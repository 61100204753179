import { TPrice } from '@components/common/UI/ProductPrice/index.types'
import useProductPrice from '@hooks/useProductPrice'
import { IProduct } from '@typesApp/product'

type UseProductBundleDataProps = IProduct

type UseProductBundleDataReturn = {
  partNumber: string | undefined
  price: TPrice | undefined
  currency: string | undefined
}

const useProductBundleData = (props: UseProductBundleDataProps): UseProductBundleDataReturn => {
  const partNumber = props?.items?.[0]?.partNumber
  const { currentPrice } = useProductPrice(props?.x_price)

  /**
   * Product price - Comes from useProductPrice as TPrice
   */
  const price = currentPrice ?? undefined

  /**
   * Product currency
   */
  const currency = price?.currency ?? undefined

  return { partNumber, price, currency }
}

export default useProductBundleData
