import styled from '@mui/material/styles/styled'
import { ProductImageZoomProps } from './ProductImageZoom'
import { IconButton } from '@components/UI/IconButton'
import { Z_INDEX_LV1 } from '../../../../constants/ui'

const ProductImageZoomWrapper = styled('div', {
  shouldForwardProp: prop => prop === 'isvisible' || prop === 'children',
  name: 'ProductImageZoom',
  slot: 'Wrapper',
})<ProductImageZoomProps>(({ isvisible, theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    display: isvisible === 1 ? 'flex' : 'none',
    backgroundColor: theme.palette.custom.white,
    width: '100%',
    height: '100vh',
    zIndex: Z_INDEX_LV1,
    left: '0',
    top: '0',
  },
}))

const ProductImageZoomMagnifiedImageWrapper = styled('div', {
  name: 'ProductImageZoom',
  slot: 'MagnifiedImageWrapper',
})(() => ({
  position: 'absolute',
  width: 'calc(100% - 120px)',
  height: '100%',
  zIndex: -1,
  right: 0,

  img: {
    maxHeight: 'inherit',
  },
}))

const ProductImageZoomMagnifier = styled('div', {
  name: 'ProductImageZoom',
  slot: 'Magnifier',
  shouldForwardProp: prop => prop === 'isvisible',
})<ProductImageZoomProps>(({ lensHeight, lensWidth, theme }) => ({
  zIndex: 6,
  position: 'absolute',
  top: 0,
  left: 0,
  width: lensWidth,
  height: lensHeight,
  display: 'block',
  border: `5px solid  ${theme.palette.primary.main}`,
  borderRadius: '100%',
  boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.3)',
  pointerEvents: 'none',
}))

const ProductImageZoomCloseIcon = styled(IconButton, {
  name: 'ProductImageZoom',
  slot: 'CloseIcon',
})(({ theme }) => ({
  borderRadius: '0',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.custom.white,
  position: 'absolute',
  zIndex: '10',
  top: '0',
  right: '0',
  svg: {
    width: theme.spacing(6),
  },
  '&:hover': {
    backgroundColor: theme.palette.custom.white,
  },
}))

export {
  ProductImageZoomWrapper,
  ProductImageZoomMagnifier,
  ProductImageZoomCloseIcon,
  ProductImageZoomMagnifiedImageWrapper,
}
