import { Trans, useTranslation } from 'next-i18next'
import React from 'react'
import { SupplyData } from '../../../../types/product'
import { SupplyBadgeContainer } from './SupplyBage.style'

const SupplyBadge: React.FC<{ data: SupplyData }> = ({ data }) => {
  const { t } = useTranslation()
  const supplyPeriodLabelMap: Record<string, string> = {
    '1year': 'SupplyBadge.Labels.AnnualSupply',
    '6month': 'SupplyBadge.Labels.MontlySupply',
  }

  const getBadgeLabel = (timePeriod: string, supplyPeriod: string) => {
    return (
      <Trans i18nkey={supplyPeriodLabelMap[timePeriod]} components={[<strong key="univers">univers</strong>]}>
        {t(supplyPeriodLabelMap[timePeriod], { supplyPeriod: supplyPeriod })}
      </Trans>
    )
  }

  //TODO: this attribute should come from BE, we will mock it for now

  const { timePeriod } = data

  const floatTimePeriod = timePeriod ? parseFloat(timePeriod) : 0
  const timePeriodParsed: string = floatTimePeriod >= 12 ? '1year' : '6month'

  const supplyPeriodParsed: string = floatTimePeriod >= 12 ? '2 months' : '1 month'

  return (
    <SupplyBadgeContainer>
      <span>{getBadgeLabel(timePeriodParsed, supplyPeriodParsed)}</span>
    </SupplyBadgeContainer>
  )
}

export default SupplyBadge
