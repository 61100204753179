import { Switch as MuiSwitch, switchClasses as MuiSwitchClasses, styled } from '@mui/material'

export const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  [`&.${MuiSwitchClasses.root}`]: {
    borderRadius: theme.spacing(6),
    height: theme.spacing(12),
    width: theme.spacing(22),
    padding: 0,
  },

  [`& .${MuiSwitchClasses.thumb}`]: {
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: 20,
    height: 20,
  },

  [`& .${MuiSwitchClasses.track}`]: {
    borderRadius: theme.spacing(6),
    backgroundColor: theme.palette.neutral.tones[60],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 200,
    }),
  },

  [`& .${MuiSwitchClasses.switchBase}`]: {
    padding: 0,
    margin: 2,
    transitionDuration: '200ms',
    [`&.${MuiSwitchClasses.checked}`]: {
      transform: 'translateX(20px)',
      color: theme.palette.success.main,
      [`& + .${MuiSwitchClasses.track}`]: {
        backgroundColor: theme.palette.primary.tones[100],
        opacity: 1,
        border: 0,
      },
      [`&.${MuiSwitchClasses.disabled} + .${MuiSwitchClasses.track}`]: {
        opacity: 0.4,
      },
      [`&.${MuiSwitchClasses.disabled} .${MuiSwitchClasses.thumb}`]: {
        color: theme.palette.success.main,
        opacity: 0.4,
      },
    },
    [`&.${MuiSwitchClasses.disabled} .${MuiSwitchClasses.thumb}`]: {
      color: theme.palette.primary.tones[100],
    },
    [`&.${MuiSwitchClasses.disabled} + .${MuiSwitchClasses.track}`]: {
      opacity: 0.4,
    },
  },
}))
