import { styled } from '@mui/material'

export const StyledCategoryWrapper = styled('div', {
  name: 'Category',
  slot: 'wrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(8),

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(16),
  },
}))
