import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles/index.module.scss'

interface CarouselProps {
  children: JSX.Element[]
  autoplay?: boolean
  loop?: boolean
  allowTouchMove?: boolean
  slidesPerView?: number
  delay?: number
  centeredSlides?: boolean
  slideIndex?: number
  setSlideIndex?: (index: number) => void
  dragThreshold?: number
  containerHeight?: number
}

const sliderIndexSetter = (index: number, arrayLength: number, setter: (index: number) => void) => {
  const nextIndex = index + 1 >= arrayLength ? 0 : index + 1
  setter(nextIndex)
}

const getNextSlideIndex = (currIndex: number, slidesPerView: number, arrayLength: number) => {
  return currIndex + slidesPerView <= arrayLength ? currIndex : arrayLength - slidesPerView
}

const getTranslateYValue = (swipeTransform: number, nextSlideIndex: number, arrayLength: number) => {
  return swipeTransform ? `${-swipeTransform}px` : `-${(nextSlideIndex * 100) / arrayLength}%`
}

export const VerticalCarousel: React.FC<CarouselProps> = ({
  children,
  autoplay = false,
  allowTouchMove = true,
  slidesPerView = 1,
  delay = 3000,
  centeredSlides = false,
  slideIndex,
  setSlideIndex,
  dragThreshold = 150,
  containerHeight,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const sliderContainerRef = useRef<HTMLDivElement | null>(null)
  const sliderListRef = useRef<HTMLDivElement | null>(null)
  const touchStartY = useRef<number>(0)
  const [swipeTransform, setSwipeTransform] = useState(0)
  const currentElemIndex = slideIndex || currentIndex

  const setterCallback = useCallback(() => {
    if (slideIndex && setSlideIndex) {
      sliderIndexSetter(slideIndex, children.length, setSlideIndex)
    } else {
      sliderIndexSetter(currentIndex, children.length, setCurrentIndex)
    }
  }, [slideIndex, currentIndex, children])

  useEffect(() => {
    let intervalId = 0

    if (autoplay && !allowTouchMove) {
      intervalId = window.setInterval(setterCallback, delay)
    }

    return () => {
      intervalId && window.clearInterval(intervalId)
    }
  }, [autoplay, allowTouchMove, delay, setterCallback])

  useEffect(() => {
    if (currentElemIndex && currentElemIndex >= 0) {
      setSwipeTransform(0)
    }
  }, [currentElemIndex])

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartY.current = e.touches[0].clientY
  }

  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault()
    const touchEnd = e.touches[0].clientY
    const slideDistance = touchStartY.current - touchEnd
    if (slideDistance > dragThreshold || slideDistance < -dragThreshold) {
      setSwipeTransform(slideDistance)
    }
  }

  const handleTouchEnd = () => {
    if (sliderContainerRef.current && sliderListRef.current) {
      const sliderContainerHeight = sliderContainerRef.current.offsetHeight
      const sliderListHeight = sliderListRef.current.offsetHeight
      const diff = sliderListHeight - sliderContainerHeight

      setSwipeTransform(tempValue => {
        if (Math.abs(tempValue) > Math.abs(diff)) {
          return tempValue > 0 ? diff : 0.1
        }
        return tempValue
      })
    } else {
      setSwipeTransform(0)
    }
  }

  useEffect(() => {
    if (sliderListRef) {
      sliderListRef.current?.addEventListener('touchmove', handleTouchMove, { passive: false })
    }
    return () => sliderListRef.current?.removeEventListener('touchmove', handleTouchMove)
  }, [handleTouchMove])

  const nextSlideIndex = getNextSlideIndex(currentElemIndex, slidesPerView, children.length)
  const translateYValue = getTranslateYValue(swipeTransform, nextSlideIndex, children.length)
  const transitionTime = swipeTransform ? 0.1 : 0.5

  return (
    <div
      className={clsx(styles['slider-container'], 'slider-container')}
      ref={sliderContainerRef}
      style={{ height: containerHeight || '100%' }}
    >
      <div
        className={clsx(styles['slider-list'], 'slider-list')}
        style={{ transform: `translateY(${translateYValue})`, transition: `transform ${transitionTime}s ease` }}
        ref={sliderListRef}
        onTouchStart={allowTouchMove ? handleTouchStart : undefined}
        onTouchEnd={allowTouchMove ? handleTouchEnd : undefined}
      >
        {children.map((item, index) => (
          <div key={index} className={clsx(styles['slide'], 'slide', centeredSlides && styles['center-slide'])}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
