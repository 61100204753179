import { ImageCrops } from '@components/common/components/CmsMedia/types'

export const bundleOfferImageCrops: ImageCrops = {
  sm: {
    crop: 'landscape_ratio204x181',
    width: 734,
    height: 652,
  },
  md: {
    crop: 'landscape_ratio379x362',
    width: 758,
    height: 724,
  },
  lg: {
    crop: 'landscape_ratio200x181',
    width: 800,
    height: 724,
  },
  xl: {
    crop: 'landscape_ratio216x181',
    width: 864,
    height: 724,
  },
  xxl: {
    crop: 'landscape_ratio176x163',
    width: 1267,
    height: 1174,
  },
}
