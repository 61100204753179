import TopReasons from '@components/TopReasons'
import { toggleAddToCart } from '@features/product/slice'
import Box from '@mui/material/Box'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ProductRightColumnProps } from '../../../../types/product'
import { generateQuantityOptions } from '../../../../utils/order'
import { getTopReasonsCMCollection } from '@components/TopReasons/helpers'
import { getClVolume } from '../../../../utils/productAttributes'
import {
  StyledCLAccessoriesContainer,
  StyledFormConfigLabel,
  StyledFormControl,
  StyledMenuItem,
  StyledSelect,
  StyledSeparator,
} from '../../ProductDetails.style'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import ProductPriceNew from '../ProductPriceNew'
import SizeSwitcher from '../SizeSwitcher'
import { DropDownIcon } from '../ContactLensesSelect/ContactLensesSelect.style'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import useProductPrice from '@hooks/useProductPrice'
import { DEFAULT_CLACCESSORIES_MAX, DEFAULT_CLACCESSORIES_MIN } from '@constants/product'

const ASSOCIATION_TYPE = 'CL_Pack_Sizes'

export const CLAccessoriesPdpRightColumn = ({
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  isRoxable,
  productQuantity,
  setProductQuantity,
  onlyFewPieces,
}: ProductRightColumnProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleQuantityUpdate = event => {
    setProductQuantity(event.target.value)
  }

  const quantityOptions = generateQuantityOptions(
    DEFAULT_CLACCESSORIES_MAX,
    DEFAULT_CLACCESSORIES_MIN,
    'ProductDetails.Pack',
    'ProductDetails.Packs',
    5,
    t
  )
  const clusterWithMerchandisingAssociations = cluster?.find(c => !!c.merchandisingAssociations)

  const getAssociatedMerchandising = cp => {
    return cp?.merchandisingAssociations?.some(ma => ma?.associationType === ASSOCIATION_TYPE)
  }

  const hasAssociatedMerchandising = useMemo(
    () => getAssociatedMerchandising(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const topReasonsCMCollection = getTopReasonsCMCollection(placements || [])

  const getSizeSwitcherOptions = cp => {
    return cp?.merchandisingAssociations
      ?.filter(ma => ma.associationType === ASSOCIATION_TYPE)
      .map(ma => {
        const product = ma?.cluster?.[0]
        const size = getClVolume(product)
        return { size, label: '', href: product?.seo?.href }
      })
  }

  const options = useMemo(
    () => getSizeSwitcherOptions(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const currentSize = currentProduct.productAttributes['CL_VOLUME']
  const productType = currentProduct.productAttributes['PRODUCT_TYPE']
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)

  dispatch(toggleAddToCart(true))

  return (
    <>
      {hasAssociatedMerchandising && currentSize ? <SizeSwitcher options={options} currentSize={currentSize} /> : null}
      {currentProduct && (
        <StyledCLAccessoriesContainer>
          <StyledFormControl fullWidth>
            <StyledFormConfigLabel>{t('ContactLenses.Labels.Quantity')}</StyledFormConfigLabel>
            <StyledSelect
              id="packs"
              name="packs_quantity"
              onChange={handleQuantityUpdate}
              defaultValue={1}
              IconComponent={DropDownIcon}
            >
              {quantityOptions &&
                quantityOptions?.map((q, i) => (
                  <StyledMenuItem key={i} value={q.value || ''}>
                    {q.text}
                  </StyledMenuItem>
                ))}
            </StyledSelect>
          </StyledFormControl>
        </StyledCLAccessoriesContainer>
      )}
      <StyledSeparator />
      <Box sx={{ gap: 0 }}>
        {currentProduct && currentProduct.x_price && (
          <ProductPriceNew
            isPDP={true}
            price={prices}
            productType={parseProductTypeForPrice(productType)}
            isCLAccessories={true}
            showPricePerBox={true}
            showTotalPrice={true}
            showSalePrice={false}
            totalBoxes={Number(productQuantity)}
          />
        )}
      </Box>
      <StyledSeparator />
      {currentProduct && currentProduct.x_price && (
        <ProductCtaContainer
          productInCart={productInCart}
          type={soldOutStatus}
          currentProduct={currentProduct}
          error={addToCartButtonFail}
          ctaRef={ctaRef}
          roxable={isRoxable}
          addToCartFillType={'fill'}
          productQuantity={productQuantity}
          onlyFewPieces={onlyFewPieces}
        />
      )}
      <OmniChannelCarousel placements={placements} />
      <ProductAvailableNotificationDrawer
        attachments={currentProduct.attachments || []}
        attributes={currentProduct.productAttributes}
        product={currentProduct || undefined}
      />
      {topReasonsCMCollection && <TopReasons topReasonsCMCollection={topReasonsCMCollection} />}
    </>
  )
}
