import { SwitchProps as MuiSwitchProps } from '@mui/material'
import { forwardRef } from 'react'
import { StyledSwitch } from './Switch.style'

export interface SwitchProps {
  disabled?: MuiSwitchProps['disabled']
  className?: MuiSwitchProps['className']
  checked?: MuiSwitchProps['checked']
  inputRef?: MuiSwitchProps['inputRef']
  onChange?: MuiSwitchProps['onChange']
  value?: MuiSwitchProps['value']
  required?: MuiSwitchProps['required']
  inputProps?: MuiSwitchProps['inputProps']
}

const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  return <StyledSwitch inputRef={ref} {...props} disableRipple />
})

export default Switch
