import React, { useEffect } from 'react'
import { Status } from './Status'
import { CTAItem, MyClaimsWidgetConfig } from '../types'
import { LoadingIndicator } from './LoadingIndicator'
import { useModuleLoad } from '../hooks/useModuleLoad'
import { useConfiguration } from '../hooks/useConfiguration'

const ROOT = 'insuranceMyClaimsApp'

interface MyClaimsProps {
  onOrderClick: (orderNumber: string) => void
  shoppingActions: CTAItem[]
  translations: {
    unavailableTitle: string
    unavailableMessage: string
  }
}

export const MyClaims: React.FC<MyClaimsProps> = props => {
  const { isReady, isBusy, isError } = useModuleLoad()
  const { session, environment, actionModule } = useConfiguration()

  const onOrderClick = (orderNumber: string): Promise<void> => {
    return new Promise(resolve => {
      resolve()
      props.onOrderClick(orderNumber)
    })
  }

  const config = {
    session,
    environment,
    actionModule,
    selector: `#${ROOT}`,
    shoppingActions: props.shoppingActions,
    onOrderClick,
  } as MyClaimsWidgetConfig

  useEffect(() => {
    return () => {
      document.dispatchEvent(
        new CustomEvent('db:insuranceWidgetEvent', {
          detail: { type: 'close', widgetName: 'myClaimsWidget' },
        })
      )
    }
  }, [])

  useEffect(() => {
    if (!isReady) return

    document.dispatchEvent(
      new CustomEvent('db:insuranceWidgetEvent', {
        detail: { type: 'render', widgetName: 'myClaimsWidget', config },
      })
    )
  }, [isReady])

  return (
    <>
      <div id={ROOT} />
      {isBusy && <LoadingIndicator />}
      {!isBusy && isError && (
        <Status
          isLoading={false}
          isError
          unavailableTitle={props.translations.unavailableTitle}
          unavailableMessage={props.translations.unavailableMessage}
        />
      )}
    </>
  )
}
