import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import { setGuestLoggedIn } from '@features/user/slice'
import { setWishList } from '@features/wishList/slice'
import guestIdentityService from '@foundation/apis/transaction/guestIdentity.service'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import Log from '@services/Log'
import { CreateWishlistResponse, IWishlist, ItemForUpdateWishlistRequest } from '@typesApp/wishlist'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWishlistAction } from '../../../features/wishList/action'
import {
  isWishlistLoadingSelector,
  wishlistExternalIdSelector,
  wishlistItemSelector,
  wishlistItemsSelector,
} from '../../../features/wishList/selector'
import WishlistService from '../../../foundation/apis/transaction/wishList.service'

export const useWishlist = currentProduct => {
  const dispatch = useDispatch()
  const wishlistExternalId = useSelector(wishlistExternalIdSelector)
  const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistedItems = useSelector(wishlistItemsSelector)
  const getItemWishList = useSelector(
    wishlistItemSelector(currentProduct?.partnumberId ? currentProduct?.partnumberId : currentProduct?.partNumber || '')
  )
  const [itemIdWishList, setItemIdWishList] = useState<string>('')
  const { langId } = useStoreIdentity()
  const { mySite } = useSite()

  const determineWishListAction = (avoidRefetchWishlist?: boolean) => {
    getItemWishList ? removeFromWishList(avoidRefetchWishlist) : addToWishList()
  }

  const getGuestIdentity = async () => {
    const payload = { widget: 'axiosConfig' }
    const guestIdentity: AxiosResponse = await guestIdentityService.login(payload)

    dispatch(setGuestLoggedIn({ ...guestIdentity.data, ...payload }))
    dispatch(fetchContract({ ...guestIdentity.data, ...payload }))
    dispatch(fetchEntitledOrganization({ ...guestIdentity.data, ...payload }))
    dispatch(fetchUserContext({ ...guestIdentity.data, ...payload }))
  }

  const getWishlist = async () => {
    return await WishlistService.getDefault(mySite.storeID)
      .then((res: IWishlist) => res)
      .catch(error => {
        Log.error('Get wishlist error: ', error)
        return undefined
      })
  }

  const createWishlist = async (): Promise<string> => {
    return await WishlistService.createWishlist(mySite.storeID)
      .then((res: CreateWishlistResponse) => res.uniqueID)
      .catch(error => {
        Log.error('Create wishlist error: ', error)
        return ''
      })
  }

  const addItemToWishlist = async (
    wishListId: string,
    item: ItemForUpdateWishlistRequest[],
    langId: string,
    dispatch
  ) => {
    await WishlistService.addItemToWishlist(wishListId, item, langId, mySite.storeID)

    dispatch(fetchWishlistAction(mySite.storeID))
  }

  const addToWishList = async () => {
    dispatch(setWishList({ isLoading: true }))
    try {
      const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

      let wishListId = ''

      //if generic user, call the guest identity
      if (!currentUser) {
        await getGuestIdentity()
        wishListId = await createWishlist()
      } else {
        //if NOT generic user, call the get wishlist
        const wishList = await getWishlist()
        if (!!wishList) {
          wishListId = wishList.GiftList[0].uniqueID
        } else {
          wishListId = await createWishlist()
        }
      }

      if (!!wishListId && wishListId.length > 0) {
        addItemToWishlist(
          wishListId,
          [
            {
              partNumber: currentProduct.partnumberId ? currentProduct.partnumberId : currentProduct.partNumber,
              quantityRequested: '1',
              location: 'online',
            },
          ],
          langId,
          dispatch
        )
      } else {
        throw new Error()
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        Log.error('Add wishlist error: ', error?.message)
      }
    }
  }

  const removeFromWishList = async (avoidRefetchWishlist?: boolean) => {
    await WishlistService.deleteItemFromWishlist({
      storeId: mySite.storeID,
      externalId: wishlistExternalId,
      itemId: itemIdWishList,
    })

    !avoidRefetchWishlist && dispatch(fetchWishlistAction(mySite.storeID))
  }

  useEffect(() => {
    if (currentProduct) {
      if (
        getItemWishList &&
        getItemWishList.partNumber ===
          (currentProduct.partnumberId ? currentProduct.partnumberId : currentProduct.partNumber)
      ) {
        setItemIdWishList(getItemWishList.giftListItemID)
      } else {
        setItemIdWishList('')
      }
    }
  }, [currentProduct, getItemWishList])

  return {
    itemIdWishList,
    isWishlistLoading,
    wishlistedItems,
    setItemIdWishList,
    determineWishListAction,
  }
}
