import { PlusIcon, RemoveIcon } from '@components/UI/Icons'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputAdornmentProps,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from '@mui/material'
import { EyeContactLensAttribute } from '@typesApp/prescription'
import { useTranslation } from 'next-i18next'

interface NumberInputProps extends Omit<OutlinedInputProps, 'value' | 'onChange'> {
  value: number | string
  onChange: (eye: string, id: EyeContactLensAttribute, val: string) => void
  min?: number
  max?: number
  eye: string
  id: EyeContactLensAttribute
  disabled?: boolean
}

interface StyledInputAdornmentProps extends InputAdornmentProps {
  increment?: boolean
  disabled?: boolean
}

const StyledInputAdornment = styled((props: StyledInputAdornmentProps) => <InputAdornment {...props} />)(
  ({ theme, increment, disabled }) => ({
    backgroundColor: disabled ? theme.palette.neutral.tones[98] : theme.palette.secondary.tones[90],
    borderRight: !increment ? `1px solid ${theme.palette.neutral.tones[80]}` : 'none',
    borderLeft: increment ? `1px solid ${theme.palette.neutral.tones[80]}` : 'none',
    borderRadius: increment ? theme.spacing(0, 4, 4, 0) : theme.spacing(4, 0, 0, 4),
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    svg: {
      position: 'absolute',
      top: increment ? theme.spacing(0) : theme.spacing(3),
      left: increment ? theme.spacing(0) : theme.spacing(0.5),
    },
  })
)
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0),
}))
const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  padding: 0,
  borderColor: theme.palette.neutral.tones[80],
  height: theme.spacing(19),
  textAlign: 'center',
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: theme.spacing(0),
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: theme.spacing(0),
  },
  span: {
    display: 'none',
  },
}))

const NumberInput: React.FC<NumberInputProps> = ({ onChange, value = 1, min = 1, max, id, eye, disabled }) => {
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value
    if (Number(v) >= (min || 1) && Number(v) <= (max || Infinity)) onChange(eye, id, v)
  }
  return (
    <FormControl variant="outlined">
      <StyledOutlinedInput
        type="number"
        disabled={disabled}
        readOnly={true}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
        value={value}
        onChange={handleChange}
        id="number-input"
        data-name={eye + '_quantity'}
        startAdornment={
          <StyledInputAdornment position="start" disabled={disabled || Number(value) - 1 < (min || 1) || disabled}>
            <StyledIconButton
              aria-label={t('ContactLenses.Labels.Increase')}
              edge="end"
              onClick={() => {
                if (Number(value) - 1 < (min || 1) || disabled) return
                onChange(eye, id, String(Number(value) - 1))
              }}
            >
              <RemoveIcon />
            </StyledIconButton>
          </StyledInputAdornment>
        }
        endAdornment={
          <StyledInputAdornment
            position="end"
            increment
            disabled={disabled || Number(value) + 1 > (max || Infinity) || disabled}
          >
            <StyledIconButton
              aria-label={t('ContactLenses.Labels.Decrease')}
              edge="start"
              onClick={() => {
                if (Number(value) + 1 > (max || Infinity) || disabled) return
                onChange(eye, id, String(Number(value) + 1))
              }}
            >
              <PlusIcon />
            </StyledIconButton>
          </StyledInputAdornment>
        }
      />
    </FormControl>
  )
}

export default NumberInput
