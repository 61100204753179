import { Carousel } from '@components/common/components/Carousel'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { TProduct } from '@utils/productNew'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { SuggestedProductTile } from '../SuggestedProductTile'
import styles from './styles/index.module.scss'
import { Typography } from '@mui/material'

const SuggestedProductsContainer: React.FC<PropsWithChildren<Pick<TSuggestedProducts, 'title'>>> = ({
  title,
  children,
}) => {
  return (
    <div className={clsx(styles['suggested-products-wrapper'], title && 'suggested-products-wrapper-with-title')}>
      {title && <Typography variant="h6">{title}</Typography>}
      <div className={clsx(styles['suggested-products-container'])}>{children}</div>
    </div>
  )
}

type TSuggestedProducts = {
  products: TProduct[]
  isImgWithShadow?: boolean
  lazy?: boolean
  title?: string
}

export const SuggestedProducts: React.FC<TSuggestedProducts> = ({ products, isImgWithShadow, lazy, title }) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoint()
  const deviceBreakpoint = isMobile ? 1 : isTablet ? 4 : 3

  if (products.length === 0) return null

  if (products.length <= deviceBreakpoint) {
    return (
      <SuggestedProductsContainer title={title}>
        {products?.map((product, index) => (
          <div key={index} className={clsx(styles['single-item-wrapper'])}>
            <SuggestedProductTile product={product} tileIndex={index} lazy={lazy} />
          </div>
        ))}
      </SuggestedProductsContainer>
    )
  }

  return (
    <SuggestedProductsContainer title={title}>
      <Carousel
        loop={true}
        slidesPerView={isMobile ? 1 : products.length < deviceBreakpoint ? products.length : deviceBreakpoint}
        renderDefaultArrows={isDesktop}
        spaceBetween={isDesktop ? 20 : 0}
        withoutBullets={isDesktop}
        withoutButtons={!isDesktop}
      >
        {products?.map((product, index) => (
          <SuggestedProductTile
            key={`swiper-${index}`}
            product={product}
            tileIndex={index}
            isImgWithShadow={isImgWithShadow}
            lazy={lazy}
          />
        ))}
      </Carousel>
    </SuggestedProductsContainer>
  )
}
