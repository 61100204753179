import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { renderLink } from './renderLink'
import { LANGUAGE_CODE_MAP } from '@constants/common'
import { StyledIconTick3 } from './TopReasons.style'
import { IPlacement, IPlacementItem, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

export const renderTop3ReasonsLink = (reasonKey: string) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const prefix = '/'.concat(langCode)

  switch (reasonKey) {
    case 'reason1':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason1.link.spedizione')), 'SpedizioneLink', 'styled')]
      }

      return [renderLink(prefix.concat(t('TopReasons.Reason1.link.available')), 'AvailableLink', 'styled')]
    case 'reason2':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason2.link.contatti')), 'ContattiLink', 'styled')]
      }

      return []
    case 'reason3':
      if (
        [LANGUAGE_CODE_MAP.it_it, LANGUAGE_CODE_MAP.nl_nl, LANGUAGE_CODE_MAP.nl_be, LANGUAGE_CODE_MAP.fr_be].includes(
          langCode
        )
      ) {
        return [renderLink(prefix.concat(t('TopReasons.Reason3.link.detrazione')), 'DetrazioneLink', 'styled')]
      }

      return [
        renderLink(prefix.concat(t('TopReasons.Reason3.link.Price match Promise')), 'PriceMatchPromiseLink', 'link'),
        renderLink(t('TopReasons.Reason3.link.020 7768 5000'), 'TelLink', 'styled'),
      ]
    default:
      return []
  }
}

const ICONS_MAP = {
  'icon--check': <StyledIconTick3 />,
}

export const getTopReasonsIconByName = (name: string): JSX.Element | null => ICONS_MAP[name] || null

export const getTopReasonsCMCollection = (placements: IPlacement<IPlacementItem>[]): ICMCollection | null => {
  const topReasonsPlacement = placements?.find(placement => placement.name === 'PDP_placement_3')
  const topReasonsCMCollection = topReasonsPlacement?.items.find(isCMCollection)

  return topReasonsCMCollection || null
}
