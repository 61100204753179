import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const ButterflyGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.003 4.667h-.013c-.97 0-4.973.47-6.053 2.243A1.98 1.98 0 0 0 8 6.667a2 2 0 0 0-.943.237C5.973 5.137 1.98 4.667 1.01 4.667H.997C.253 4.67 0 5.294 0 5.894v3.073c0 1.517.66 2.097 2.663 2.097 2.007 0 4.67-.707 4.67-3.214a1.92 1.92 0 0 0-.03-.313 1.308 1.308 0 0 1 1.394-.003c-.017.103-.03.206-.03.316 0 2.507 2.663 3.214 4.67 3.214 2.003 0 2.663-.58 2.663-2.097V5.894c0-.6-.253-1.224-.997-1.227zM5.437 9.81c-.957.51-2.147.587-2.774.587-1.903 0-1.996-.517-1.996-1.43V5.894c0-.55.227-.56.329-.56.516.001 2.023.148 3.387.6 1.044.346 2.284.956 2.284 1.916 0 .877-.404 1.517-1.23 1.96zm9.896-.843c0 .913-.093 1.43-1.996 1.43-.627 0-1.817-.077-2.774-.587-.826-.443-1.23-1.083-1.23-1.96 0-.96 1.24-1.57 2.284-1.916 1.365-.452 2.871-.6 3.387-.6.101 0 .33.009.33.56v3.073z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CatEyeGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M12.869 5.334c-.384 0-.775.03-1.156.106-1.061.21-2.18.768-2.71 1.502a1.983 1.983 0 0 0-2.007-.004C6.463 6.206 5.346 5.65 4.287 5.44a5.984 5.984 0 0 0-1.156-.106c-1.63 0-3.122.552-3.122.552 0 2.845 1.572 4.78 4.163 4.78s3.352-1.72 3.122-3.058c-.003-.02-.01-.04-.015-.06a1.33 1.33 0 0 1 1.441-.003c-.004.021-.012.042-.015.063-.23 1.338.53 3.057 3.122 3.057 2.591 0 4.163-1.935 4.163-4.779 0 0-1.49-.552-3.122-.552zM6.255 9.241c-.418.496-1.138.758-2.082.758-2.017 0-3.32-1.378-3.48-3.63.53-.152 1.46-.368 2.44-.368.367 0 .713.031 1.026.093 1.256.248 2.368.977 2.48 1.626.095.564-.044 1.118-.384 1.521zm5.573.758c-.944 0-1.664-.262-2.082-.758-.34-.403-.48-.957-.383-1.52.11-.65 1.223-1.38 2.48-1.627A5.342 5.342 0 0 1 12.868 6c.973 0 1.907.217 2.439.368-.161 2.252-1.464 3.63-3.48 3.63z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const GeometricalGlassesIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.91 7.667 14.37 5a.666.666 0 0 0-.576-.333h-3.08a.666.666 0 0 0-.577.333l-.681 1.18a2.325 2.325 0 0 0-2.912 0L5.863 5a.667.667 0 0 0-.578-.333H2.206A.666.666 0 0 0 1.63 5L.089 7.667a.667.667 0 0 0 0 .667L1.63 11c.119.207.34.334.577.334h3.08A.666.666 0 0 0 5.863 11l1.54-2.666a.667.667 0 0 0 0-.667l-.52-.9a1.66 1.66 0 0 1 2.235 0l-.52.9a.667.667 0 0 0 0 .667L10.138 11c.118.207.339.334.577.334h3.079A.666.666 0 0 0 14.37 11l1.54-2.666a.668.668 0 0 0 0-.667zm-10.625 3H2.206L.666 8l1.54-2.666h3.08L6.826 8l-1.54 2.667zm8.509 0h-3.08L9.174 8l1.54-2.666h3.08L15.334 8l-1.54 2.667z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const GlassesCase: React.FC<CustomSVGIconProps> = ({ width = 25, height = 25, htmlColor, ...rest }) => {
  return (
    <IconWrapper {...rest} viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none">
      <g clipPath="url(#bjv0v7boma)">
        <path d="M12.375 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" fill={htmlColor} />
        <path
          d="M23.375 6h-22a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-11 11c-2.726 0-3.245-1.447-4.031-3.637-.617-1.721-1.317-3.673-3.33-5.223-.56-.43-1.262-.654-1.94-.869-.279-.088-.552-.175-.795-.27H22.47c-.242.095-.514.182-.793.27-.679.216-1.381.439-1.94.869-2.013 1.55-2.713 3.502-3.331 5.223-.785 2.19-1.304 3.637-4.031 3.637zm-11 0V7.673c.415.237.909.397 1.396.552.596.189 1.212.384 1.632.707 1.78 1.371 2.428 3.176 3 4.768.452 1.263.893 2.483 1.824 3.3H1.375zm14.148 0c.931-.817 1.372-2.037 1.825-3.3.571-1.592 1.219-3.397 2.999-4.768.42-.324 1.036-.519 1.633-.708.486-.155.981-.314 1.395-.55V17h-7.852z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="bjv0v7boma">
          <path fill={htmlColor} transform="translate(.375)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const GlassesIcon: React.FC<CustomSVGIconProps> = ({ width = 32, height = 32, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M31.698 13.682c-.61-2.325-2.157-3.84-4.26-4.157a22.33 22.33 0 0 0-2.788-.187v-.004H7.328v.004a22.42 22.42 0 0 0-2.802.19C2.437 9.844.89 11.357.28 13.683c-.786 2.997.159 6.457 2.196 8.049.792.618 1.75.931 2.796.931.623 0 1.277-.11 1.948-.336 3.06-1.028 5.979-4.226 7.154-7.768.93-.704 2.298-.705 3.228 0 1.167 3.533 4.09 6.739 7.155 7.768.67.224 1.325.336 1.948.336 1.046 0 2.004-.314 2.796-.931 2.038-1.592 2.982-5.052 2.197-8.05zm-18.417-.104c-.902 3.378-3.628 6.526-6.484 7.486-.96.322-2.345.516-3.5-.383-1.616-1.264-2.375-4.188-1.727-6.66.275-1.05 1.05-2.857 3.144-3.174a21.02 21.02 0 0 1 2.615-.181h.277c2.486.024 4.515.54 5.331 1.444.372.413.48.886.344 1.468zm1.373-.675a2.775 2.775 0 0 0-.73-1.687 3.72 3.72 0 0 0-.64-.548h5.409a3.718 3.718 0 0 0-.64.548 2.775 2.775 0 0 0-.728 1.688 3.975 3.975 0 0 0-1.336-.237c-.46 0-.91.085-1.335.236zM28.68 20.68c-1.154.9-2.54.705-3.5.383-2.855-.96-5.583-4.108-6.48-7.466-.14-.603-.033-1.075.34-1.488.818-.904 2.85-1.421 5.335-1.443h.273c.831.004 1.707.06 2.603.178 2.106.32 2.882 2.127 3.157 3.176.647 2.471-.111 5.396-1.728 6.66z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const HexagonalGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill={htmlColor} {...rest}>
      <path
        d="M15.91 7.667 14.372 5a.666.666 0 0 0-.577-.333h-3.08a.666.666 0 0 0-.577.333l-.681 1.18a2.325 2.325 0 0 0-2.912 0L5.863 5a.667.667 0 0 0-.578-.333H2.206A.666.666 0 0 0 1.63 5L.089 7.667a.667.667 0 0 0 0 .667L1.63 11c.119.207.34.334.577.334h3.08A.666.666 0 0 0 5.863 11l1.54-2.666a.667.667 0 0 0 0-.667l-.52-.9a1.66 1.66 0 0 1 2.235 0l-.52.9a.667.667 0 0 0 0 .667L10.138 11c.118.207.339.334.577.334h3.079a.666.666 0 0 0 .577-.334l1.54-2.666a.668.668 0 0 0 0-.667zm-10.625 3H2.206L.666 8l1.54-2.666h3.08L6.826 8l-1.54 2.667zm8.509 0h-3.08L9.174 8l1.54-2.666h3.08L15.334 8l-1.54 2.667z"
        fill="#00303C"
      />
    </IconWrapper>
  )
}

export const IrregularGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.333 4.5h-3a3.332 3.332 0 0 0-3.225 2.504 2 2 0 0 0-2.217-.002A3.331 3.331 0 0 0 3.667 4.5h-3A.666.666 0 0 0 0 5.167v3A3.333 3.333 0 0 0 3.333 11.5h3A.666.666 0 0 0 7 10.833v-3l-.002-.035c.254-.293.61-.465 1.002-.465.386 0 .75.169 1.002.456L9 7.833v3c0 .369.298.667.667.667h3A3.333 3.333 0 0 0 16 8.167v-3a.666.666 0 0 0-.667-.667zm-9 6.333h-3A2.67 2.67 0 0 1 .667 8.167v-3h3a2.67 2.67 0 0 1 2.666 2.666v3zm9-2.666a2.67 2.67 0 0 1-2.666 2.666h-3v-3a2.67 2.67 0 0 1 2.666-2.666h3v3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const LensGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M38.608,13.217c0.2-0.613,0.389-1.23,0.554-1.854c1.871-8.041-8.136-11.198-19.203-11.198
    c-2.745,0-5.553,0.194-8.263,0.556C-2.644,2.915-3.111,25.926,6.558,33.628c0.837,0.66,1.732,1.168,2.667,1.546
    c0.943,4.235,2.958,8.075,5.945,10.454C17.238,47.259,19.641,48,22.192,48c9.891,0,22.016-11.13,25.581-24.637
    C49.039,17.923,44.866,14.721,38.608,13.217z M7.804,32.064c-4.828-3.845-7.07-12.635-5.105-20.01
    c0.823-3.088,3.125-8.411,9.262-9.35c2.675-0.357,5.366-0.538,7.999-0.538c8.047,0,14.2,1.698,16.458,4.543
    c0.918,1.156,1.181,2.522,0.803,4.174c-0.171,0.646-0.37,1.288-0.585,1.926c-2.501-0.437-5.248-0.643-8.064-0.643
    c-2.745,0-5.553,0.194-8.263,0.556C11.231,14.11,7.719,23.837,8.81,32.744l0,0C8.464,32.539,8.126,32.317,7.804,32.064z
     M8.988,33.963c0.002,0.009,0.004,0.019,0.005,0.028C8.991,33.982,8.989,33.972,8.988,33.963z M45.832,22.883
    C42.624,34.986,31.364,46,22.192,46c-2.204,0-4.15-0.654-5.777-1.936c-4.828-3.845-7.07-12.635-5.105-20.01
    c0.823-3.088,3.125-8.411,9.261-9.35c2.675-0.357,5.366-0.538,7.999-0.538c8.047,0,14.2,1.698,16.458,4.543
    C45.947,19.865,46.21,21.23,45.832,22.883z M13.989,24.882l1.922,0.553c0.634-2.201,2.369-5.997,6.87-6.685l-0.303-1.977C18.387,17.399,15.292,20.354,13.989,24.882z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LuxuryGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M28 24c0-2.793 1.023-6 9-6s9 3.207 9 6c0 1.984-.484 5.888-3.937 7.835.089.724.278 1.385.541 1.954C46.025 32.08 48 28.643 48 24c0-5.309-3.701-8-11-8-5.554 0-9.019 1.563-10.361 4.648a6.252 6.252 0 0 0-5.278 0C20.019 17.563 16.554 16 11 16c-7.299 0-11 2.691-11 8 0 6.785 4.215 11 11 11 6.065 0 11-4.935 11-11 0-.486-.042-.943-.104-1.385 1.265-.767 2.943-.767 4.208 0A9.882 9.882 0 0 0 26 24c0 5.313 3.786 9.757 8.801 10.779a5.854 5.854 0 0 0 .924-1.88C31.366 32.277 28 28.529 28 24zm-17 9c-8.123 0-9-6.294-9-9 0-2.793 1.023-6 9-6s9 3.207 9 6c0 4.962-4.038 9-9 9z"
        fill={htmlColor}
      />
      <path
        d="M45 38c-2.36 0-5-2.994-5-7a1 1 0 0 0-2 0c0 4.006-2.64 7-5 7a1 1 0 0 0 0 2c2.36 0 5 2.994 5 7a1 1 0 0 0 2 0c0-4.006 2.64-7 5-7a1 1 0 0 0 0-2zm-6 4.506C38.305 41.03 37.291 39.8 36.101 39c1.19-.8 2.204-2.03 2.899-3.506.695 1.476 1.709 2.706 2.899 3.506-1.19.8-2.204 2.03-2.899 3.506z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OvalGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M12.333 5.8c-1.414 0-2.64.481-3.25 1.185a1.993 1.993 0 0 0-2.165 0C6.307 6.281 5.082 5.8 3.667 5.8 1.642 5.8 0 6.785 0 8c0 1.215 1.642 2.2 3.667 2.2S7.333 9.215 7.333 8c0-.151-.025-.299-.074-.441a1.321 1.321 0 0 1 1.481.001 1.367 1.367 0 0 0-.073.44c0 1.215 1.641 2.2 3.666 2.2S16 9.215 16 8c0-1.215-1.642-2.2-3.667-2.2zM3.667 9.533c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533zm8.666 0c-1.768 0-3-.808-3-1.533 0-.726 1.232-1.534 3-1.534s3 .808 3 1.534c0 .725-1.232 1.533-3 1.533z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PilotDarkGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.581 20.523c-.915-3.487-3.238-5.759-6.393-6.235a33.355 33.355 0 0 0-4.187-.281V14h-26v.007c-1.453.006-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-25.584-1.169a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.121a5.628 5.628 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A5.97 5.97 0 0 0 24.001 19c-.69 0-1.365.127-2.004.354z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PilotGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.86 6.841c-.305-1.162-1.08-1.92-2.131-2.078a11.184 11.184 0 0 0-1.396-.094v-.002H3.666v.002c-.484.003-.964.037-1.402.095C1.22 4.922.444 5.679.14 6.842c-.393 1.498.08 3.228 1.099 4.024.396.31.875.466 1.398.466.312 0 .64-.056.975-.168 1.532-.514 2.992-2.113 3.58-3.884a1.367 1.367 0 0 1 1.615 0c.584 1.766 2.046 3.369 3.58 3.884.335.112.663.168.975.168.523 0 1.002-.157 1.398-.466 1.02-.796 1.492-2.526 1.1-4.025zM6.645 6.79c-.451 1.689-1.816 3.263-3.245 3.743-.48.161-1.173.258-1.75-.191C.84 9.709.46 8.247.784 7.01c.138-.525.526-1.429 1.573-1.587.451-.06.892-.089 1.309-.091h.138c1.244.012 2.26.27 2.668.722.186.207.24.443.172.734zm.687-.337a1.387 1.387 0 0 0-.365-.844 1.863 1.863 0 0 0-.32-.274h2.706a1.86 1.86 0 0 0-.32.274c-.22.243-.341.529-.365.844A1.99 1.99 0 0 0 8 6.334c-.23 0-.455.042-.668.118zm7.018 3.889c-.577.45-1.27.352-1.75.191-1.43-.48-2.794-2.054-3.243-3.733-.07-.301-.017-.537.17-.744.41-.452 1.426-.71 2.67-.721h.136c.416.002.854.03 1.302.089 1.054.16 1.442 1.063 1.58 1.588.324 1.235-.056 2.698-.865 3.33z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PilotGlassesMeterIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M16 8h16v2h2V4h-2v2H16V4h-2v6h2V8zM47.581 20.523c-.915-3.487-3.238-5.759-6.393-6.235a33.544 33.544 0 0 0-4.187-.281V14h-26v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073v.001c1.19.927 2.626 1.397 4.196 1.397.936 0 1.918-.167 2.924-.505 4.595-1.543 8.975-6.338 10.74-11.651 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.299 6.138 10.108 10.741 11.653 1.006.338 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.397v-.001c3.06-2.389 4.476-7.579 3.298-12.075zm-27.645-.156c-1.353 5.066-5.446 9.788-9.733 11.228-1.44.483-3.52.777-5.254-.575-2.426-1.894-3.564-6.281-2.592-9.989.413-1.574 1.577-4.285 4.72-4.76a31.574 31.574 0 0 1 3.925-.273V16h.44c3.721.037 6.755.813 7.978 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.121a5.628 5.628 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A5.97 5.97 0 0 0 24.001 19c-.69 0-1.365.127-2.004.354zM43.054 31.02c-1.734 1.353-3.814 1.059-5.254.575-4.287-1.439-8.38-6.161-9.726-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165h.41v-.001c1.246.007 2.56.091 3.906.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.166 8.095-2.592 9.989z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PanthosGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M12.333 5.333c-1.851 0-3.006.521-3.453 1.55a2.084 2.084 0 0 0-1.76 0c-.447-1.029-1.602-1.55-3.453-1.55C1.234 5.333 0 6.23 0 8c0 2.261 1.405 3.666 3.667 3.666A3.671 3.671 0 0 0 7.333 8c0-.162-.014-.315-.034-.462a1.378 1.378 0 0 1 1.402 0c-.02.147-.034.3-.034.462a3.671 3.671 0 0 0 3.666 3.666C14.595 11.666 16 10.261 16 8c0-1.77-1.234-2.667-3.667-2.667zM3.667 11c-2.708 0-3-2.098-3-3 0-.931.34-2 3-2 2.659 0 3 1.069 3 2 0 1.654-1.346 3-3 3zm8.666 0c-1.654 0-3-1.346-3-3 0-.931.341-2 3-2 2.66 0 3 1.069 3 2 0 .902-.292 3-3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const PillowGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M12.333 5.333c-2.077 0-3.034.002-3.428.887a2.05 2.05 0 0 0-1.81 0c-.394-.885-1.351-.887-3.428-.887C.667 5.333 0 5.333 0 8c0 2.666.667 2.666 3.667 2.666s3.666 0 3.666-2.666c0-.441-.02-.803-.064-1.11a1.351 1.351 0 0 1 1.462 0A7.914 7.914 0 0 0 8.667 8c0 2.666.666 2.666 3.666 2.666S16 10.666 16 8c0-2.667-.667-2.667-3.667-2.667zM6.276 9.765C5.895 10 4.96 10 3.666 10c-1.293 0-2.227 0-2.608-.235C.855 9.64.667 9.34.667 8c0-1.34.188-1.641.39-1.766C1.44 6 2.374 6 3.668 6c1.293 0 2.228 0 2.609.234.203.125.39.425.39 1.766 0 1.34-.187 1.64-.39 1.765zm8.666 0c-.38.235-1.315.235-2.609.235-1.293 0-2.228 0-2.609-.235-.203-.125-.39-.425-.39-1.765 0-1.34.187-1.641.39-1.766C10.105 6 11.04 6 12.334 6c1.293 0 2.227 0 2.608.234.203.125.391.425.391 1.766 0 1.34-.188 1.64-.39 1.765z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const RectangleGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.333 5.8h-6a.666.666 0 0 0-.666.666v.322a2.058 2.058 0 0 0-1.334 0v-.322a.666.666 0 0 0-.666-.666h-6A.666.666 0 0 0 0 6.466v3.067c0 .368.298.667.667.667h6a.666.666 0 0 0 .666-.667V7.516a1.358 1.358 0 0 1 1.334 0v2.017c0 .368.298.667.666.667h6A.666.666 0 0 0 16 9.533V6.466a.666.666 0 0 0-.667-.666zM6.667 9.533h-6V6.466h6v3.067zm8.666 0h-6V6.466h6v3.067z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RoundGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox = '0 4.333 16 7.333',
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M12.333 4.333a3.672 3.672 0 0 0-3.486 2.535 2.083 2.083 0 0 0-1.694 0 3.672 3.672 0 0 0-3.486-2.535A3.671 3.671 0 0 0 0 8a3.671 3.671 0 0 0 3.667 3.666A3.671 3.671 0 0 0 7.333 8c0-.157-.013-.311-.032-.463a1.378 1.378 0 0 1 1.398 0A3.724 3.724 0 0 0 8.667 8a3.671 3.671 0 0 0 3.666 3.666A3.671 3.671 0 0 0 16 8a3.671 3.671 0 0 0-3.667-3.667zM3.667 11c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3s3 1.345 3 3c0 1.654-1.346 3-3 3zm8.666 0c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3s3 1.345 3 3c0 1.654-1.346 3-3 3z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const SmoothRectangleGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 48,
  height = 48,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M37 16c-6.232 0-9.104.005-10.284 2.66a6.152 6.152 0 0 0-5.433-.001C20.104 16.005 17.231 16 11 16c-9 0-11 0-11 8s2 8 11 8 11 0 11-8c0-1.322-.062-2.408-.194-3.329a4.054 4.054 0 0 1 4.388 0C26.062 21.592 26 22.678 26 24c0 8 2 8 11 8s11 0 11-8-2-8-11-8zM18.827 29.297C17.684 30 14.88 30 11 30s-6.684 0-7.827-.703C2.564 28.922 2 28.021 2 24s.564-4.922 1.173-5.297C4.316 18 7.12 18 11 18s6.684 0 7.827.703C19.436 19.078 20 19.979 20 24s-.564 4.922-1.173 5.297zm26 0C43.684 30 40.88 30 37 30s-6.684 0-7.827-.703C28.564 28.922 28 28.021 28 24s.564-4.922 1.173-5.297C30.316 18 33.12 18 37 18s6.684 0 7.827.703C45.436 19.078 46 19.979 46 24s-.564 4.922-1.173 5.297z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const SquareGlassesIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M15.333 4.333h-6A.666.666 0 0 0 8.667 5v1.12a2.058 2.058 0 0 0-1.334 0V5a.666.666 0 0 0-.666-.667h-6A.666.666 0 0 0 0 5v6c0 .368.298.666.667.666h6A.666.666 0 0 0 7.333 11V6.85a1.358 1.358 0 0 1 1.334 0V11c0 .368.298.666.666.666h6A.666.666 0 0 0 16 11V5a.666.666 0 0 0-.667-.667zM6.667 11h-6V5h6v6zm8.666 0h-6V5h6v6z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassesLaserIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <rect x="38" y="44" width="2" height="4" />
      <rect x="44.062" y="40.5" transform="matrix(0.5 -0.866 0.866 0.5 -14.2744 60.2769)" width="2" height="4" />
      <rect x="43.062" y="34.5" transform="matrix(0.8661 -0.4999 0.4999 0.8661 -11.712 27.282)" width="4" height="2" />
      <rect x="31.938" y="33.5" transform="matrix(0.5 -0.866 0.866 0.5 -14.2747 46.2764)" width="2" height="4" />
      <rect
        x="30.938"
        y="41.5"
        transform="matrix(0.8661 -0.4999 0.4999 0.8661 -16.8353 22.1582)"
        width="4"
        height="2"
      />
      <path
        d="M6.764,17.723l1.922,0.553c0.786-2.732,2.942-7.444,8.548-8.302l-0.303-1.977C12.032,8.746,8.327,12.291,6.764,17.723z"
        fill={htmlColor}
      />
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
        c3.271,0,6.743-1.013,10.153-2.812c-0.219-0.644-0.387-1.31-0.503-1.994C22.881,43.015,19.567,44,16.55,44
        c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124C3.924,12.529,6.82,5.852,14.52,4.664
        C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72c1.228,1.544,1.583,3.361,1.083,5.552
        c-0.992,3.777-2.565,7.473-4.618,10.923c0.688,0.115,1.358,0.281,2.005,0.501c1.97-3.403,3.547-7.093,4.556-10.945
        C50.004,5.876,37.809,2,24.323,2z"
        fill={htmlColor}
      />
      <path
        d="M40,37.277V30h-2v7.277c-0.595,0.346-1,0.984-1,1.723c0,1.105,0.895,2,2,2s2-0.895,2-2C41,38.262,40.595,37.624,40,37.277z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LensGlassesPhotochromicIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M6.764,17.723l1.922,0.553c0.786-2.732,2.942-7.444,8.548-8.302l-0.303-1.977C12.032,8.746,8.327,12.291,6.764,17.723z"
        fill={htmlColor}
      />
      <path
        d="M16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124C3.924,12.529,6.82,5.852,14.52,4.664
        C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72c1.228,1.544,1.583,3.361,1.083,5.552
        c-0.992,3.776-2.564,7.471-4.617,10.919c0.689,0.114,1.359,0.279,2.007,0.497c1.968-3.401,3.543-7.089,4.552-10.938
        C50.004,5.876,37.809,2,24.323,2c-3.345,0-6.767,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087
        C10.512,45.09,13.441,46,16.55,46c3.278,0,6.757-1.017,10.173-2.822c-0.219-0.644-0.385-1.311-0.501-1.997
        C22.896,43.01,19.574,44,16.55,44z"
        fill={htmlColor}
      />
      <path
        d="M46.914,34.71c-0.139-0.255-0.289-0.503-0.451-0.742c-0.323-0.479-0.692-0.925-1.099-1.332C43.735,31.007,41.485,30,39,30
        c-4.971,0-9,4.029-9,9c0,4.971,4.029,9,9,9c2.485,0,4.735-1.007,6.364-2.636c0.407-0.407,0.776-0.853,1.099-1.332
        c0.162-0.239,0.312-0.487,0.451-0.742C47.607,42.015,48,40.553,48,39S47.607,35.985,46.914,34.71z M32,39c0-3.86,3.14-7,7-7v14
        C35.14,46,32,42.86,32,39z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RayBanGlassIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
    c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M45.781,15.272c-1.986,7.559-6.295,14.795-12.135,20.376
    C28.093,40.956,21.861,44,16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124
    C3.924,12.529,6.82,5.852,14.52,4.664C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72
    C45.927,11.264,46.281,13.081,45.781,15.272z M23.968,8.664c-0.17,0-0.308,0.137-0.308,0.306c0,0.17,0.137,0.308,0.308,0.308c0.169,0,0.306-0.137,0.306-0.308
    C24.274,8.801,24.137,8.664,23.968,8.664z M16.366,12.164c-0.234,0.135-0.343,0.389-0.251,0.548c0.092,0.16,0.371,0.187,0.606,0.051
    c0.234-0.135,0.336-0.382,0.244-0.542C16.872,12.062,16.6,12.029,16.366,12.164z M15.417,12.282l-4.062,1.456l0.003-0.279c0.001-0.038,0.008-0.077,0.058-0.113c0.214-0.136,0.876-0.503,1.56-1.501
    c0.911-1.33,0-2.447-1.424-2.143c-1.264,0.27-2.552,0.955-3.11,1.327C7.945,11.361,8.014,11.762,8,12.122
    c0.028,0.152,0.083,0.138,0.207-0.014c0.926-0.857,2.336-1.507,2.599-1.617c0.263-0.111,0.885-0.484,1.617-0.318
    c0.704,0.159,0.533,1.116,0.318,1.521c-0.235,0.442-0.905,1.085-1.21,1.244c-0.116,0.06-0.159,0-0.159-0.097l0.048-2.08
    c0-0.138-0.062-0.2-0.2-0.152L11,10.719c-0.152,0.09-0.145,0.124-0.159,0.235l-0.034,2.398c-0.001,0.052-0.025,0.093-0.054,0.106
    l-0.721,0.406c-0.166,0.083-0.124,0.207,0,0.249l0.581,0.276c0.138,0.055,0.18,0.097,0.18,0.235l-0.021,1.161
    c0.518-0.104,0.553-0.352,0.567-0.518v-0.352c0.014-0.083,0.062-0.111,0.131-0.097l1.686,0.816
    c0.152,0.069,0.235,0.041,0.346-0.152c0.111-0.194,0.124-0.29-0.083-0.387l-1.219-0.595l3.556-1.275L15.417,12.282z M23.287,9.785l-4.27,1.531c-0.142-0.114-0.285-0.234-0.354-0.315c-0.111-0.13-0.035-0.228,0.035-0.283
    c0.415-0.276,1.064-0.705,1.32-1.368c0.247-0.64-0.138-1.196-0.415-1.286c-0.379-0.123-1.182,0.124-1.583,0.871
    c-0.26,0.484-0.38,0.643-0.415,1.956c-0.007,0.249-0.014,0.532-0.014,0.843c0,0.311-0.035,1.154-0.069,1.313
    c-0.131,0.373,0.263,0.152,0.442,0.007c0.18-0.145,0.166-0.363,0.166-0.456c0-0.118,0.007-2.191,0.069-2.654
    c0.039-0.288,0.083-0.822,0.387-1.286c0.304-0.463,0.615-0.276,0.698-0.2c0.083,0.076,0.2,0.27,0.138,0.664
    c-0.097,0.611-0.802,1.279-0.981,1.486c-0.18,0.207-0.138,0.387-0.035,0.532c0.074,0.103,0.256,0.304,0.442,0.491
    s0.166,0.352,0.138,0.477c-0.097,0.373-1.106,1.382-1.106,1.382c-0.214,0.228,0.007,0.601,0.083,0.726
    c0.076,0.124,0.173,0.083,0.235-0.021c0.249-0.463,1.32-1.583,1.32-1.583c0.13-0.165,0.169-0.324,0.166-0.473l3.939-1.412
    L23.287,9.785z"
        stroke="none"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OakleyGlassIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24.323,2c-3.345,0-6.766,0.238-10.07,0.682C-3.222,5.376-3.791,33.631,7.992,43.087C10.512,45.09,13.441,46,16.55,46
    c12.054,0,26.83-13.666,31.174-30.251C50.004,5.876,37.809,2,24.323,2z M45.781,15.272c-1.986,7.559-6.295,14.795-12.135,20.376
    C28.093,40.956,21.861,44,16.55,44c-2.784,0-5.244-0.834-7.307-2.472c-6.039-4.847-8.832-15.883-6.357-25.124
    C3.924,12.529,6.82,5.852,14.52,4.664C17.798,4.224,21.096,4,24.323,4c9.91,0,17.527,2.138,20.375,5.72
    C45.927,11.264,46.281,13.081,45.781,15.272z M20.927,8.266c-0.56-0.18-1.138-0.225-1.721-0.221c-0.61,0.005-1.214,0.079-1.817,0.172
    c-0.701,0.108-1.391,0.261-2.071,0.459c-0.205,0.06-0.41,0.118-0.614,0.178c-0.43,0.149-0.86,0.298-1.291,0.447
    c-0.019,0.011-0.037,0.024-0.058,0.033c-0.425,0.185-0.854,0.361-1.274,0.555c-0.592,0.273-1.163,0.586-1.713,0.936
    c-0.545,0.347-1.071,0.721-1.525,1.185c-0.292,0.299-0.556,0.618-0.716,1.011c-0.218,0.537-0.067,1.029,0.421,1.34
    c0.134,0.085,0.283,0.152,0.432,0.206c0.481,0.173,0.984,0.219,1.491,0.233c1.045,0.028,2.07-0.132,3.086-0.358
    c0.672-0.149,1.334-0.34,1.988-0.555c1.38-0.454,2.698-1.04,3.937-1.8c0.658-0.403,1.269-0.867,1.789-1.442
    c0.265-0.292,0.49-0.609,0.592-0.997c0.112-0.424,0.001-0.782-0.337-1.065C21.351,8.433,21.146,8.336,20.927,8.266z M19.132,10.723
    c-0.135,0.344-0.363,0.624-0.622,0.882c-0.549,0.548-1.196,0.953-1.893,1.283c-0.82,0.388-1.679,0.655-2.575,0.799
    c-0.632,0.101-1.266,0.14-1.902,0.033c-0.292-0.049-0.576-0.13-0.835-0.278c-0.532-0.303-0.709-0.827-0.466-1.39
    c0.182-0.422,0.475-0.76,0.82-1.055c0.729-0.624,1.551-1.088,2.455-1.408c0.095-0.034,0.191-0.066,0.287-0.099
    c0.654-0.22,1.321-0.385,2.011-0.442c0.576-0.048,1.151-0.053,1.713,0.105c0.172,0.048,0.343,0.112,0.501,0.195
    C19.168,9.632,19.357,10.153,19.132,10.723z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const MagnifyingGlassIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  viewBox,
  width = 48,
  height = 48,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M48.374 46.293 35.482 33.401c3.212-3.548 5.185-8.238 5.185-13.401 0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20c5.163 0 9.853-1.973 13.401-5.185L46.96 47.707l1.414-1.414zM2.718 21h1.949v-2H2.718A17.896 17.896 0 0 1 7.272 8.019l1.375 1.375 1.414-1.414-1.375-1.375a17.896 17.896 0 0 1 10.981-4.554V4h2V2.051a17.896 17.896 0 0 1 10.981 4.554L31.273 7.98l1.414 1.414 1.375-1.375A17.9 17.9 0 0 1 38.616 19h-1.949v2h1.949a17.896 17.896 0 0 1-4.554 10.981l-1.375-1.375-1.414 1.414 1.375 1.375a17.9 17.9 0 0 1-10.981 4.554V36h-2v1.949a17.896 17.896 0 0 1-10.981-4.554l1.375-1.375-1.414-1.414-1.375 1.375A17.896 17.896 0 0 1 2.718 21z"
        fill={htmlColor}
      />
      <path
        d="M20.667 24a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"
        fill={htmlColor}
      />
      <path
        d="M20.667 29.245c5.753 0 10.777-3.311 13.49-8.24a2.076 2.076 0 0 0 0-2.01c-2.713-4.93-7.737-8.24-13.49-8.24s-10.777 3.31-13.49 8.24a2.076 2.076 0 0 0 0 2.01c2.713 4.929 7.737 8.24 13.49 8.24zM8.93 19.959c2.446-4.444 6.943-7.205 11.737-7.205 4.794 0 9.292 2.76 11.737 7.205a.079.079 0 0 1 0 .08c-2.446 4.445-6.943 7.205-11.737 7.205-4.794 0-9.292-2.761-11.737-7.204a.074.074 0 0 1 0-.081z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
