import React, { useCallback, useEffect } from 'react'
import { StyledPagination } from './Pagination.style'
import {
  PaginationProps as MuiPaginationProps,
  PaginationItemProps as MuiPaginationItemProps,
  SvgIconProps,
  PaginationItem,
} from '@mui/material'
import { IconChevronLeft, IconChevronRight } from '../Icons/VD/General'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { APP_MAIN_CONTAINER_ID } from '@constants/ui'
import { usePathname } from 'next/navigation'

export interface PaginationProps {
  page: MuiPaginationProps['page']
  count: MuiPaginationProps['count']
  boundaryCount?: MuiPaginationProps['boundaryCount']
  siblingCount?: MuiPaginationProps['siblingCount']
  renderItem?: MuiPaginationProps['renderItem']
  components?: MuiPaginationItemProps['components']
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void
  refine?: (page: number) => void
}

function Pagination(props: PaginationProps) {
  const renderIcon = (Icon: React.ComponentType<SvgIconProps>) => <Icon sx={{ fontSize: 16 }} />
  const router = useRouter()
  const pathname = usePathname()
  const { pageName, ...query } = router.query
  const { refine, page, count, onChange } = props

  const onPageClick = (pageNumber: number) => {
    if (refine) {
      refine(pageNumber - 1)
    }
    const appMainContainerElement = document.getElementById(APP_MAIN_CONTAINER_ID)
    appMainContainerElement?.scrollTo({ top: 0 })
  }

  const disableCrawlingForExtraPaginationLink = useCallback((extraPaginationLink: Element): void => {
    extraPaginationLink.setAttribute('rel', 'nofollow')
    extraPaginationLink.setAttribute('href', '/')
  }, [])

  useEffect(() => {
    const extraPaginationLink = document.querySelectorAll('.MuiPaginationItem-previousNext')[1]
    const isOnLastPaginationPage = page === count

    if (extraPaginationLink && isOnLastPaginationPage) {
      disableCrawlingForExtraPaginationLink(extraPaginationLink)
    } else {
      extraPaginationLink.removeAttribute('rel')
    }
  }, [count, disableCrawlingForExtraPaginationLink, page])

  return (
    <StyledPagination
      data-name="paginationNavigation"
      {...props}
      renderItem={item => (
        <PaginationItem
          component={Link}
          href={{
            pathname: pathname,
            query: { ...query, page: item.page },
          }}
          slots={{
            previous: () => renderIcon(IconChevronLeft),
            next: () => renderIcon(IconChevronRight),
          }}
          {...item}
          onClick={e => {
            e.preventDefault()
            refine ? onPageClick(item.page || 1) : onChange && onChange(e, item.page || 1)
          }}
          data-name={`page${item.page}`}
        />
      )}
    />
  )
}

export default Pagination
