import { Carousel } from '@components/common/components/Carousel'
import styled from '@mui/material/styles/styled'
import { teaserBackGroundColorToBg } from '../../../../utils/placements'

export const PreFooterContainer = styled('div', {
  name: 'PreFooter',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'bgColor',
})<{
  bgColor?: string
}>(({ theme, bgColor }) => ({
  ...teaserBackGroundColorToBg(bgColor, theme),
  paddingTop: theme.spacing(8),
  color: theme.palette.text.dark.primary,
}))

export const PreFooterCardContainer = styled('div', {
  name: 'PreFooter',
  slot: 'CardContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}))

export const PreFooterSwiper = styled(Carousel, {
  name: 'PreFooter',
  slot: 'Swiper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(10),
  '.slider-list': {
    marginBottom: theme.spacing(6),
  },
  '.slide': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: 165,
    marginLeft: theme.spacing(12),
    '&:first-of-type': { marginLeft: theme.spacing(2.5) },
    '&:last-of-type': { marginRight: theme.spacing(6) },
  },
}))

export const CustomProgressbar = styled('div', {
  name: 'PreFooter',
  slot: 'CustomProgressbar',
})(({ theme }) => ({
  width: '50%',
  margin: '0 auto',
  height: '2px',
  position: 'relative',
  backgroundColor: theme.palette.background.light.tertiary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
}))

export const ProgressbarItem = styled('span', {
  name: 'PreFooter',
  slot: 'ProgressbarItem',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'width',
})<{ active?: boolean; width: number }>(({ theme, active, width }) => ({
  width: `${width}%`,
  height: '2px',
  backgroundColor: theme.palette.background.dark.primary,
  opacity: active ? 1 : 0.2,
}))

export const PreFooterTitle = styled('div', {
  name: 'PreFooter',
  slot: 'Title',
})(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 20,
  textAlign: 'center',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
}))
