import React from 'react'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ItemLink, OmnichannelInfoWrapper, OmnichannelIconWrapper } from './OmniChannelLink.style'
import CmsIcon from '@components/Cms/CmsComponents/CmsIcon'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId?: string
  icon: string
  title: string
}

const OmniChannelLink: React.FC<IProps> = ({ actionSettings, dataElementId, icon, title }) => {
  const { basePath } = useStoreIdentity()
  const { locale, isLocaleDomain, domainLocales } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const link = `${basePath}${formattedUrl ?? ''}`

  return (
    <OmnichannelInfoWrapper>
      <OmnichannelIconWrapper>
        <CmsIcon teaserIcon={icon} />
      </OmnichannelIconWrapper>
      {ctaEnabled ? (
        <ItemLink
          href={link}
          data-element-id={dataElementId}
          locale={locale as string}
          isLocaleDomain={isLocaleDomain}
          locationOrigin={locationOrigin}
        >
          {title}
        </ItemLink>
      ) : (
        title
      )}
    </OmnichannelInfoWrapper>
  )
}

export default OmniChannelLink
