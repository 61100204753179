import { styled } from '@mui/material'
import Box from '@components/UI/Box/Box'
import Price from '@pages_views/ProductDetails/components/ProductPrice/Price'

export const StyledBundleBox = styled(Box, {
  name: 'BundleBox',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(6),
}))

export const StyledImageWrapper = styled('div', {
  name: 'BundleOffersImageWrapper',
})(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-around',
  [theme.breakpoints.up('md')]: {
    maxWidth: '350px',
  },
  '.media-wrapper': {
    picture: {
      position: 'relative',
    },
  },
}))

export const StyledOriginalPrice = styled(Price, {
  name: 'BundleOriginalPrice',
})(() => ({
  fontWeight: '600',
}))

export const StyledOfferPrice = styled(Price, {
  name: 'BundleOfferPrice',
})(({ theme }) => ({
  textDecoration: 'line-through',
  fontWeight: '600',
  color: theme.palette.color.error,
}))
