import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const UserIcon: React.FC<CustomSVGIconProps> = ({ htmlColor = 'none', width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 8.667A4.333 4.333 0 1 0 8 0a4.333 4.333 0 0 0 0 8.667zm0-7.334c1.655 0 3 1.346 3 3 0 1.655-1.345 3-3 3-1.654 0-3-1.345-3-3 0-1.654 1.346-3 3-3zM11.478 10H4.522C2.024 10 0 12.328 0 15.2v.133c0 .369.298.667.667.667h14.666a.666.666 0 0 0 .667-.667V15.2c0-2.872-2.024-5.2-4.522-5.2zM1.364 14.667c.215-1.881 1.549-3.334 3.158-3.334h6.957c1.609 0 2.943 1.453 3.158 3.334H1.364z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const LogoutIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width, height, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M1.333 13V3A1.65 1.65 0 0 1 3 1.333h1.667V0H3C1.333 0 0 1.333 0 3v10c0 1.667 1.333 3 3 3h1.667v-1.333H3A1.65 1.65 0 0 1 1.333 13z"
        fill={htmlColor}
      />
      <path
        d="m10.466 2.533-.933.934L13.4 7.333H3.333v1.334H13.4l-3.867 3.866.933.934L15.933 8l-5.467-5.467z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const PasswordShowVisibleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M 8.208 4.788 c -1.8858 0 -3.42 1.5342 -3.42 3.42 s 1.5342 3.42 3.42 3.42 s 3.42 -1.5342 3.42 -3.42 s -1.5342 -3.42 -3.42 -3.42 z m 0 6.156 c -1.5086 0 -2.736 -1.2274 -2.736 -2.736 s 1.2274 -2.736 2.736 -2.736 s 2.736 1.2274 2.736 2.736 s -1.2274 2.736 -2.736 2.736 z M 8.208 8.892 a 0.684 0.684 90 1 0 0 -1.368 a 0.684 0.684 90 0 0 0 1.368 z M 16.3411 7.8944 C 14.7751 4.8226 11.721 2.736 8.208 2.736 C 4.695 2.736 1.6409 4.8226 0.0749 7.8944 a 0.6946 0.6946 90 0 0 0 0.6276 C 1.6409 11.5934 4.695 13.68 8.208 13.68 c 3.513 0 6.5671 -2.0866 8.1331 -5.1584 a 0.6943 0.6943 90 0 0 0 -0.6272 z M 8.208 12.996 c -3.1361 0 -6.0192 -1.8335 -7.5236 -4.791 C 2.1888 5.2535 5.0719 3.42 8.208 3.42 c 3.1361 0 6.0192 1.8335 7.5236 4.791 C 14.2272 11.1625 11.3441 12.996 8.208 12.996 z"
        fill={htmlColor}
        stroke={htmlColor}
      />
    </IconWrapper>
  )
}

export const PasswordHideIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = 'none',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M8 4c2.696 0 5.19 1.525 6.579 4a8.114 8.114 0 0 1-1.763 2.166l.945.945a9.536 9.536 0 0 0 2.153-2.778.73.73 0 0 0 0-.665c-1.53-2.98-4.5-5.001-7.914-5.001-.813 0-1.6.118-2.35.333l1.112 1.113A7.14 7.14 0 0 1 8 4zM1.138.195.342.992l-.147.146 3.006 3.006A9.426 9.426 0 0 0 .077 7.687c-.1.195-.1.43 0 .626 1.527 2.99 4.502 5.02 7.923 5.02a8.517 8.517 0 0 0 3.596-.794l3.266 3.266.943-.943-.167-.167-14.5-14.5zM8 12c-2.697 0-5.19-1.524-6.579-4a8 8 0 0 1 2.744-2.892l2.032 2.031A2 2 0 0 0 8.86 9.803l1.714 1.714A7.175 7.175 0 0 1 8 12z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const RoundWarningIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <g clipPath="url(#xrqi5uq57a)" fill={htmlColor}>
        <path d="M12 24c6.617 0 12-5.383 12-12S18.617 0 12 0 0 5.383 0 12s5.383 12 12 12zm0-22c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2z" />
        <path d="M11 14h2V6h-2v8zM11 18h2v-2h-2v2z" />
      </g>
      <defs>
        <clipPath id="xrqi5uq57a">
          <path fill={htmlColor} transform="rotate(-180 12 12)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const RoundWarningSmallIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <g clipPath="url(#6bz14j603a)" fill={htmlColor}>
        <path d="M8 16c4.411 0 8-3.589 8-8s-3.589-8-8-8-8 3.589-8 8 3.589 8 8 8zM8 1.333A6.674 6.674 0 0 1 14.667 8 6.674 6.674 0 0 1 8 14.667 6.674 6.674 0 0 1 1.333 8 6.674 6.674 0 0 1 8 1.333z" />
        <path d="M7.333 9.334h1.333V4H7.333v5.334zM7.333 12h1.333v-1.333H7.333V12z" />
      </g>
      <defs>
        <clipPath id="6bz14j603a">
          <path fill="#fff" transform="rotate(-180 8 8)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const WarningIcon: React.FC<CustomSVGIconProps> = ({ htmlColor = 'none', width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <g clipPath="url(#41pydu9ura)" fill={htmlColor}>
        <path d="M8.667 5.334H7.334v5.333h1.333V5.334zM8.667 12H7.334v1.333h1.333V12z" />
        <path d="M15.763 12.916 9.783 1.168A1.978 1.978 0 0 0 8 .075c-.706 0-1.411.365-1.782 1.093L.237 12.916C-.482 14.328.543 16 2.127 16h11.746c1.584 0 2.609-1.672 1.89-3.084zm-1.218 1.374a.776.776 0 0 1-.672.377H2.127a.776.776 0 0 1-.672-.377.777.777 0 0 1-.03-.769L7.406 1.773A.65.65 0 0 1 8 1.409a.65.65 0 0 1 .594.364l5.981 11.748a.776.776 0 0 1-.03.77z" />
      </g>
      <defs>
        <clipPath id="41pydu9ura">
          <path fill="none" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}
