import wishListService from '@foundation/apis/transaction/wishList.service'
import { AppDispatch } from '@redux/store'
import { getAllProductAttributes } from '@utils/productAttributes'
import { removeWishList, setWishList } from './slice'

export const fetchWishlistAction = (storeId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setWishList({ isLoading: true }))

    const wishlist = await wishListService.getDefault(storeId)

    const productDetails = wishlist.x_data?.productDetails?.map(item => {
      return {
        ...item,
        productAttributes: getAllProductAttributes(item.attributes),
      }
    })

    dispatch(
      setWishList({
        GiftList: wishlist.GiftList,
        x_data: {
          productDetails: productDetails,
        },
        isLoading: false,
      })
    )
  } catch (error) {
    dispatch(setWishList({ isLoading: false }))
  }
}

export const clearWishlistAction = () => (dispatch: AppDispatch) => dispatch(removeWishList())
