import { WarningIcon } from '@components/UI/Icons/account'
import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import { CheckmarkRoundedValidIcon } from '@components/UI/Icons/check'
import { CloseCircleIcon } from '@components/UI/Icons/close'
import InputAdornment from '@mui/material/InputAdornment'
import MuiTextField from '@mui/material/TextField'
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/material/styles/useTheme'
import * as React from 'react'
import { CustomInputFieldProps } from '../../../types/inputFields'

export const StyledTextFieldWrapper = styled('div', {
  name: 'CustomThemeComponent',
  slot: 'Root',
  shouldForwardProp: prop => prop !== 'showvalidationstatus' && prop !== 'ispristine' && prop !== 'isvalid',
})<CustomInputFieldProps>(() => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  '& > div': {
    width: '100%',
  },
}))

export const StyledClearButton = styled(CloseCircleIcon, {
  name: 'TextField',
  slot: 'ClearButton',
})(() => ({
  cursor: 'pointer',
  height: 16,
}))

const BootstrapInput = styled(MuiTextField, {
  name: 'TextField',
  slot: 'Input',
  shouldForwardProp: prop => prop !== 'isCartRecap' && prop !== 'isLongHelper' && prop !== 'ispristine',
})<{ isCartRecap?: boolean; isLongHelper?: boolean }>(({ isCartRecap, isLongHelper, theme }) => ({
  '.MuiInputLabel-root': {
    fontWeight: 'normal',
    transform: 'unset',
    position: 'relative',
    top: '0',
    left: '12px',
    ...theme.typography.caption,
    color: theme.palette.neutral.main,
    '&.MuiInputLabel-shrink': {
      color: theme.palette.neutral.main,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.neutral.tones[100],
    letterSpacing: 'normal',
    padding: theme.spacing(4, 8),
    '.is-select &': {
      padding: 0,
      '.MuiSelect-select': {
        height: '40px',
        padding: theme.spacing(0, 0, 0, 8),
        display: 'flex',
        alignItems: 'center',
      },
      '.MuiMenuItem-root': {
        color: theme.palette.text.dark.primary,
        fontFamily: 'inherit',
        padding: theme.spacing(3, 4),
        '&:hover': {
          backgroundColor: theme.palette.neutral.tones[90],
        },
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: 8,
      border: `1px solid ${theme.palette.neutral.tones[80]}`,
      top: 0,
      legend: {
        display: 'none',
      },
    },
    '&.Mui-error > fieldset': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '.is-valid &:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.neutral.tones[80]}`,
    },
    '&:hover &:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.neutral.tones[0],
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.text.light.primary,
    color: theme.palette.text.dark.primary,
    fontSize: 16,
    width: '100%',
    lineHeight: theme.spacing(6),
    padding: theme.spacing(0, 4, 0, 0),
    height: theme.spacing(12),
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.text.light.tertiary,
    },
    '&::-webkit-search-decoration': { display: 'none' },
    '&::-webkit-search-cancel-button': { display: 'none' },
    '&::-webkit-search-results-button': { display: 'none' },
    '&::-webkit-search-results-decoration': { display: 'none' },
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active': {
      backgroundClip: 'content-box !important',
      webkitBoxShadow: `0 0 0 30px ${theme.palette.text.light.primary} inset`,
      boxShadow: `0 0 0 30px ${theme.palette.text.light.primary} inset`,
      WebkitTextFillColor: theme.palette.text.dark.primary,
    },
  },
  '.MuiFormHelperText-root.Mui-error': {
    position: 'relative',
    left: 0,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.error.main,
    top: isLongHelper ? '105%' : 'auto',
  },
  '.MuiInputAdornment-root': {
    margin: 0,
    color: theme.palette.text.dark.primary,
    '&.MuiInputAdornment-positionStart': {
      width: theme.spacing(15),
      marginRight: 0,
    },
    '&.MuiInputAdornment-positionEnd': {
      gap: theme.spacing(4),
    },
    '.is-select &': {
      position: 'absolute',
      '&.MuiInputAdornment-positionEnd': {
        right: theme.spacing(12),
      },
    },
  },
  '.MuiSelect-icon': {
    right: theme.spacing(4),
    top: isCartRecap ? theme.spacing(2) : theme.spacing(6),
    width: 16,
    height: 16,
  },
  '.MuiAutocomplete-inputRoot': {
    //TODO remove !important
    padding: `${theme.spacing(0, 4)} !important`,
    '.MuiAutocomplete-endAdornment': {
      position: 'static',
      display: 'flex',
      gap: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
}))

export const TextField = ({
  showvalidationstatus,
  showclearbutton,
  error,
  isfilled,
  isvalid,
  onClearButtonClick,
  customInputProps,
  SelectProps,
  type,
  select,
  isCartRecap,
  isLongHelper,
  ...props
}: CustomInputFieldProps) => {
  const theme = useTheme()

  const wrapperClassNames = ['dc-textfield-wrapper', ...(isvalid ? ['is-valid'] : []), ...(select ? ['is-select'] : [])]

  const getEndAdornments = () => {
    let validationIcons: React.ReactNode | null = null
    if (showclearbutton && !error && isfilled) {
      validationIcons = (
        <StyledClearButton
          onClick={() => onClearButtonClick && onClearButtonClick()}
          htmlColor={theme.palette.custom?.boulder}
        />
      )
    } else if (error && showvalidationstatus) {
      validationIcons = <WarningIcon htmlColor={theme.palette.color?.error} />
    } else if (isvalid && showvalidationstatus) {
      validationIcons = (
        <CheckmarkRoundedValidIcon
          width={16}
          height={16}
          htmlColor={theme.palette.color?.success}
        ></CheckmarkRoundedValidIcon>
      )
    }
    return (
      <InputAdornment position="end">
        {validationIcons}
        {customInputProps?.endAdornment}
      </InputAdornment>
    )
  }

  const getStartAdornments = () => {
    return customInputProps?.startAdornment ? (
      <InputAdornment position="start">{customInputProps?.startAdornment}</InputAdornment>
    ) : null
  }

  return (
    <StyledTextFieldWrapper
      className={wrapperClassNames.join(' ')}
      showvalidationstatus={showvalidationstatus}
      isvalid={isvalid}
    >
      <BootstrapInput
        InputProps={{
          ...customInputProps,
          startAdornment: getStartAdornments(),
          endAdornment: <InputAdornment position="end">{customInputProps?.endAdornment}</InputAdornment>,
        }}
        SelectProps={{
          ...SelectProps,
          IconComponent: ArrowDownIcon,
          MenuProps: {
            disableScrollLock: true,
            disablePortal: true,
            elevation: 0,
            marginThreshold: 0,
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          },
        }}
        type={type}
        error={error}
        variant="outlined"
        select={select}
        isCartRecap={isCartRecap}
        isLongHelper={isLongHelper}
        {...props}
      />
    </StyledTextFieldWrapper>
  )
}
