import { FC, MouseEvent, TouchEvent, useEffect, useRef, useState } from 'react'
import {
  ProductImageZoomCloseIcon,
  ProductImageZoomMagnifiedImageWrapper,
  ProductImageZoomMagnifier,
  ProductImageZoomWrapper,
} from './ProductImageZoom.style'

// TYPES
import { Attachment } from '@typesApp/product'
//UI
import { StyledLoadingWrapper } from '@components/ProductTile/ProductTile.style'
import { CloseIcon } from '@components/UI/Icons/close'
import { StyledLoader } from '@components/UI/Loader'
import useTheme from '@mui/material/styles/useTheme'
import { ProductZoomImagesSlider } from '../../ProductDetails.style'
import ProductImagesThumbnails from '../ProductImagesThumbnails'

/**
 * @param { Attachment[] } images slider images
 * @param { 1 | 0 } isvisible toggle the component visibility
 * @param { boolean } pdpDataloading pdp data loading status
 * @param { function } onCloseClick callback when clicking on close button
 * @param { number } currentImageIndex you can force the image index
 * @param { number } zoomedImgSize zoomed image dimension
 * @param { number } lensWidth magnifier lens width
 * @param { number } lensHeight magnifier lens height
 */

export interface ProductImageZoomProps {
  zoomedImgSize?: number
  images?: Attachment[]
  isvisible?: 1 | 0
  onCloseClick?: () => void
  currentImageIndex?: number
  pdpDataloading?: boolean
  lensWidth?: number
  lensHeight?: number
}

const ProductImageZoom: FC<ProductImageZoomProps> = ({
  images,
  onCloseClick,
  isvisible,
  lensWidth = 200,
  lensHeight = 200,
  currentImageIndex,
}: ProductImageZoomProps) => {
  const theme = useTheme()
  const largeImageRef = useRef<HTMLDivElement>(null)
  const [zoomedImageIndex, setZoomedImageIndex] = useState<number | undefined>(currentImageIndex)
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  useEffect(() => {
    setZoomedImageIndex(currentImageIndex)
  }, [currentImageIndex])

  const getCursorPos = (e: MouseEvent<HTMLImageElement> | TouchEvent<HTMLImageElement>) => {
    if (e.type === 'touchmove') {
      const img = e.target as HTMLElement

      const a = img?.getBoundingClientRect()
      const pageX = (e as TouchEvent).targetTouches[0].pageX
      const pageY = (e as TouchEvent).targetTouches[0].pageY

      const x = pageX - a.left
      const y = pageY - a.top

      return { x: x, y: y }
    }
    const img = e.currentTarget as HTMLElement
    /*get the x and y positions of the image:*/
    const a = img?.getBoundingClientRect()
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    const x = (e as MouseEvent<HTMLImageElement>).pageX - a.left
    const y = (e as MouseEvent<HTMLImageElement>).pageY - a.top
    return { x: x, y: y }
  }

  const moveLens = (e: MouseEvent<HTMLImageElement> | TouchEvent<HTMLImageElement>) => {
    const pos = getCursorPos(e)
    /*calculate the position of the lens:*/

    const img = e.currentTarget
    const style = (largeImageRef.current && largeImageRef.current.style) || {}
    const imgWidth = img.width
    const imgHeight = img.height
    //calculate the ratio between visible image and true image size
    const zoomedImageRatio = devicePixelRatio > 1 ? img.naturalWidth / imgWidth : 2 * (img.naturalWidth / imgWidth)
    let lensPositionX = pos.x - lensWidth / 2
    let lensPositionY = pos.y - lensHeight / 2

    /*prevent the lens from being positioned outside the image:*/
    if (lensPositionX > imgWidth - lensWidth) {
      lensPositionX = imgWidth - lensWidth
    }
    if (lensPositionX < 0) {
      lensPositionX = 0
    }
    if (lensPositionY > imgHeight - lensHeight) {
      lensPositionY = imgHeight - lensHeight
    }
    if (lensPositionY < 0) {
      lensPositionY = 0
    }

    // Set the background of the magnified image
    style['background'] = `url(${e.currentTarget.currentSrc}) no-repeat #ffffff `
    style['backgroundSize'] = imgWidth * zoomedImageRatio + 'px ' + imgHeight * zoomedImageRatio + 'px'
    style['backgroundPosition'] =
      '-' +
      (lensPositionX + lensWidth / 2 - 100 / zoomedImageRatio) * zoomedImageRatio +
      'px -' +
      (lensPositionY + lensWidth / 2 - 100 / zoomedImageRatio) * zoomedImageRatio +
      'px'
    // Move the magnifying glass with the mouse movement.
    style['left'] = lensPositionX + 'px'
    style['top'] = lensPositionY + 'px'
  }
  const onMouseHover = (e: MouseEvent<HTMLImageElement>) => {
    moveLens(e)
  }
  const onTouchMove = (e: TouchEvent<HTMLImageElement>) => {
    moveLens(e)
  }

  if (!isvisible) return null

  return (
    <ProductImageZoomWrapper isvisible={isvisible}>
      <ProductImageZoomCloseIcon
        onClick={() => {
          onCloseClick && onCloseClick()
          setZoomedImageIndex(undefined)
        }}
      >
        <CloseIcon htmlColor={theme.palette.primary.main} />
      </ProductImageZoomCloseIcon>
      <ProductImagesThumbnails
        images={images}
        currentIndex={zoomedImageIndex!}
        thumbnailImageProps={{ usage: 'PDP', width: 160, lazy: false }}
        isGalleryMode={isvisible}
        onThumbnailClick={index => {
          setIsImageLoaded(false)
          setZoomedImageIndex(index)
        }}
      />
      <ProductImageZoomMagnifiedImageWrapper>
        {images && (
          <>
            {!isImageLoaded && (
              <StyledLoadingWrapper>
                <StyledLoader size={60} />
              </StyledLoadingWrapper>
            )}

            <ProductZoomImagesSlider
              currentDisplayedSlide={zoomedImageIndex}
              slideIndex={zoomedImageIndex}
              swiping={false}
              images={images}
              sliderImageProps={{
                onMouseMove: onMouseHover,
                onTouchMove: onTouchMove,
                width: 2400,
                usage: 'PDP',
                lazy: true,
              }}
              loop={false}
              onImageLoaded={() => setIsImageLoaded(true)}
            />
          </>
        )}

        <ProductImageZoomMagnifier ref={largeImageRef} lensWidth={lensWidth} lensHeight={lensHeight} />
      </ProductImageZoomMagnifiedImageWrapper>
    </ProductImageZoomWrapper>
  )
}

export default ProductImageZoom
