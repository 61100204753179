import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Box, TooltipProps as MuiTooltipProps } from '@mui/material'
import { IconCross } from '../Icons/VD/General'

export const BootstrapTooltip = styled(Tooltip, {
  name: 'Tooltip',
  slot: 'Root',
  shouldForwardProp: prop => prop !== 'background',
})<{ background: string | undefined }>(({ theme, background }) => {
  let backgroundColor: MuiTooltipProps['color']
  let color: MuiTooltipProps['color']

  switch (background) {
    case 'background11':
      backgroundColor = theme.palette.background[11]
      break
    case 'neutral':
      backgroundColor = theme.palette.background.neutral
      break
    case 'background3':
      backgroundColor = theme.palette.background[3]
      color = theme.palette.background.neutral
      break
    default:
      backgroundColor = theme.palette.background.primary
  }
  return {
    [`&.${tooltipClasses.popper}`]: {
      maxWidth: 264,

      [`&[data-popper-placement="right"] .${tooltipClasses.tooltip}`]: {
        marginLeft: theme.spacing(4),
      },
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor,
      color: color,
      padding: theme.spacing(4, 8),
    },
  }
})

export const TooltipContentBlock = styled('div', {
  name: 'Tooltip',
  slot: 'ContentBlock',
})(() => ({}))

export const TooltipContentRight = styled(Box, {
  name: 'Tooltip',
  slot: 'ContentRight',
})({
  float: 'right',
})

export const TooltipContent = styled(Box, {
  name: 'Tooltip',
  slot: 'Content',
})(() => ({}))

export const TooltipCross = styled(IconCross, {
  name: 'Tooltip',
  slot: 'Cross',
})({
  fontSize: '0.625rem',
  '&:hover': { cursor: 'pointer' },
})

export const StyledContent = styled('div', {
  name: 'Tooltip',
  slot: 'Content',
})(() => ({
  display: 'flex',
}))
