import { StyledAnchor } from '@components/UI/Anchor'
import { Button } from '@components/UI/Button'
import { StyledFormControlLabel } from '@components/UI/Checkbox/StyledFormControlLabel'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import styled from '@mui/material/styles/styled'

export const Container = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'Container',
})(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 375,
  },
}))

export const Header = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'Header',
})(({ theme }) => ({
  background: theme.palette.background.dark.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
}))

export const Title = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.light.primary,
  fontWeight: 600,
  lineHeight: 1.43,
  letterSpacing: 'normal',
  textTransform: 'uppercase',
}))

export const Content = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'Content',
})(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.text.dark.tertiary,
  padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
}))

export const Image = styled('img', {
  name: 'ProductAvailableDrawer',
  slot: 'Image',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  width: '100%',
  height: 'auto',
}))

export const AttributeLabel = styled('span', {
  name: 'ProductAvailableDrawer',
  slot: 'AttributeLabel',
})(() => ({
  fontWeight: 'bold',
}))

export const AttributesContent = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'AttributesContent',
})(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(10),
}))

export const PrivacyPolicyContainer = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'PrivacyPolicyContainer',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  color: theme.palette.text.light.secondary,
  lineHeight: 1.33,
  fontSize: 12,
  marginBottom: theme.spacing(6),
}))

export const PrivacyLink = styled(StyledAnchor, {
  name: 'ProductAvailableDrawer',
  slot: 'PrivacyLink',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.light.secondary,
}))

export const SubscribeButton = styled(Button, {
  name: 'ProductAvailableDrawer',
  slot: 'SubscribeButton',
})(() => ({}))

export const CloseSubscribeButton = styled(Button, {
  name: 'ProductAvailableDrawer',
  slot: 'CloseSubscribeButton',
})(() => ({}))

export const Description = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'Description',
})(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

export const DescriptionSuccess = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'DescriptionSuccess',
})(() => ({
  fontSize: 24,
}))

export const CheckboxContainer = styled('div', {
  name: 'ProductAvailableDrawer',
  slot: 'CheckboxContainer',
})(({ theme }) => ({
  fontSize: 14,
  display: 'flex',
  margin: `${theme.spacing(6)} 0`,
  verticalAlign: 'flex-start',
}))

export const StyledOOSFormControlLabel = styled(StyledFormControlLabel, {
  name: 'ProductAvailableDrawer',
  slot: 'StyledOOSFormControlLabel',
})(({ theme }) => ({
  alignItems: 'start',

  '.MuiFormControlLabel-root': {
    display: 'flex',
    verticalAlign: 'flex-start',
  },
  '.MuiFormControlLabel-label': {
    maxWidth: 'none',
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
}))

export const StyledProductImage = styled(ProductMedia, {
  name: 'ProductAvailableDrawer',
  slot: 'SubscribeButton',
})(() => ({
  width: '100%',
  height: '100%',
}))
