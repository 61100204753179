import { InfoOutlinedIcon } from '@components/UI/Icons/info'
import { StyledTooltip } from '@components/UI/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styled from '@mui/material/styles/styled'
import { getIsGeoFit, getIsPhotochromic, getIsPolarized } from '@utils/productAttributes'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { IProduct } from '../../../types/product'

interface IProductDetailsList {
  currentProduct: IProduct
}

export const StyledSpecialFrameInfoWrapper = styled('div', {
  name: 'SpecialFrameInfo',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: 12,
  color: theme.palette.text.dark.primary,
}))

const StyledProductSpecialAttributeSpan = styled('span', {
  name: 'SpecialFrameInfo',
  slot: 'AttributeSpan',
})(({ theme }) => ({
  display: 'flex',
  margin: `0 ${theme.spacing()} 0 ${theme.spacing()}`,

  span: {
    fontWeight: 'bold',
    marginRight: theme.spacing(),
  },
}))

interface IStyledProductSpecialAttributeProps {
  attr: string
}

const StyledProductSpecialAttribute = ({ attr }: IStyledProductSpecialAttributeProps) => {
  const { t } = useTranslation()

  const attrName = () => {
    switch (true) {
      case attr === 'universal fit':
        return 'UNIVERSALFIT'
      case attr === 'low bridge fit':
        return 'LOWBRIDGEFIT'
      case attr === 'high bridge fit':
        return 'HIGHBRIDGEFIT'
      case attr === 'adjustable nosepads':
        return 'ADJUSTABLENOSEPAD'
      case attr === 'photochromic':
        return 'PHOTOCHROMIC'
      case attr === 'polarized':
        return 'POLARIZED'
      default:
        return attr
    }
  }

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => setOpen(false)

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledProductSpecialAttributeSpan>
        <span>{t(`ProductDetails.SpecialFrameInfo.Label.${attrName()}`)}</span>
        <StyledTooltip placement="top" text={t(`ProductDetails.SpecialFrameInfo.Tooltip.${attrName()}`)} open={open}>
          <div>
            <InfoOutlinedIcon />
          </div>
        </StyledTooltip>
      </StyledProductSpecialAttributeSpan>
    </ClickAwayListener>
  )
}

export const StyledSpecialFrameInfo = ({ currentProduct }: IProductDetailsList) => {
  const { t } = useTranslation()

  const geoFit = getIsGeoFit(currentProduct)
  const photochromic = getIsPhotochromic(currentProduct) ? 'photochromic' : ''
  const polarized = getIsPolarized(currentProduct) ? 'polarized' : ''

  const attributes = [geoFit, photochromic, polarized].filter(item => item)

  return attributes && attributes.length > 0 ? (
    <StyledSpecialFrameInfoWrapper>
      {t('ProductDetails.SpecialFrameInfo.Label.modelFeatures')}
      {attributes.map((attr, index) =>
        index >= 1 ? (
          <React.Fragment key={attr + index}>
            {' '}
            and <StyledProductSpecialAttribute key={`product-attribute-${attr}-${index}`} attr={attr.toLowerCase()} />
          </React.Fragment>
        ) : (
          <React.Fragment key={`product-attribute-wrapper-${attr}-${index}`}>
            {' '}
            <StyledProductSpecialAttribute
              key={`product-attribute-special-${attr}-${index}`}
              attr={attr.toLowerCase()}
            />
          </React.Fragment>
        )
      )}
    </StyledSpecialFrameInfoWrapper>
  ) : null
}
