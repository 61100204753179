import type { CustomSVGIconProps } from '../icon.type'
import IconWrapper from '../IconWrapper'
import React from 'react'

export const ArrowSlimDownIcon: React.FC<CustomSVGIconProps> = ({ width = 64, height = 64, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <g>
        <polygon points="31,0 31,60.586 23.707,53.293 22.293,54.854 31.293,64 32.707,64 41.707,54.854 40.293,53.366 33,60.586 33,0 	" />
      </g>
    </IconWrapper>
  )
}

export const ArrowDownIcon: React.FC<CustomSVGIconProps> = ({ htmlColor, width = 16, height = 16, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path d="M8 12.943.195 5.138l.943-.943L8 11.057l6.862-6.862.943.943L8 12.943z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const ArrowLeftIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path d="M10.862 15.805 3.057 8 10.862.195l.942.943L4.943 8l6.861 6.862-.942.943z" fill={htmlColor} />
    </IconWrapper>
  )
}

export const ArrowRightIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path d="m5.138 15.805-.943-.943L11.057 8 4.195 1.138l.943-.943L12.943 8l-7.805 7.805z" fill={htmlColor} />{' '}
    </IconWrapper>
  )
}

export const ArrowUpIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor = '#767676',
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M14.862 11.8043L7.99998 4.94264L1.13798 11.8043L0.195312 10.8616L7.99998 3.05664L15.8046 10.8616L14.862 11.8043Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const UnicodeArrowLeftIcon: React.FC<CustomSVGIconProps> = ({ width = 24, height = 24, ...rest }) => (
  <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 12.5316V11.4711L15.5 9V10.0605L10.002 12.0013L15.5 13.9422V15.0026L8.5 12.5316Z"
      fill="black"
    />
  </IconWrapper>
)

export const UnicodeArrowRightIcon: React.FC<CustomSVGIconProps> = ({ width = 24, height = 24, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11.9709L15.5 13.0314L8.5 15.5024L8.5 14.442L13.998 12.5011L8.5 10.5603L8.5 9.4998L15.5 11.9709Z"
        fill="black"
      />
    </IconWrapper>
  )
}