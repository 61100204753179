import React from 'react'
import { BrandImg, StyledTopPageTitle, WrapperBrandIcon } from './BrandIcon.style'
import brandList from './brandList'

const BrandIcon: React.FC<{
  name: string
  isInverted?: boolean
  width?: number
  height?: number
  isTabletLandscape?: boolean
  lazy?: boolean
}> = ({ name, isInverted, width, isTabletLandscape, height, lazy }) => {
  const brand = brandList.find(brand => brand.name === name)

  const brandName = brand?.name
  const isPradaLineaRossa = brandName === 'Prada Linea Rossa'

  return brand ? (
    <WrapperBrandIcon width={width} isTabletLandscape={isTabletLandscape} height={height}>
      <BrandImg
        effect="opacity"
        src={brand.logo}
        alt={brandName}
        isInverted={isInverted!}
        isPradaLineaRossa={isPradaLineaRossa}
      />
    </WrapperBrandIcon>
  ) : (
    <WrapperBrandIcon>
      <StyledTopPageTitle isLogo={true}>{name}</StyledTopPageTitle>
    </WrapperBrandIcon>
  )
}
export default BrandIcon
