import { useCallback } from 'react'
import { Attachment, IProduct, ProductRightColumnProps } from '../../../../types/product'
import {
  FramesPdpRightColumnInfo,
  FramesPdpRightColumnInfoContainer,
  StyledImageWrapper,
  StyledRelatedProduct,
  StyledSeparator,
} from '../../ProductDetails.style'

import { usePrescriptionLenses } from '@components/PrescriptionLenses/PrescriptionLensesContext'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import { NOT_FOUND } from '@constants/routes'
import { toggleAddToCart } from '@features/product/slice'
import useProductData from '@hooks/useProductData/useProductData'
import { useTheme } from '@mui/material/styles'
import { getCurrentProductItemByUniqId, isSunProduct } from '@utils/product'
import { getSoldOut } from '@utils/productAttributes'
import { seoUrlConcat } from '@utils/url'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import ProductColorSelector from '../../components/ProductColorSelector'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import { ProductDetailsList } from '../ProductDetailsList'
import { ProductNameSection } from '../ProductNameSection'
import ProductPriceNew from '../ProductPriceNew'
import ProductSize from '../ProductSize'
import { StyledSpecialFrameInfo } from '../StyledSpecialFrameInfo'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import useProductPrice from '@hooks/useProductPrice'
import { locationOriginSelector } from '@redux/selectors/site'

export const FramesPdpRightColumn = ({
  partNumber,
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  onClusterProductClick,
  isSoldout,
  isRoxable,
  pdpData,
  onlyFewPieces,
}: ProductRightColumnProps) => {
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const dispatch = useDispatch()
  const locationOrigin = useSelector(locationOriginSelector)

  const { suggestedProducts: relatedProducts } = useProductData(pdpData, partNumber)

  const onSizeChange = useCallback(
    (id: string | undefined) => {
      const selectedSizeItem = !!id ? getCurrentProductItemByUniqId(id, cluster) : null
      if (!selectedSizeItem) return
      router.push(selectedSizeItem?.seo?.href ?? '')
    },
    [cluster, router]
  )

  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false

  const getAttachments = (cluster: IProduct): Attachment[] => cluster?.cluster?.[0]?.attachments || []

  const getSequence = (cluster: IProduct) => {
    const attachments = cluster[0]?.attachments
    const hasFrontalAttachment = attachments.some(({ sequence }) => sequence === '15.0')

    return hasFrontalAttachment ? '15.0' : undefined
  }

  const getItems = (product: IProduct) => {
    return product.sKUs != null ? product.sKUs : product.items
  }

  const getMocoLinkTo = (cluster: IProduct | undefined) => {
    let href = ''
    const product = cluster?.cluster?.[0]
    const items = product && getItems(product)
    href = items?.[0]?.seo?.href || NOT_FOUND
    const finalHref = seoUrlConcat(langCode, href)
    return finalHref
  }

  const productLensColor = currentProduct.productAttributes['LENS_COLOR']
  const productFrontColor = currentProduct.productAttributes['FRONT_COLOR']
  const productType = currentProduct?.productAttributes['PRODUCT_TYPE']
  const isOpticalPage = productType === 'Optical'
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)
  const { setPrescriptionLensesFormOpen, setPrescriptionLenses } = usePrescriptionLenses()

  const selectLenses = () => {
    currentProduct && setPrescriptionLenses(currentProduct)
    setPrescriptionLensesFormOpen(true)
  }

  dispatch(toggleAddToCart(true))

  return (
    <>
      {currentProduct && <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} />}

      {currentProduct && (
        <ProductSize
          onSizeChange={onSizeChange}
          currentProduct={currentProduct}
          cluster={cluster}
          partNumber={partNumber}
        />
      )}

      {!!relatedProducts?.length && (
        <StyledRelatedProduct
          href={getMocoLinkTo(relatedProducts[0])}
          locale={router.locale as string}
          isLocaleDomain={router.isLocaleDomain}
          locationOrigin={locationOrigin}
        >
          <StyledImageWrapper>
            <ProductMedia
              sequence={getSequence(relatedProducts[0])}
              attachments={getAttachments(relatedProducts[0])}
              backgroundColor={theme.palette.background.light.primary}
              lazy={true}
            />
          </StyledImageWrapper>
          <span>
            {isSunProduct(relatedProducts[0])
              ? t('ProductDetails.Suggested.ShopInSun')
              : t('ProductDetails.Suggested.ShopInOptical')}
          </span>
        </StyledRelatedProduct>
      )}
      {currentProduct && !isSoldout && (
        <>
          <StyledSpecialFrameInfo currentProduct={currentProduct} />
          <StyledSeparator />
        </>
      )}

      {!areAllClusterItemsSoldOut &&
        (cluster && cluster?.length > 1 ? (
          <>
            <ProductColorSelector
              currentProductItem={currentProduct}
              cluster={cluster}
              selectedPartNumber={partNumber}
              soldOutStatus={soldOutStatus}
              onClick={onClusterProductClick}
            />
            <StyledSeparator />
          </>
        ) : (
          <>
            <FramesPdpRightColumnInfoContainer>
              <FramesPdpRightColumnInfo>
                <span>{t('ProductDetails.SpecialFrameInfo.Label.Frame')}:</span>
                <span>{productFrontColor}</span>
              </FramesPdpRightColumnInfo>

              {!isOpticalPage ? (
                <FramesPdpRightColumnInfo>
                  <span>{t('ProductDetails.SpecialFrameInfo.Label.Lenses')}: </span>
                  <span>{productLensColor}</span>
                </FramesPdpRightColumnInfo>
              ) : null}
            </FramesPdpRightColumnInfoContainer>
            <StyledSeparator />
          </>
        ))}

      {currentProduct && currentProduct.x_price && !isSoldout && (
        <ProductPriceNew isPDP={true} price={prices} productType={parseProductTypeForPrice(productType)} />
      )}
      {currentProduct && currentProduct.x_price && (
        <ProductCtaContainer
          productInCart={productInCart}
          type={soldOutStatus}
          currentProduct={currentProduct}
          roxable={isRoxable}
          error={addToCartButtonFail}
          ctaRef={ctaRef}
          addToCartFillType={'outline'}
          onlyFewPieces={onlyFewPieces}
        />
      )}
      <OmniChannelCarousel placements={placements} />
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.productAttributes}
        product={currentProduct || undefined}
      />
      {currentProduct && !isSoldout && <ProductDetailsList type={soldOutStatus} currentProduct={currentProduct} />}
    </>
  )
}
