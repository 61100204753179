import { Container, styled, containerClasses as MuiContainerClasses } from '@mui/material'

export const StyledContainer = styled(Container, {
  name: 'StyledContainer',
})(({ theme }) => ({
  height: 'inherit',

  [`&.${MuiContainerClasses.root}`]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),

    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(32),
      paddingRight: theme.spacing(32),
    },
  },
}))
