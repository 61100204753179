import type { PaginationRenderState } from 'instantsearch.js/es/connectors/pagination/connectPagination'
import { StyledPagionationLayoutContainer } from './PaginationLayout.style'
import Pagination from '@components/UI/Pagination'

interface Props {
  paginationState: PaginationRenderState
}

const PaginationLayout: React.FC<Props> = ({ paginationState }) => {
  const { nbPages: totalPages, currentRefinement, refine } = paginationState
  const currentPage = currentRefinement + 1

  return (
    <StyledPagionationLayoutContainer>
      {totalPages > 1 && <Pagination page={currentPage} count={totalPages} refine={refine} />}
    </StyledPagionationLayoutContainer>
  )
}

export default PaginationLayout
