import useTheme from '@mui/material/styles/useTheme'
import { useTranslation } from 'next-i18next'
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useClearRefinements, useCurrentRefinements, useInstantSearch, useMenu, useSortBy } from 'react-instantsearch'
import { TransitionGroup } from 'react-transition-group'

import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import { ArrowUpIcon } from '@components/UI/Icons/arrows'
import { Pill } from '@components/UI/Pill'
import type { SearchResults } from 'algoliasearch-helper'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import useScrollTo from '../../../../hooks/useScrollTo'
import useScrollingUp from '../../../../hooks/useScrollingUp/useScrollingUp'
import useStickyScroll from '../../../../hooks/useStickyScroll'
import SizeAdvisorUtil from '../../../../utils/FrameGenius/SizeAdvisorUtil'
import {
  ClearAllContainer,
  ClearAllLink,
  FilterCTASContainer,
  FilterCTASWrapper,
  FiltersAppliedContainer,
  SquaredFilterPill,
  SquaredFilterPillWrapper,
  StyledFade,
  StyledFilterResultWrapper,
  StyledFilterWrapper,
  StyledPLPFiltersBar,
  StyledPLPFiltersBarButtonsWrapper,
  StyledPLPHeaderTitleWrapper,
  StyledPLPHeaderWrapper,
  StyledPLPOffersFiltersWrapper,
  StyledPlpHeaderWrapperDesktop,
  StyledPlpHeaderWrapperMobile,
  StyledResultsFor,
  StyledScrollToTopButton,
} from './PlpHeader.style'
import PLPHeaderFilterAlgolia from './components/PlpHeaderFilterAlgolia'
import PlpHeaderTitle from './components/PlpHeaderTitle'

import { ICategory } from '@features/category/query'
import { useProductParams } from '@hooks/useProductParams'
import { IAlgoliaHit } from '@typesApp/product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { Refinement } from '@utils/Plp/PlpReducer'
import { flatRefinementList } from '@utils/Plp/Utils'
import { getFacetLabel } from '@utils/productAttributesAlgolia'
import { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu'
import { TransformItems } from 'instantsearch.js/es/types/widget'
import { IconCrossThree } from '@components/UI/Icons/VD/General'
import Divider from '@components/UI/Divider'

interface PLPHeaderAlgoliaProps {
  title?: string
  catalogLoading?: boolean
  searchTerm?: string
  category: ICategory[] | null | undefined
  productData: any
  isPlacementsBetween?: boolean
  productTypePillFilters?: JSX.Element
}

const transformItems: TransformItems<MenuItem> = items => {
  return items
    .filter(el => +el.value >= 1)
    .sort((a, b) => Number(a.value) - Number(b.value))
    .map(item => {
      const _item = { ...item }
      const priceArray = _item.label.split('.')
      if (+priceArray[1] === 0) _item.label = priceArray[0]
      if (!_item.label.endsWith('OFF')) _item.label += '% OFF'
      return _item
    })
}

const isDiscountFilterApplied = indexUiState => {
  return Object.keys(indexUiState?.menu || {}).some(key => key.includes('sort.discount'))
}

const PlpHeaderAlgolia: FC<PLPHeaderAlgoliaProps> = ({
  title,
  catalogLoading,
  searchTerm,
  isPlacementsBetween,
  productData,
}) => {
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const theme = useTheme()
  const scrolled = useScrollingUp()
  const scrollTo = useScrollTo()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const { results, indexUiState } = useInstantSearch()
  const { items: currentRefinements } = useCurrentRefinements()
  const [sortDiscountAllSelected, setSortDiscountAllSelected] = useState<boolean>(isDiscountFilterApplied(indexUiState))
  const { refine, currentRefinement } = useSortBy({
    items: plpState.sortOrderOptions,
  })
  const deleteIcon = <IconCrossThree />

  const { nbHits } = results as SearchResults<IAlgoliaHit>

  useEffect(() => {
    setSortDiscountAllSelected(isDiscountFilterApplied(indexUiState))
  }, [indexUiState])

  useEffect(() => {
    if (plpState.firstLoad || !plpState.openDrawerFilters) {
      plpDispatch({
        type: 'SET_MULTIPLE',
        payload: {
          _internalAppliedFacets: flatRefinementList(currentRefinements),
          appliedFacets: flatRefinementList(currentRefinements),
          shouldLoadUngrouped: false,
        },
      })
    }
  }, [currentRefinements, plpDispatch, plpState.firstLoad, plpState.openDrawerFilters])

  const [isFiltersBarStuck, setFiltersBarStuck] = useState<boolean>(false)
  const plpHeaderWrapperRef = useRef<HTMLDivElement | null>(null)
  const plpDesktopHeaderRef = useRef<HTMLDivElement | null>(null)
  const plpMobileHeaderRef = useRef<HTMLDivElement | null>(null)
  const filtersBarRef = useRef<HTMLDivElement>(null)

  const isSearchTerm = searchTerm !== ''
  const isSticky = useStickyScroll(isDesktop ? plpDesktopHeaderRef : plpMobileHeaderRef, plpHeaderWrapperRef)

  const selectedFacets = plpState.appliedFacets.filter(el => !el.attribute.startsWith('attributes.'))
  const appliedFiltersNumberForSearch = selectedFacets.length

  const shouldShowActiveFilters = !(
    appliedFiltersNumberForSearch === 1 && selectedFacets[0].attribute.startsWith('sort.discount.')
  )

  const onClearAll = (event: MouseEvent<HTMLButtonElement>) => {
    clearAll()
    plpDispatch({
      type: 'SET_MULTIPLE',
      payload: {
        shouldLoadUngrouped: true,
      },
    })
    event?.preventDefault()
    SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
    setShouldUpdateDiscountFilters(true)
  }

  const handleRemoveFacet = (refinement: Refinement) => {
    refinement.refine(refinement)
    plpDispatch({
      type: 'SET_INTERNAL_APPLIED_FILTERS',
      payload: {
        _internalAppliedFacets: currentRefinements,
        shouldLoadUngrouped: true,
      },
    })
    SizeAdvisorUtil.setSizeAdvisorPlpToggleStatus(false)
    setShouldUpdateDiscountFilters(true)
  }

  useEffect(() => {
    if (!filtersBarRef?.current) return
    const observer = new IntersectionObserver(([e]) => setFiltersBarStuck(e.intersectionRatio < 1), { threshold: 1 })
    observer.observe(filtersBarRef.current)

    return () => {
      if (filtersBarRef?.current) {
        observer.unobserve(filtersBarRef.current)
      }
    }
  }, [filtersBarRef])

  const customerSegment = useCustomerSegmentsUtil()[0]
  const { generateParameters } = useProductParams()

  const parameters = generateParameters(customerSegment)

  const { items, refine: applyDiscountFilter } = useMenu({
    attribute: `sort.discount.${customerSegment}`,
    transformItems,
  })

  const [innerItems, setInnerItems] = useState(items)
  const [shouldUpdateDiscountFilters, setShouldUpdateDiscountFilters] = useState(true)

  const updateInnerItems = useCallback(
    (items: MenuItem[]): void => {
      if (plpState.firstLoad || plpState.shouldLoadUngrouped || shouldUpdateDiscountFilters) {
        setInnerItems(items)
        setShouldUpdateDiscountFilters(false)
      }
    },
    [plpState.firstLoad, plpState.shouldLoadUngrouped, shouldUpdateDiscountFilters]
  )

  useEffect(() => {
    updateInnerItems(items)
  }, [items, updateInnerItems])

  const { refine: clearSortDiscount } = useClearRefinements({
    includedAttributes: [`sort.discount.${customerSegment}`],
  })

  const { refine: clearAll } = useClearRefinements({
    excludedAttributes: [`sort.discount.${customerSegment}`, 'sortBy', 'query'],
  })

  const removeDiscountFilter = useCallback(() => {
    setShouldUpdateDiscountFilters(true)
    clearSortDiscount()
  }, [clearSortDiscount])

  return (
    <StyledPLPHeaderWrapper ref={plpHeaderWrapperRef}>
      <StyledPlpHeaderWrapperMobile>
        <StyledPLPHeaderTitleWrapper
          isSticky={isSticky}
          isScrolled={!!scrolled}
          width={plpHeaderWrapperRef.current?.clientWidth}
          withFilters={appliedFiltersNumberForSearch > 0}
          ref={plpMobileHeaderRef}
        >
          {isPlacementsBetween && (
            <PlpHeaderTitle
              isSticky={isSticky}
              catalogLoading={catalogLoading}
              text={
                searchTerm
                  ? t('ProductGrid.Labels.searchFor', {
                      searchTerm: searchTerm.replace('*', ''),
                    })
                  : title
              }
            />
          )}
          {plpState.showOffersFilters && !isSticky && innerItems.length > 0 && (
            <>
              <Divider />
              <StyledPLPOffersFiltersWrapper>
                <SquaredFilterPillWrapper>
                  <SquaredFilterPill
                    id="all"
                    key="all"
                    selected={!sortDiscountAllSelected}
                    labelText="ALL"
                    onClick={removeDiscountFilter}
                  />
                </SquaredFilterPillWrapper>
                {innerItems.map(item => (
                  <SquaredFilterPillWrapper key={item.value}>
                    <SquaredFilterPill
                      id={item.value.toString()}
                      key={item.value.toString()}
                      labelText={item.label}
                      selected={item.isRefined}
                      onClick={() => {
                        applyDiscountFilter(item.value)
                        setShouldUpdateDiscountFilters(true)
                      }}
                    />
                  </SquaredFilterPillWrapper>
                ))}
              </StyledPLPOffersFiltersWrapper>
            </>
          )}
          <PLPHeaderFilterAlgolia
            ref={plpMobileHeaderRef}
            appliedFiltersNumber={
              plpState.showOffersFilters && currentRefinements.hasOwnProperty(`sort.discount.${customerSegment}`)
                ? appliedFiltersNumberForSearch - 1
                : appliedFiltersNumberForSearch
            }
            catalogLoading
            productTotal={nbHits}
            refine={refine}
            currentRefinement={currentRefinement}
          />
          <Divider />
        </StyledPLPHeaderTitleWrapper>

        {appliedFiltersNumberForSearch > 0 && (shouldShowActiveFilters || !plpState.showOffersFilters) && (
          <>
            <StyledPLPFiltersBar isStuck={isFiltersBarStuck} ref={filtersBarRef}>
              <StyledPLPFiltersBarButtonsWrapper>
                <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
                  <FilterCTASContainer>
                    <FiltersAppliedContainer>
                      <TransitionGroup component={null}>
                        {plpState.showOffersFilters
                          ? selectedFacets
                              .filter(el => !el.attribute.startsWith('sort.discount.'))
                              .map(facet => {
                                let { label } = facet
                                label = getFacetLabel(label)

                                return (
                                  <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                    <div>
                                      <Pill
                                        id={facet.value.toString()}
                                        key={facet.value.toString()}
                                        labelText={label + 'top'}
                                        deleteIcon={deleteIcon}
                                        onDelete={() => {
                                          handleRemoveFacet(facet)
                                        }}
                                      />
                                    </div>
                                  </StyledFade>
                                )
                              })
                          : selectedFacets.map(facet => {
                              let { label } = facet
                              label = getFacetLabel(label)
                              if (facet.attribute.startsWith('sort.discount.')) {
                                const priceArray = facet.label.split('.')
                                label = `${+priceArray[1] === 0 ? priceArray[0] : label}% OFF`
                              }
                              return (
                                <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                  <div>
                                    <Pill
                                      id={facet.value.toString()}
                                      key={facet.value.toString()}
                                      labelText={label}
                                      deleteIcon={deleteIcon}
                                      onDelete={() => handleRemoveFacet(facet)}
                                    />
                                  </div>
                                </StyledFade>
                              )
                            })}
                      </TransitionGroup>
                      {appliedFiltersNumberForSearch > 0 &&
                        (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                          <ClearAllContainer>
                            <ClearAllLink
                              onClick={event => {
                                onClearAll(event)
                              }}
                            >
                              {t('ProductGrid.Actions.clearAll')}{' '}
                            </ClearAllLink>
                          </ClearAllContainer>
                        )}
                    </FiltersAppliedContainer>
                  </FilterCTASContainer>
                </FilterCTASWrapper>
              </StyledPLPFiltersBarButtonsWrapper>
            </StyledPLPFiltersBar>
          </>
        )}
        {((isSticky && isDesktop) || !isSticky) && (
          <StyledResultsFor variant="body1">
            {nbHits} {t('ProductGrid.Labels.results')}
          </StyledResultsFor>
        )}
      </StyledPlpHeaderWrapperMobile>

      <StyledPlpHeaderWrapperDesktop>
        <StyledPLPHeaderTitleWrapper
          isSticky={isSticky}
          isScrolled={!!scrolled}
          width={plpHeaderWrapperRef.current?.clientWidth}
          ref={plpDesktopHeaderRef}
        >
          <PlpHeaderTitle
            isSticky={isSticky}
            text={
              searchTerm
                ? t('ProductGrid.Labels.searchFor', {
                    searchTerm: searchTerm.replace('*', ''),
                  })
                : title
            }
          />

          <StyledFilterResultWrapper isSticky={isSticky}>
            {((!isSearchTerm &&
              nbHits > 0 &&
              (appliedFiltersNumberForSearch === 0 || (!shouldShowActiveFilters && plpState.showOffersFilters))) ||
              isSticky) &&
              productData?.hits.length > 0 && (
                <StyledFilterWrapper>
                  <PLPHeaderFilterAlgolia
                    catalogLoading={catalogLoading}
                    appliedFiltersNumber={
                      plpState.showOffersFilters && !shouldShowActiveFilters
                        ? appliedFiltersNumberForSearch - 1
                        : appliedFiltersNumberForSearch
                    }
                    productTotal={nbHits}
                    refine={refine}
                    currentRefinement={currentRefinement}
                  />
                </StyledFilterWrapper>
              )}

            {isSticky && (
              <StyledScrollToTopButton aria-label="ScrollToTop" onClick={() => scrollTo(0, 0)}>
                <ArrowUpIcon htmlColor={theme.palette.neutral.tones[60]} />
              </StyledScrollToTopButton>
            )}
          </StyledFilterResultWrapper>
          {!isSticky && appliedFiltersNumberForSearch === 0 && !isSearchTerm && <Divider />}
        </StyledPLPHeaderTitleWrapper>

        {plpState.showOffersFilters && innerItems.length > 0 && (
          <StyledPLPOffersFiltersWrapper>
            <SquaredFilterPillWrapper>
              <SquaredFilterPill
                id="all"
                key="all"
                selected={!sortDiscountAllSelected}
                labelText="ALL"
                onClick={removeDiscountFilter}
              />
            </SquaredFilterPillWrapper>
            {innerItems.map(item => (
              <SquaredFilterPillWrapper key={item.value}>
                <SquaredFilterPill
                  id={item.value.toString()}
                  key={item.value.toString()}
                  labelText={item.label}
                  selected={item.isRefined}
                  onClick={() => {
                    applyDiscountFilter(item.value)
                    setShouldUpdateDiscountFilters(true)
                  }}
                />
              </SquaredFilterPillWrapper>
            ))}
          </StyledPLPOffersFiltersWrapper>
        )}

        <div id="filterbar" />
        <ClientOnlyPortal selector={'#filterbar'}>
          <StyledPLPFiltersBar isStuck={isFiltersBarStuck} ref={filtersBarRef}>
            {(searchTerm !== '' || appliedFiltersNumberForSearch > 0) &&
              (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                <StyledPLPFiltersBarButtonsWrapper>
                  <FilterCTASWrapper activeFilter={appliedFiltersNumberForSearch > 0}>
                    <FilterCTASContainer>
                      {appliedFiltersNumberForSearch > 0 && (
                        <FiltersAppliedContainer>
                          <TransitionGroup component={null}>
                            {plpState.showOffersFilters
                              ? plpState.appliedFacets
                                  .filter(el => !el.attribute.startsWith('sort.discount.'))
                                  .map(refinement => {
                                    let { label } = refinement
                                    label = getFacetLabel(label)
                                    return (
                                      <StyledFade key={refinement.value} timeout={{ exit: 500 }}>
                                        <div>
                                          <Pill
                                            id={refinement.value.toString()}
                                            key={refinement.value.toString()}
                                            labelText={label}
                                            deleteIcon={deleteIcon}
                                            onDelete={() => {
                                              handleRemoveFacet(refinement)
                                            }}
                                          />
                                        </div>
                                      </StyledFade>
                                    )
                                  })
                              : selectedFacets.map(facet => {
                                  let { label } = facet
                                  label = getFacetLabel(label)
                                  if (facet.attribute.startsWith('sort.discount.')) {
                                    const priceArray = facet.label.split('.')
                                    label = `${+priceArray[1] === 0 ? priceArray[0] : label}% OFF`
                                  }
                                  return (
                                    <StyledFade key={facet.value} timeout={{ exit: 500 }}>
                                      <div>
                                        <Pill
                                          id={facet.value.toString()}
                                          key={facet.value.toString()}
                                          labelText={label}
                                          deleteIcon={deleteIcon}
                                          onDelete={() => {
                                            handleRemoveFacet(facet)
                                          }}
                                        />
                                      </div>
                                    </StyledFade>
                                  )
                                })}
                          </TransitionGroup>
                          {appliedFiltersNumberForSearch > 0 &&
                            (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                              <ClearAllContainer>
                                <ClearAllLink onClick={event => onClearAll(event)}>
                                  {t('ProductGrid.Actions.clearAll')}{' '}
                                </ClearAllLink>
                              </ClearAllContainer>
                            )}
                        </FiltersAppliedContainer>
                      )}
                      {(isSearchTerm || appliedFiltersNumberForSearch > 0) &&
                        (shouldShowActiveFilters || !plpState.showOffersFilters) && (
                          <PLPHeaderFilterAlgolia
                            appliedFiltersNumber={
                              plpState.showOffersFilters && shouldShowActiveFilters
                                ? appliedFiltersNumberForSearch - 1
                                : appliedFiltersNumberForSearch
                            }
                            catalogLoading={catalogLoading}
                            refine={refine}
                            currentRefinement={currentRefinement}
                          />
                        )}
                    </FilterCTASContainer>
                    {!isSticky && <Divider />}
                  </FilterCTASWrapper>
                </StyledPLPFiltersBarButtonsWrapper>
              )}
          </StyledPLPFiltersBar>
        </ClientOnlyPortal>
        {((isSticky && isDesktop) || !isSticky) && (
          <StyledResultsFor variant="body1">
            {nbHits} {t('ProductGrid.Labels.results')}
          </StyledResultsFor>
        )}
      </StyledPlpHeaderWrapperDesktop>
    </StyledPLPHeaderWrapper>
  )
}

export default PlpHeaderAlgolia
