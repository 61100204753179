import { useCallback, useEffect, useState } from 'react'
import { APP_MAIN_CONTAINER_ID } from '../../constants/ui'

const useStickyScroll = (stickyElementRef, containerRef) => {
  const [isSticky, setIsSticky] = useState(false)

  const toggleSticky = useCallback(
    (stickyRect, containerRect) => {
      setIsSticky(
        containerRect.top - stickyRect.height <= 0 && (stickyRect.top <= 0 || stickyRect.bottom !== stickyRect.height)
      )
    },
    [isSticky]
  )

  const handleScroll = () => {
    if (!stickyElementRef.current || !containerRef.current) {
      return
    }

    toggleSticky(stickyElementRef.current.getBoundingClientRect(), containerRef.current.getBoundingClientRect())
  }

  useEffect(() => {
    const appContainer = document.getElementById(APP_MAIN_CONTAINER_ID)

    appContainer?.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('wheel', handleScroll)
  }, [])

  return isSticky
}

export default useStickyScroll
