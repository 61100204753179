import { IconButton } from '@components/UI/IconButton'
import { HeartFilledIcon, HeartIcon } from '@components/UI/Icons'
import { StyledLoader } from '@components/UI/Loader'
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/material/styles/useTheme'
import { IProduct } from '@typesApp/product'
import { useCallback } from 'react'
import { useWishlist } from '../hooks/useWishlist'

interface IProductDetailsList {
  currentProduct: IProduct
}

export const WishlistIcon = styled(IconButton, {
  name: 'Wishlist',
  slot: 'IconContainer',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
}))

export const WishListButton = ({ currentProduct }: IProductDetailsList) => {
  const theme = useTheme()

  const { isWishlistLoading, itemIdWishList, determineWishListAction } = useWishlist(currentProduct)

  const toggleWishlist = useCallback(() => {
    determineWishListAction()
  }, [determineWishListAction])

  if (isWishlistLoading) {
    return <StyledLoader size={16} />
  }

  return (
    <WishlistIcon aria-label="Add to wishlist" data-element-id="X_X_Prod_AddToWishList" onClick={toggleWishlist}>
      {itemIdWishList ? (
        <HeartFilledIcon
          aria-label="Heart filled icon"
          htmlColor={theme.palette.background.dark.primary}
          fill={theme.palette.background.dark.quaternary}
        />
      ) : (
        <HeartIcon aria-label="Heart icon" htmlColor={theme.palette.background.dark.primary} />
      )}
    </WishlistIcon>
  )
}
