import {
  styled,
  Pagination as MuiPagination,
  paginationClasses as MuiPaginationClasses,
  paginationItemClasses as MuiPaginationItemClasses,
} from '@mui/material'

export const StyledPagination = styled(MuiPagination, {
  name: 'Pagination',
})(({ theme }) => ({
  [`& .${MuiPaginationClasses.ul} > * + *`]: {
    marginLeft: theme.spacing(4),
  },

  [`& .${MuiPaginationItemClasses.root}`]: {
    height: 24,
    minWidth: 24,
    padding: theme.spacing(0),
    backgroundColor: 'inherit',

    '&:hover': {
      background: theme.palette.success.tones[99],
    },
    [`&.${MuiPaginationItemClasses.selected}`]: {
      backgroundColor: theme.palette.success.main,
    },
  },
  [`& .${MuiPaginationItemClasses.text}`]: {
    color: theme.palette.neutral.light,
    ...theme.typography.caption,
  },
  [`& .${MuiPaginationItemClasses.selected}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },

    [theme.breakpoints.down('md')]: {
      height: 20,
      minWidth: 20,
    },
  },
}))
