import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import { CameraIcon } from '@components/UI/Icons/Icons'
import VirtualMirror from '@components/VirtualMirror/VirtualMirror'
import { IProduct } from '@typesApp/product'
import { getModelName } from '@utils/productAttributesAlgolia'
import { useCallback, useState } from 'react'
import { StyledContainer, StyledSwitcherContainer, VirtualMirrorPill } from '../../ProductDetails.style'

type VirtualMirrorCTAProps = {
  labelText: string
  currentProduct: IProduct
  pdpData: IProduct
}

const VirtualMirrorCTA: React.FC<VirtualMirrorCTAProps> = ({ labelText, currentProduct, pdpData }) => {
  const [isVMMVOpen, setIsVMMVOpen] = useState(false)

  const handleVMMVClickOpen = useCallback(() => {
    setIsVMMVOpen(true)
  }, [])

  const handleVMMVClickClose = useCallback(() => {
    setIsVMMVOpen(false)
  }, [])

  return (
    <StyledContainer>
      <StyledSwitcherContainer>
        <VirtualMirrorPill
          key="vmSwitch"
          labelText={labelText}
          icon={<CameraIcon width={20} height={20} />}
          onClick={handleVMMVClickOpen}
          id="vmSwitch"
          data-element-id="X_X_Prod_VMStart"
          data-description={`${getModelName(pdpData)}_${pdpData.partNumber}`}
        />
      </StyledSwitcherContainer>
      {isVMMVOpen && (
        <ClientOnlyPortal selector="#vmPortal">
          <VirtualMirror currentProduct={currentProduct} onClose={handleVMMVClickClose} cluster={pdpData?.cluster} />
        </ClientOnlyPortal>
      )}
    </StyledContainer>
  )
}

export default VirtualMirrorCTA
