import { Button } from '@components/UI/Button'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

export const StyledSidebar = styled('div', {
  name: 'Sidebar',
})(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(8),
}))

export const StyledHeading = styled(Typography, {
  name: 'SidebarHeading',
})(({ theme }) => ({
  marginBottom: theme.spacing(12),
  fontWeight: 'bold',
}))

export const StyledButtonWrapper = styled('li', {
  name: 'SidebarButtonWrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: 'fit-content',
}))

export const StyledButton = styled(Button, {
  name: 'SidebarButtonWrapper',
})(({ theme }) => ({
  width: 'fit-content',
}))
