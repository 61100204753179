import { styled } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import SvgTick3 from './icon'

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingBottom: theme.spacing(1),
}))

export const StyledIconTick3 = styled(SvgTick3)(({ theme }) => ({
  color: theme.palette.success.main,
  marginTop: theme.spacing(0.75),
  alignSelf: 'start',
}))

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

export const StyledLink = styled('a', {
  name: 'TopReasons',
  slot: 'Link',
})(({ theme }) => ({
  font: 'inherit',
  color: theme.palette.primary.main,

  '&:visited': {
    color: theme.palette.primary.main,
  },
}))

export const StyledTopReasonsTeasableItem = styled('div', {
  name: 'TopReasonsTeasableItem',
})(({ theme }) => ({
  ...theme.typography.body2,

  paddingLeft: theme.spacing(2),
  margin: theme.spacing(2, 0),

  a: {
    font: 'inherit',
    color: theme.palette.primary.main,

    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}))
