import { SEARCH_RESULTS_LIMIT } from '@configs/catalog'
import { INDICES_MAP } from '@features/plp/algoliaUtils'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useMemo } from 'react'
import { TAlgoliaInit, TAlgoliaInitChildren } from '../types'
import { AlgoliaInit } from './Init'

export const AlgoliaSearchHeaderInit: React.FC<TAlgoliaInitChildren> = ({ children }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const props: TAlgoliaInit = useMemo(
    () => ({
      checkForEmptyQuery: true,
      initIndexNameParams: { locale: langCountry, sortOption: INDICES_MAP.BEST_SELLERS },
      configure: {
        hitsPerPage: SEARCH_RESULTS_LIMIT,
        attributesToRetrieve: ['x_groupkey'],
        clickAnalytics: true,
      },
    }),
    [langCountry]
  )

  return <AlgoliaInit {...props}>{children}</AlgoliaInit>
}
