import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const FrameAdvisorIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="m16.4 11.6c0-1.8 0.5-3.5 1.4-5q0.4-0.5 0.8-1 0.4-0.5 0.9-0.9 0.5-0.5 1-0.8 0.6-0.3 1.2-0.6 0.6-0.2 1.2-0.4 0.6-0.2 1.2-0.2 0.7-0.1 1.3-0.1 0.6 0.1 1.2 0.2 0.7 0.1 1.3 0.4 0.6 0.2 1.1 0.5 0.6 0.3 1.1 0.7 0.5 0.4 1 0.8 0.4 0.5 0.8 1.1 0.4 0.5 0.7 1.1 0.3 0.6 0.5 1.2 0.2 0.6 0.3 1.2 0.1 0.7 0.2 1.3 0 0.7-0.1 1.4 0 0.6-0.2 1.3-0.1 0.6-0.4 1.2-0.2 0.6-0.5 1.2-0.4 0.6-0.8 1.1-0.4 0.5-0.8 0.9-0.5 0.5-1.1 0.8-0.5 0.4-1 0.7-0.6 0.3-1.2 0.5-0.6 0.2-1.3 0.3-0.6 0-1.2 0.1c-2.3 0-4.5-1-6.1-2.7-1.6-1.7-2.5-3.9-2.5-6.3zm-14.6 39.8h14.5v-0.4h-14.6v0.2q0 0.1 0.1 0.2zm15.2-3.7l-13.7-5.2q-0.2 0.4-0.3 0.8l13.7 5.2q0.1-0.4 0.3-0.8zm-0.5 1.6l-14.4-2.7q0 0.3-0.1 0.6l14.4 2.7q0.1-0.3 0.1-0.6c0 0 0 0 0 0zm1.3-3.1l-12.6-7.6q-0.3 0.5-0.6 1l12.7 7.6q0.2-0.5 0.5-1zm1-1.3q0 0 0 0zm-11.2-9.8q-0.1 0.1-0.2 0.3-0.1 0.1-0.2 0.2-0.1 0.2-0.3 0.3-0.1 0.1-0.2 0.3l11.2 9.8q0.4-0.6 0.9-1.1zm26 16.3h14.6v-0.2c0-6.4-2.4-12.4-6.6-17l-0.2-0.1q-0.3-0.4-0.7-0.7-0.3-0.3-0.7-0.7-0.3-0.3-0.7-0.6-0.4-0.3-0.8-0.6l-2 2.5 1.5-2.8q-0.3-0.3-0.7-0.5-0.3-0.2-0.7-0.4-0.3-0.3-0.7-0.5-0.4-0.2-0.8-0.4l-2.3 4.2 1.6-4.5q-0.4-0.2-0.7-0.3-0.4-0.2-0.7-0.3-0.4-0.2-0.8-0.3-0.4-0.1-0.7-0.2l-2.1 5.9 1-6.2q-0.3-0.1-0.7-0.2-0.3-0.1-0.7-0.1-0.3-0.1-0.7-0.1-0.3-0.1-0.7-0.1l-1.3 7.7v-7.9q-0.1 0-0.3 0-0.2 0-0.3 0-0.2 0-0.4 0-0.1 0-0.3 0-0.6 0-1.3 0v9.4l-1.6-9.2q-1.1 0.1-2.3 0.4l1.8 10.7-3.5-10.2q-1 0.3-2 0.8l4.1 11.6-6-10.7q-0.8 0.4-1.6 0.9l6.7 12.1-8.6-10.7q-0.6 0.6-1.2 1.1l9.4 11.7q0.3-0.3 0.6-0.6 0.3-0.3 0.7-0.6 0.3-0.2 0.7-0.4 0.4-0.2 0.8-0.4 0.4-0.2 0.8-0.3 0.4-0.1 0.8-0.2 0.4-0.1 0.8-0.1 0.5-0.1 0.9-0.1c1.1 0 2.3 0.3 3.3 0.7q0.4 0.2 0.8 0.4 0.4 0.2 0.7 0.5 0.4 0.2 0.7 0.5 0.3 0.3 0.6 0.6h0.1l0.1 0.2 0.2 0.2 0.1 0.1 0.1 0.2q0.5 0.6 0.9 1.3 0.3 0.7 0.6 1.5 0.2 0.7 0.3 1.4 0.1 0.8 0.1 1.6 0 0 0 0z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FrameAdvisorFrameSizeIcon: React.FC<CustomSVGIconProps> = ({
  width = 16,
  height = 16,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M5.26 3.043c0-.565.16-1.118.46-1.588.302-.47.73-.836 1.23-1.053A2.63 2.63 0 0 1 8.53.24c.531.11 1.019.383 1.402.783s.643.909.749 1.463c.105.555.051 1.13-.156 1.652A2.836 2.836 0 0 1 9.518 5.42c-.45.314-.98.481-1.521.481a2.68 2.68 0 0 1-1.936-.837 2.924 2.924 0 0 1-.801-2.021zM.616 15.731h4.642v-.13H.616v.064c0 .022-.002.044 0 .066zm4.855-1.175-4.366-1.658c-.031.088-.062.17-.091.263l4.364 1.66c.021-.092.059-.18.093-.265zm-.163.509-4.574-.848a6.74 6.74 0 0 0-.032.192l4.574.848c.016-.056.027-.12.04-.185l-.008-.007zm.416-.98L1.7 11.66a7.344 7.344 0 0 0-.176.315L5.55 14.4c.05-.11.108-.215.174-.315zm.325-.419-3.572-3.124c-.095.11-.187.225-.275.339L5.762 14c.086-.122.182-.235.287-.34v.005zm4.692 2.07h4.643v-.066c.002-2.023-.76-3.966-2.12-5.406l-.04-.044c-.289-.3-.6-.576-.93-.823l-.65.807.505-.912a7.218 7.218 0 0 0-.927-.561l-.738 1.333.505-1.445a7.126 7.126 0 0 0-.932-.354l-.662 1.899.336-1.989a7.098 7.098 0 0 0-.896-.17l-.416 2.464v-2.5a6.761 6.761 0 0 0-.416-.013c-.14 0-.277 0-.415.014v2.982l-.495-2.936a7.104 7.104 0 0 0-.74.134l.577 3.414-1.136-3.257c-.215.071-.424.151-.63.24l1.299 3.71-1.89-3.417a7.492 7.492 0 0 0-.51.308l2.12 3.835-2.732-3.39c-.133.11-.263.223-.39.338l2.988 3.724c.255-.269.559-.482.894-.627a2.64 2.64 0 0 1 2.11.005c.335.146.638.36.892.63l.012.012.04.046.01.01.044.05.044.057.047.06c.396.52.606 1.169.592 1.833l.007.005z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const FittingSizeAdvisorIcon: React.FC<CustomSVGIconProps> = ({ fill = '#000', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M23 2v1H1V2H0v3h1V4h22v1h1V2h-1zM20.594 7.144a16.772 16.772 0 0 0-2.094-.14V7h-13v.004c-.726.003-1.446.055-2.103.142C1.83 7.383.667 8.519.21 10.262-.38 12.51.33 15.105 1.86 16.3c.594.464 1.313.699 2.098.699.468 0 .959-.084 1.462-.253 2.297-.77 4.487-3.169 5.37-5.826a2.05 2.05 0 0 1 2.422 0c.876 2.65 3.07 5.054 5.37 5.826.503.169.994.253 1.462.253.785 0 1.504-.236 2.098-.699 1.53-1.194 2.238-3.789 1.649-6.037-.457-1.743-1.619-2.879-3.196-3.118zm-10.626 3.04c-.677 2.533-2.723 4.895-4.867 5.614-.72.242-1.76.388-2.626-.287-1.213-.947-1.783-3.141-1.297-4.995.207-.787.789-2.143 2.36-2.38a15.787 15.787 0 0 1 1.963-.137V8h.225c1.858.02 3.373.408 3.984 1.083.28.31.36.664.258 1.101zm1.03-.507c-.035-.47-.217-.9-.547-1.265a2.796 2.796 0 0 0-.481-.411h4.06a2.814 2.814 0 0 0-.481.411 2.08 2.08 0 0 0-.546 1.266A2.98 2.98 0 0 0 12 9.5c-.345 0-.682.064-1.002.177zm10.528 5.834c-.866.675-1.906.529-2.626.287-2.144-.72-4.19-3.08-4.864-5.6-.106-.451-.025-.806.255-1.115.614-.678 2.14-1.066 4.004-1.083h.205c.624.003 1.28.045 1.954.134 1.58.24 2.162 1.595 2.369 2.382.486 1.854-.084 4.047-1.297 4.995z"
        fill={fill}
      />
    </svg>
  )
}
