import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { IconButton } from '@components/UI/IconButton'
import { IconLoaderWrapper } from '@components/UI/IconButton/IconButton'
import { StyledLoader } from '@components/UI/Loader'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import config from '@configs/index'
import { PRODUCT_TYPES_KEYS, PRODUCT_TYPES_MAP } from '@constants/product'
import { NOT_FOUND } from '@constants/routes'
import {
  getAlgoliaEventParameters,
  storeAlgoliaEventParameters,
  transformAttachmentsToImage,
} from '@features/plp/algoliaUtils'
import { setMocoOrderedIds } from '@features/product/slice'
import { setLastSelectedProduct } from '@features/ui/action'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { wishlistEnabledSelector } from '@features/wishList/selector'
import { algoliaEventTrigger } from '@foundation/algolia/algoliaConfig'
import { getUserToken } from '@foundation/hooks/useAnalyticsData'
import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import ProductPriceNew from '@pages_views/ProductDetails/components/ProductPriceNew'
import { useWishlist } from '@pages_views/ProductDetails/hooks/useWishlist'
import { plpBadgesSelector } from '@redux/selectors/site'
import CurrencyService from '@services/CurrencyService'
import { IAlgoliaHit } from '@typesApp/product'
import {
  getAnnualSupplyBadge,
  getBadges,
  getBrand,
  getIsRoxable,
  getModelCodeDisplay,
  getModelName,
  getProductColorLabel,
  getProductType,
} from '@utils/productAttributesAlgolia'
import dynamic from 'next/dynamic'
import { ProductImageProps } from '../ProductImage/ProductImage'
import {
  PrimaryBadgeSpacePlaceholder,
  StyledImageWrapper,
  StyledProductAnchor,
  StyledProductBrandName,
  StyledProductDescriptionAnchor,
  StyledProductName,
  StyledProductSubWrapper,
  StyledProductTileAttributes,
  StyledProductTileContactLensesAnnualSupplySaveAmount,
  StyledProductTileContactLensesAnnualSupplyWrapper,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
  StyledProductTileHeader,
  StyledProductTileImageContainer,
  StyledProductTilePopularLabel,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledSquare,
} from './components/ProductTile.style'
import ProductTileGalleryAlgolia from './components/ProductTileGalleryAlgolia'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import useProductPrice from '@hooks/useProductPrice'

interface ProductTileAlgoliaProps {
  product: IAlgoliaHit
  tileIndex: number
  variant: string
  onClick?: () => void
  isClustered?: boolean
}

const siteName = config.name

const WishlistLazyIcon = dynamic(() => import('./WishlistLazyIcon'), { ssr: false })

const ProductTileAlgolia: FC<ProductTileAlgoliaProps> = ({
  product,
  tileIndex,
  variant,
  onClick,
  isClustered = true,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pageType } = usePageType()
  const { isRXEnabled } = useStoreIdentity()
  const { isMobile, isDesktop } = useBreakpoints()
  const moCoOrderedIds = product?.cluster?.map(item => item.parentProductId) ?? []
  const [sentClickedObjectIDsEvent, setSentClickedObjectIDsEvent] = useState<{
    [key: string]: boolean
  }>({})
  const [isFirstView, setIsFirstView] = useState<boolean>(true)

  const productType = (product !== null && PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()]) || 'frames'

  const isAccessories = () => productType === PRODUCT_TYPES_KEYS.ACCESSORIES
  const isContactLenses = () => productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES
  const isCLAccessories = () => productType === PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES

  const badgeSelector = useSelector(plpBadgesSelector)
  const [carouselMode, setCarouselMode] = useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<IAlgoliaHit>(product)
  const [clusters, setClusters] = useState<IAlgoliaHit[] | undefined>(product?.cluster || [])
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const { primaryBadge, secondaryBadges } = getBadges(product, t, badgeSelector, isRXEnabled)
  const name = getModelName(product, true)
  const brand = getBrand(product)
  const annualSupplyBadges = getAnnualSupplyBadge(product) || 100
  const modelCode = getModelCodeDisplay(product)

  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)

  const mobileListingLayoutBasedOnPages = pageType === 'search' ? 'compact' : productsMobileListingLayout

  const selectedViewCluster = useMemo(
    () => (clusters && clusters.length > 0 ? clusters[selectedClusterIndex] : product),
    [clusters, product, selectedClusterIndex]
  )
  const { isWishlistLoading, determineWishListAction } = useWishlist(selectedViewCluster)

  const isRoxable = useMemo(() => selectedViewCluster && getIsRoxable(selectedViewCluster), [selectedViewCluster])

  const currency = CurrencyService.getSymbolByName(selectedViewCluster?.price?.currency as string)

  const isMoCoSliderRenderedForAccessories = isAccessories() && clusters && clusters?.length > 1
  const isMoCoSliderRendered =
    (clusters && !isAccessories() && !isContactLenses() && !isCLAccessories()) || isMoCoSliderRenderedForAccessories
  const isMoCoSliderDisplayed = isMoCoSliderRendered && (carouselMode || mobileListingLayoutBasedOnPages === 'full')

  const isFullListeningLayout = productsMobileListingLayout === 'full'
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)

  const { currentPrice: price } = useProductPrice(selectedViewCluster.prices)

  const tileDataElementId = useMemo(() => {
    switch (pageType) {
      case 'search':
        return `X_X_SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }, [pageType])

  const onProductTileClick = () => {
    storeAlgoliaEventParameters({
      type: 'ungrouped',
      position: tileIndex + 1,
      objectID: selectedViewCluster.objectID,
    })
    storeAlgoliaEventParameters({
      type: 'grouped',
      position: tileIndex + 1,
    })
    dispatch(setMocoOrderedIds(moCoOrderedIds))
  }

  useEffect(() => {
    if (product) {
      setCurrentProduct(product)
      setClusters(product.cluster)
    }
  }, [product, selectedViewCluster])

  useEffect(() => {
    if (
      (selectedClusterIndex !== 0 || (selectedClusterIndex === 0 && !isFirstView)) &&
      !Object.keys(sentClickedObjectIDsEvent).includes(selectedViewCluster.objectID)
    ) {
      algoliaEventTrigger('sendEvents', [
        {
          eventType: 'view',
          userToken: window.utag_data.User_Email_MD5 || getUserToken(),
          index: getAlgoliaEventParameters(['indexName'], 'ungrouped').indexName!,
          eventName: 'main_title_result_page_changed',
          objectIDs: [selectedViewCluster.objectID],
          queryID: getAlgoliaEventParameters(['queryID'], 'ungrouped').queryID!,
        },
      ])
      setSentClickedObjectIDsEvent({
        ...sentClickedObjectIDsEvent,
        [selectedViewCluster.objectID]: true,
      })
    }
  }, [selectedViewCluster, selectedClusterIndex])

  useEffect(() => {
    if (selectedClusterIndex !== 0 && isFirstView) {
      setIsFirstView(false)
    }
  }, [selectedClusterIndex, isFirstView])

  const onProductTileMouseEnter = () => {
    if (clusters && clusters.length > 0 && (carouselMode || mobileListingLayoutBasedOnPages === 'full' || isDesktop)) {
      setCarouselMode(true)
    }
  }

  const onProductTileMouseLeave = () => {
    if (carouselMode) {
      setCarouselMode(false)
    }
  }

  const onImageClick = () => {
    dispatch(setLastSelectedProduct(product.productId || ''))
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: productType === 'accessories' ? '1' : '2',
    usage: 'PLP',
    lazy: tileIndex > 4,
  }

  const getMocoLinkTo = product => product.url || NOT_FOUND

  const clusterViewLength = clusters?.length ?? 1

  const handleWishlistAction = () => {
    determineWishListAction()
  }

  const renderWishlist =
    isWishlistEnabled &&
    productType !== 'contact-lenses' &&
    !isMobile &&
    (isWishlistLoading ? (
      <IconLoaderWrapper>
        <StyledLoader size={16} />
      </IconLoaderWrapper>
    ) : (
      <IconButton aria-label="Wishlist" data-element-id={'X_X_WishList_Remove'} onClick={handleWishlistAction}>
        <WishlistLazyIcon selectedViewCluster={selectedViewCluster} />
      </IconButton>
    ))

  return (
    product && (
      <StyledProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        aria-label={primaryBadge || `${name}_${modelCode}`}
        data-element-id={tileDataElementId}
        data-description={`${siteName}_${name}_${modelCode}`}
        className={`${variant === 'suggested' ? 'ProductTile-ymal' : 'ProductTile'}`}
        onClick={onProductTileClick}
      >
        <StyledProductSubWrapper fullLayout={carouselMode || mobileListingLayoutBasedOnPages === 'full'}>
          <StyledSquare onClick={onClick}>
            <>
              <StyledProductTileHeader className="product-tile__header">
                {productType === 'contact-lenses' && annualSupplyBadges ? (
                  <StyledProductTileContactLensesAnnualSupplyWrapper isFull={isFullListeningLayout}>
                    <StyledProductTileContactLensesAnnualSupplySaveAmount>
                      {t('ProductTile.Labels.SaveAmountLine1', {
                        currency,
                        amount: annualSupplyBadges,
                      })}
                    </StyledProductTileContactLensesAnnualSupplySaveAmount>
                    {t('ProductTile.Labels.SaveAmountLine2')}
                  </StyledProductTileContactLensesAnnualSupplyWrapper>
                ) : (
                  <StyledProductTilePopularLabel>
                    {primaryBadge ? primaryBadge : <PrimaryBadgeSpacePlaceholder />}
                  </StyledProductTilePopularLabel>
                )}
                <StyledProductTileAttributes>{secondaryBadges}</StyledProductTileAttributes>
              </StyledProductTileHeader>
              {isMoCoSliderRendered && clusters && isMoCoSliderDisplayed && (
                <ProductTileGalleryAlgolia
                  isDisplayed={isMoCoSliderDisplayed}
                  mocoLink={getMocoLinkTo(selectedViewCluster)}
                  tileDataElementId={tileDataElementId}
                  priorityBadgeText={primaryBadge}
                  commonImageProps={commonGalleryProductImageProps}
                  currentProductIndex={selectedClusterIndex}
                  onIndexChange={setSelectedClusterIndex}
                  cluster={isClustered ? clusters : [product]}
                />
              )}

              <>
                <StyledProductTileImageContainer
                  isFull={isFullListeningLayout}
                  className={`product-tile-image ${variant === 'cms-products-module' ? 'product-tile__hp' : ''}`}
                  isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
                  isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
                >
                  <StyledProductAnchor
                    aria-label={primaryBadge || `${name}_${modelCode}`}
                    href={getMocoLinkTo(selectedViewCluster)}
                    className="product-tile__inner"
                    data-element-id={tileDataElementId}
                    data-description={`${siteName}_${name}_${modelCode}`}
                    isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
                  >
                    <StyledImageWrapper shouldZoomOnHover={isCLAccessories() || isContactLenses() || isAccessories()}>
                      <ProductMedia
                        attachments={transformAttachmentsToImage(selectedViewCluster?.attachments)}
                        alt={t('ProductTile.AltPrefix', { name })}
                        sequence="1"
                        srcSetMap={{
                          320: '320w',
                          400: '640w',
                          600: '768w',
                        }}
                        usage="PLP"
                        fetchpriority={tileIndex >= 6 ? undefined : 'high'}
                        width={600}
                        onClick={onImageClick}
                        preload={tileIndex < 3}
                        lazy={tileIndex > 4}
                      />
                    </StyledImageWrapper>
                  </StyledProductAnchor>
                </StyledProductTileImageContainer>

                <StyledProductVariants
                  className="product-tile__colors"
                  isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
                >
                  {clusterViewLength > 1
                    ? `${clusterViewLength} ${t('ProductTile.Labels.colors')?.toUpperCase()}`
                    : getProductColorLabel(currentProduct!)}
                </StyledProductVariants>
              </>
            </>
          </StyledSquare>
        </StyledProductSubWrapper>
        <StyledProductTileFooterWrapper className={`${variant === 'cms-products-module' ? 'hp-variant' : ''}`}>
          <StyledProductTileFooter className="product-tile__bottom">
            <StyledProductTileDescription isPDP={false}>
              <StyledProductDescriptionAnchor
                aria-label={primaryBadge || `${name}_${modelCode}`}
                href={getMocoLinkTo(selectedViewCluster)}
                className="product-tile__inner"
                data-element-id={tileDataElementId}
                data-description={`${siteName}_${name}_${modelCode}`}
                isClRelatedTile={isCLAccessories() || isContactLenses() || isAccessories()}
              >
                <StyledProductName type={productType}>{name ? name : '\u00A0'}</StyledProductName>
                <StyledProductBrandName>
                  {productType === 'contact-lenses' || productType === 'contact-lenses-accessories'
                    ? null
                    : brand || 'Brand name'}
                  {/* {brand || 'Brand name'} */}
                </StyledProductBrandName>
                {selectedViewCluster && selectedViewCluster.price && (
                  <ProductPriceNew
                    isCompact={mobileListingLayoutBasedOnPages !== 'full'}
                    price={price}
                    productType={parseProductTypeForPrice(productType)}
                    isRoxable={isRoxable}
                    isPDP={false}
                  />
                )}
              </StyledProductDescriptionAnchor>
            </StyledProductTileDescription>

            {renderWishlist}
          </StyledProductTileFooter>
        </StyledProductTileFooterWrapper>
      </StyledProductTileWrapper>
    )
  )
}

export { ProductTileAlgolia }
