import { useEffect } from 'react'
import { sendAnalyticEventRaw } from '@foundation/analytics/tealium/lib'

export const useTmspslotsAnalytics = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const tmspslots = document.getElementsByClassName('tmspslot')

          if (tmspslots && tmspslots.length > 0) {
            const tmspslotsList = Array.from(document.getElementsByClassName('tmspslot'))
            const loadedElement = tmspslotsList.find(element => element.getAttribute('data-state') === 'loaded')

            if (loadedElement) {
              const closeButton =
                loadedElement.querySelector('button[class*="close-button"]') || document.getElementById('FR_CloseBtn')
              const submitButton =
                loadedElement.querySelector('a[class*="submit-button"]') || loadedElement.querySelector('p.copyButton')

              closeButton?.setAttribute('data-analytics_available_call', '0')
              submitButton?.setAttribute('data-element-id', 'Prod_Upgrade')
              if (submitButton) {
                submitButton.addEventListener('click', function () {
                  const tealiumPayload = {
                    id: 'Click',
                    data_element_id: 'Prod_Upgrade',
                    data_analytics_available_call: '1',
                  }
                  sendAnalyticEventRaw(tealiumPayload)
                })
              }
              observer.disconnect()
              break
            }
          }
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])
}
