import { toggleAddToCart } from '@features/product/slice'
import { ProductRightColumnProps } from '@typesApp/product'
import { getFrontColor, getSoldOut } from '@utils/productAttributes'
import { useDispatch } from 'react-redux'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { StyledSeparator } from '../../ProductDetails.style'
import ProductColorSelector from '../../components/ProductColorSelector'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import { ProductDetailsList } from '../ProductDetailsList'
import { ProductNameSection } from '../ProductNameSection'
import ProductPriceNew from '../ProductPriceNew'
import useProductPrice from '@hooks/useProductPrice'

export const AccessoriesPdpRightColumn = ({
  partNumber,
  currentProduct,
  soldOutStatus,
  productInCart,
  addToCartButtonFail,
  ctaRef,
  placements,
  cluster,
  onClusterProductClick,
  isSoldout,
  isRoxable,
  onlyFewPieces,
}: ProductRightColumnProps) => {
  const dispatch = useDispatch()

  dispatch(toggleAddToCart(true))

  const frontColor = !!currentProduct && getFrontColor(currentProduct)
  const areAllClusterItemsSoldOut = cluster?.length
    ? cluster.every(i => getSoldOut(i) === PRODUCT_SOLDOUT_STATUS.SOLDOUT)
    : false
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)

  return (
    <>
      {currentProduct && <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} />}
      {cluster && cluster.length >= 0 && !areAllClusterItemsSoldOut && (
        <>
          <ProductColorSelector
            currentProductItem={currentProduct}
            soldOutStatus={soldOutStatus}
            cluster={cluster}
            selectedPartNumber={partNumber}
            onClick={onClusterProductClick}
          />
          {(cluster?.length >= 0 || frontColor) && <StyledSeparator />}
        </>
      )}
      {currentProduct && currentProduct.x_price && (
        <>
          <ProductPriceNew price={prices} productType={PRODUCT_TYPES_KEYS.ACCESSORIES} isPDP={true} />

          <ProductCtaContainer
            productInCart={productInCart}
            type={soldOutStatus}
            currentProduct={currentProduct}
            error={addToCartButtonFail}
            ctaRef={ctaRef}
            roxable={isRoxable}
            addToCartFillType={'fill'}
            onlyFewPieces={onlyFewPieces}
          />
        </>
      )}
      <OmniChannelCarousel placements={placements} />
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.productAttributes}
        product={currentProduct || undefined}
      />
      {currentProduct && <ProductDetailsList type={soldOutStatus} currentProduct={currentProduct} />}
    </>
  )
}
