import * as React from 'react'
import { useState } from 'react'
import {
  MultiDropDownContent,
  DropDownContainer,
  DropDownListItem,
  DropDownListItemButton,
  DropDownPopper,
  ContactLensesSelectValueField,
  DropDownIcon,
  DropDownContainerInner,
} from './ContactLensesSelect.style'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { EyeContactLensAttribute, EyeContanctLensOption } from '@typesApp/prescription'

export interface ContactLensesSelectProps {
  id: EyeContactLensAttribute
  eye: string
  onSelectValueChange?: (eye: string, id: EyeContactLensAttribute, value?: string | null) => void
  value?: string | null
  options: EyeContanctLensOption[]
  open?: boolean
  onDropDownToggle?: (open?: boolean) => void
  error?: boolean
  disabled?: boolean
  multifield?: boolean
  defaultLabel?: string | null
  listheading?: React.ReactNode
  className?: string
}

const getOptionByIndex = (index: number, options?: ContactLensesSelectProps['options']) => {
  return options?.find((_option, i) => i === index)?.value
}

const getOptionTextByValue = (value: string | null, options?: ContactLensesSelectProps['options']) => {
  return options?.find(option => option.value === value)?.text
}

export const ContactLensesSelect: React.FC<ContactLensesSelectProps> = ({
  id,
  eye,
  onSelectValueChange,
  onDropDownToggle,
  error,
  disabled,
  value,
  options,
  multifield,
  listheading,
  defaultLabel,
  className,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(0)
  const [open, setIsOpen] = useState<boolean>(false)

  const handleListKeyDown = e => {
    let selectedOptionIndex: number
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        onDropDownToggle && onDropDownToggle(false)
        break
      case 'ArrowUp':
        e.preventDefault()
        selectedOptionIndex = selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      case 'ArrowDown':
        e.preventDefault()
        selectedOptionIndex = selectedOption === options.length - 1 ? 0 : selectedOption + 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      default:
        break
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)} {...props}>
      <DropDownContainer
        data-name={eye + '_' + id}
        disabled={disabled || false}
        error={error || false}
        open={open || false}
        onClick={() => !disabled && setIsOpen(!open)}
        className={className}
      >
        <DropDownContainerInner disabled={disabled || false} error={error || false} open={open || false}>
          <ContactLensesSelectValueField
            type="button"
            aria-haspopup="listbox"
            aria-expanded={open}
            className={open ? `${className} expanded` : className}
            onKeyDown={handleListKeyDown}
          >
            {getOptionTextByValue(value || null, options) || '-'}
          </ContactLensesSelectValueField>
          <DropDownPopper open={open} className={className}>
            {listheading && listheading}
            <MultiDropDownContent
              multifield={multifield}
              role="listbox"
              aria-activedescendant={options[selectedOption]?.text}
              tabIndex={-1}
              onKeyDown={handleListKeyDown}
            >
              {options?.map(({ value, text }, index) => {
                return (
                  <DropDownListItem
                    key={`${eye}_${index}_${text}`}
                    aria-selected={selectedOption === index}
                    multifield={multifield}
                    className={`${className} ${selectedOption === index ? 'selected' : ''}`}
                  >
                    <DropDownListItemButton
                      variant="caption"
                      className={className}
                      onClick={() => {
                        if (!disabled) {
                          onSelectValueChange && onSelectValueChange(eye, id, value)
                          setSelectedOption(index)
                          setIsOpen(false)
                        }
                      }}
                    >
                      {text}
                    </DropDownListItemButton>
                  </DropDownListItem>
                )
              })}
            </MultiDropDownContent>
          </DropDownPopper>
          <DropDownIcon open={open} onClick={() => !disabled && setIsOpen(!open)} />
        </DropDownContainerInner>
      </DropDownContainer>
    </ClickAwayListener>
  )
}
