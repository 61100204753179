import React, { useEffect } from 'react'
import { DirectBillingWidgetConfig } from '../types'
import { useModuleLoad } from '../hooks/useModuleLoad'
import { useConfiguration } from '../hooks/useConfiguration'

const ROOT = 'insuranceLogoutInterceptApp'

export const LogoutInterceptListener: React.FC = () => {
  const { isReady } = useModuleLoad()
  const { session, environment, actionModule, cartBundle } = useConfiguration()

  const config = {
    cartBundle,
    session,
    environment,
    actionModule,
    selector: `#${ROOT}`,
  } as DirectBillingWidgetConfig

  useEffect(() => {
    return () => {
      document.dispatchEvent(
        new CustomEvent('db:insuranceWidgetEvent', {
          detail: { type: 'close', widgetName: 'logoutInterceptWidget' },
        })
      )
    }
  }, [])

  useEffect(() => {
    if (!isReady) return

    document.dispatchEvent(
      new CustomEvent('db:insuranceWidgetEvent', {
        detail: { type: 'render', widgetName: 'logoutInterceptWidget', config },
      })
    )
  }, [isReady, session])

  return <div id={ROOT} />
}
