import { styled } from '@mui/system'

export const StyledLinkList = styled('div', {
  name: 'LinkList',
  slot: 'Root',
})(() => ({
  display: 'flex',
  flexDirection: 'column',

  ul: {
    display: 'grid',
    gap: 6,
    margin: 0,
    padding: 0,
  },

  li: {
    listStyle: 'none',
  },
}))
