import React from 'react'
import { StyledTypography } from '@components/UI/Typography'
import { StyledSuggestedFiltersContainer } from '../PlpFilter.style'
import { useFrameGenius } from '@hooks/useFrameGenius'
import styled from '@mui/material/styles/styled'
import { useTranslation } from 'next-i18next'
import { SizeAdvisorSwitcher } from './SizeAdvisorSwitcher'

const StyledSizeAdvisorDescr = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledSizeAdvisorDescr',
})(() => ({
  display: 'flex',
  width: '100%',
}))

const StyledFrameGeniusPhoto = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledFrameGeniusPhoto',
  shouldForwardProp: (prop) => prop !== 'url',
})<{ url?: string }>(({ url, theme }) => ({
  borderRadius: '50%',
  width: '55px',
  height: '55px',
  background: url ? `url('${url}') center center / cover rgb(255, 255, 255)` : theme.palette.background.dark.primary,
  marginRight: '15px',
  flex: '0 0 55px',
}))

const StyledSizeAdvisorSwitcherContainer = styled('div', {
  name: 'PlpFilter',
  slot: 'StyledSizeAdvisorSwitcherContainer',
})(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}))

interface ISizeAdvisorSuggestion {
  toggleHingesFacet: () => void
  enabled: boolean
}

export const SizeAdvisorSuggestion: React.FC<ISizeAdvisorSuggestion> = ({ toggleHingesFacet, enabled }) => {
  const frameGeniusData = useFrameGenius()
  const { t } = useTranslation()

  return (
    <StyledSuggestedFiltersContainer>
      <StyledSizeAdvisorDescr>
        <StyledFrameGeniusPhoto url={frameGeniusData?.analysisResults?.profileImageUrl} />
        <div>
          <StyledTypography fontWeight="bold" isUppercased fontSize={14}>
            {t('ProductFilter.SizeAdvisor.title')}
          </StyledTypography>
          <StyledTypography fontSize={12}>{t('ProductFilter.SizeAdvisor.subTitle')}</StyledTypography>
        </div>
      </StyledSizeAdvisorDescr>
      <StyledSizeAdvisorSwitcherContainer>
        <StyledTypography fontSize={14}>{t('ProductFilter.SizeAdvisor.switcherLabel')}</StyledTypography>
        <SizeAdvisorSwitcher toggleHingesFacet={toggleHingesFacet} enabled={enabled} />
      </StyledSizeAdvisorSwitcherContainer>
    </StyledSuggestedFiltersContainer>
  )
}
