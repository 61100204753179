import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV2 } from '../../constants/ui'
import Link from '@components/UI/Link'

export const StyledProductSubWrapper = styled('div', {
  name: 'StyledProduct',
  slot: 'SubWrapper',
  shouldForwardProp: prop => prop !== 'isBoxWMargin',
})<{ isBoxWMargin?: boolean }>(({ isBoxWMargin, theme }) => ({
  backgroundColor: isBoxWMargin ? 'transparent' : theme.palette.background.light.secondary,
  position: 'relative',
  cursor: 'pointer',
  maxWidth: isBoxWMargin ? 416 : 'unset',
  margin: isBoxWMargin ? 'auto' : 'unset',
  '&::before': {
    content: isBoxWMargin ? 'none' : '""',
    paddingTop: '100%',
  },
}))

export const StyledProductAnchor = styled(Link, {
  name: 'ProductAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: prop => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile = true, theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(isClRelatedTile ? 8 : 0),
  fontSize: 0,

  '&::after': {
    height: '0 !important',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(6) : 'inherit',
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))

export const StyledSquare = styled('div', {
  name: 'StyledSquare',
  slot: 'Divider',
})(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const StyledProductTileWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'TileWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minHeight: '220px',
  height: '100%',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },
}))

export const StyledProductTileImageContainer = styled('div', {
  name: 'ProductTile',
  slot: 'ImageContainer',
  shouldForwardProp: prop => prop !== 'isDisplayed' && prop !== 'isClRelatedTile' && prop !== 'isFull',
})<{ isDisplayed?: boolean; isClRelatedTile?: boolean; isFull?: boolean }>(
  ({ isDisplayed = true, isClRelatedTile = true, theme, isFull }) => ({
    display: isDisplayed ? 'flex' : 'none',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    [theme.breakpoints.up('xs')]: {
      paddingTop: 0,
      alignSelf: isFull ? 'end' : 'center',
    },

    [theme.breakpoints.up('sm')]: {
      marginTop: isClRelatedTile ? theme.spacing(6) : 0,
    },
  })
)

export const StyledProductVariants = styled('div', {
  name: 'ProductTile',
  slot: 'ProductVariants',
  shouldForwardProp: prop => prop !== 'isDisplayed',
})<{ isDisplayed?: boolean }>(({ isDisplayed = true, theme }) => ({
  fontSize: 10,
  height: 'fit-content',
  display: isDisplayed ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(5),
    alignSelf: 'center',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
    marginBottom: theme.spacing(16.5), // DC-202, DC-1667
  },
}))

export const StyledNoProductVariants = styled('div', {
  name: 'ProductNoTile',
  slot: 'ProductNoVariants',
})({
  height: 21,
})

export const StyledLoadingWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'LoadingWrapper',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.background.light.secondary,
  borderRadius: 20,
}))

export const StyledSkeletonWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'SkeletonWrapper',
})({
  flex: 0,
  // minHeight: 100,
  width: '100%',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const StyledImageWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ImageWrapper',
  shouldForwardProp: prop => prop !== 'shouldZoomOnHover',
})<{ shouldZoomOnHover?: boolean }>(({ theme, shouldZoomOnHover }) => ({
  position: 'relative',
  display: 'inline-block',
  fontSize: 0,

  [theme.breakpoints.up('md')]: {
    height: '100%',
    width: '100%',
    transition: 'transform 0.5s ease-in-out',

    '&:hover, &:focus': {
      transform: shouldZoomOnHover ? 'scale(1.1)' : undefined,
    },
  },
}))
