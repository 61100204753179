import { RootReducerState } from '../../redux/reducers'

export const breadcrumbsSelector = (state: RootReducerState) => state.plp.breadcrumbs

export const plpFacetsKeysSelector = (state: RootReducerState) => {
  const plpFacet = state.site.currentSite?.xStoreCfg?.plpFacet || {}
  return Object.keys(plpFacet)
}

export const isPlpSeoBlockEnabled = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.plpSeoBlockEnabled || false
}
