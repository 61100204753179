import React, { ChangeEvent } from 'react'
import Switch from '../Switch'
import { StyledFormControlLabel } from './StyledFormControlLabel'

interface CustomSwitchProps {
  checked: boolean
  setChecked: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  label?: string
  [key: string]: any
}

// todo move out of this folder
export const StyledSwitch = React.forwardRef<HTMLInputElement, CustomSwitchProps>((props, ref) => {
  const { checked, setChecked, label, ...other } = props

  return label ? (
    <StyledFormControlLabel
      control={<Switch checked={checked} onChange={setChecked} {...other} ref={ref} />}
      label={label}
    />
  ) : (
    <Switch checked={checked} onChange={setChecked} {...other} ref={ref} />
  )
})
