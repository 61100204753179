import React from 'react'
import { useTranslation } from 'next-i18next'
import { FittingSizeAdvisorIcon } from '@components/UI/Icons/frameAdvisor'
import { SizeAdvisorButton, SizeAdvisorFilterContainer, SizeAdvisorButtonContainer } from '../PlpFilter.style'
import theme from '@themes/index'
import { useFrameGenius } from '@hooks/useFrameGenius'
import { SizeAdvisorSwitcher } from './SizeAdvisorSwitcher'

export const SizeAdvisorFilter: React.FC<{
  sizeAdvisorSwitcherProps: {
    toggleHingesFacet?: () => void
    enabled: boolean
  }
}> = ({ sizeAdvisorSwitcherProps }) => {
  const frameGeniusData = useFrameGenius()
  const { t } = useTranslation()

  if (!sizeAdvisorSwitcherProps.toggleHingesFacet) return null

  return (
    <SizeAdvisorFilterContainer>
      <SizeAdvisorButtonContainer
        onClick={() => {
          alert('open size advisor')
        }}
      >
        <FittingSizeAdvisorIcon htmlColor={theme.palette.text.dark} />
        <SizeAdvisorButton>{t('ProductFilter.SizeAdvisor.findMySize')}</SizeAdvisorButton>
      </SizeAdvisorButtonContainer>
      {frameGeniusData?.analysisResults?.size && (
        <SizeAdvisorSwitcher
          toggleHingesFacet={sizeAdvisorSwitcherProps.toggleHingesFacet}
          enabled={sizeAdvisorSwitcherProps.enabled}
        />
      )}
    </SizeAdvisorFilterContainer>
  )
}
