import {
  StyledLoadingWrapper,
  StyledProductBrandName,
  StyledProductName,
  StyledProductSubWrapper,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileWrapper,
  StyledSkeletonWrapper,
} from './ProductTile.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { StyledLoader } from '@components/UI/Loader'
import theme from '../../../themes'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import React from 'react'

export const ProductTileLoading = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StyledProductTileWrapper>
      <StyledProductSubWrapper>
        <StyledLoadingWrapper>
          <StyledLoader size={isMobile ? 42 : 84} />
        </StyledLoadingWrapper>
      </StyledProductSubWrapper>
      <StyledProductTileFooter>
        <StyledProductTileDescription isPDP={false}>
          <SkeletonTheme baseColor="transparent" highlightColor={'rgba(0, 0, 0, 0.1)'}>
            <StyledSkeletonWrapper>
              <StyledProductName>
                <Skeleton
                  style={{
                    width: '20%',
                    borderRadius: 0,
                  }}
                />
              </StyledProductName>
              <StyledProductBrandName>
                <Skeleton
                  style={{
                    width: '30%',
                    borderRadius: 0,
                  }}
                />
              </StyledProductBrandName>
              <Skeleton
                style={{
                  width: '50%',
                  borderRadius: 0,
                }}
              />
            </StyledSkeletonWrapper>
          </SkeletonTheme>
        </StyledProductTileDescription>
      </StyledProductTileFooter>
    </StyledProductTileWrapper>
  )
}
