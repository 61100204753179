import { ISeo, SeoResponse } from '../../types/seo'

import { Category } from '../../components/PagesSeo/category'
import Plp from '../../pages_views/Plp'
import NotFound from '../../pages_views/NotFound/NotFound'
import { Product } from '../../components/PagesSeo/product'

export const SEO_PAGE_TYPES = {
  NOT_FOUND: 'NotFound',
  PRODUCT_LIST_PAGE: 'ProductListPage',
  CATEGORY_PAGE: 'CategoryPage',
  PRODUCT_PAGE: 'ProductPage',
  ITEM_PAGE: 'ItemPage',
  VARIANT_PAGE: 'VariantPage',
  SEARCH_RESULT: 'SearchResult',
}

const resolveComponent = (component: any) => {
  switch (component.page.type) {
    case SEO_PAGE_TYPES.PRODUCT_LIST_PAGE:
      return Plp // Category Product
    case SEO_PAGE_TYPES.PRODUCT_PAGE:
    case SEO_PAGE_TYPES.ITEM_PAGE:
    case SEO_PAGE_TYPES.VARIANT_PAGE:
      return Product
    case SEO_PAGE_TYPES.CATEGORY_PAGE:
      return Category
    default:
      return NotFound
  }
}

export const getSEOConfigFromPayload = (response: SeoResponse<ISeo>, storeName: string) => {
  let seoconfig: ISeo
  if (!response || response.contents.length === 0) {
    seoconfig = {
      identifier: 'NotFound',
      page: {
        type: 'NotFound',
        title: storeName,
        identifier: 'NotFound',
      },
    }
  } else {
    const { tokenValue, tokenExternalValue } = response.contents[0]
    seoconfig = {
      ...response.contents[0],
      page: {
        tokenValue: tokenValue,
        externalContext: {
          identifier: tokenExternalValue,
        },
        ...response.contents[0].page,
      },
    }
  }
  const identifier = seoconfig.identifier
  seoconfig.component = resolveComponent(seoconfig)
  return { [encodeURI(identifier)]: seoconfig }
}

export const useSeoComponent = (seoConfigRes: SeoResponse, storeName: string) => {
  return {
    seoConfig: getSEOConfigFromPayload(seoConfigRes, storeName),
  }
}
