// Import Swiper styles
//TODO import '../index.scss'
import { ArrowLeftIcon, ArrowRightIcon } from '@components/UI/Icons/arrows'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import config from '@configs/index'
import Box from '@mui/material/Box/Box'
import { useTheme } from '@mui/material/styles'
import { FC } from 'react'
import { transformAttachmentsToImage } from '../../../features/plp/algoliaUtils'
import { IAlgoliaHit } from '../../../types/product'
import { getModelCodeDisplay, getModelName, getProductColorLabel } from '../../../utils/productAttributesAlgolia'
import { ProductImageProps } from '../../ProductImage/ProductImage'
import { StyledProductAnchor } from './ProductTile.style'
import { StyledControlsWrapper, StyledControlsWrapperColorLabel, TileGalleryCarousel } from './ProductTileGallery.style'

const siteName = config.name

type ProductTileGalleryProps = {
  isDisplayed?: boolean
  mocoLink: string
  tileDataElementId: string
  priorityBadgeText: string
  currentProductIndex: number
  cluster: IAlgoliaHit[]
  commonImageProps?: ProductImageProps
  onIndexChange: (ind: number) => void
}

const ProductTileGalleryAlgolia: FC<ProductTileGalleryProps> = ({
  isDisplayed = true,
  mocoLink,
  tileDataElementId,
  priorityBadgeText,
  cluster,
  currentProductIndex,
  commonImageProps,
  onIndexChange,
}) => {
  const theme = useTheme()
  const currentProduct = cluster[currentProductIndex]
  const name = getModelName(currentProduct)
  const modelCode = getModelCodeDisplay(currentProduct)

  return (
    <TileGalleryCarousel
      isDisplayed={isDisplayed}
      loop
      animation="fade"
      speed={2500}
      renderBottomCenterControls={({ previousSlide, nextSlide, currentSlide }) => (
        <StyledControlsWrapper isDisplayed={isDisplayed}>
          {cluster.length > 1 ? (
            <>
              <div className="icon-container">
                <ArrowLeftIcon
                  htmlColor={theme.palette.text.dark.primary}
                  onClick={e => {
                    e.stopPropagation()
                    const prevSlideIndex = currentSlide - 1
                    const slideTo = prevSlideIndex < 0 ? cluster.length - 1 : prevSlideIndex
                    previousSlide()
                    onIndexChange(slideTo)
                  }}
                />
              </div>
              <StyledControlsWrapperColorLabel>{`${getProductColorLabel(
                currentProduct
              )}`}</StyledControlsWrapperColorLabel>
              <div className="icon-container">
                <ArrowRightIcon
                  htmlColor={theme.palette.text.dark.primary}
                  onClick={e => {
                    e.stopPropagation()
                    const nextSlideIndex = currentSlide + 1
                    const slideTo = nextSlideIndex > cluster.length - 1 ? 0 : nextSlideIndex
                    nextSlide()
                    onIndexChange(slideTo)
                  }}
                />
              </div>
            </>
          ) : (
            <Box flex={1} textAlign="center">
              {getProductColorLabel(currentProduct)}
            </Box>
          )}
        </StyledControlsWrapper>
      )}
    >
      {cluster.map(c => (
        <StyledProductAnchor
          key={c.partnumberId}
          aria-label={priorityBadgeText || `${name}_${modelCode}`}
          href={mocoLink}
          className="product-tile__inner"
          data-element-id={tileDataElementId}
          data-description={`${siteName}_${name}_${modelCode}`}
        >
          <ProductMedia attachments={transformAttachmentsToImage(c.attachments)} {...commonImageProps} />
        </StyledProductAnchor>
      ))}
    </TileGalleryCarousel>
  )
}

export default ProductTileGalleryAlgolia
