import { Link } from '@components/common/Link/Link'
import styled from '@mui/material/styles/styled'

export const ItemLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  cursor: 'pointer',
}))

export const OmnichannelInfoWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
}))

export const OmnichannelIconWrapper = styled('div')(() => ({
  height: 32,
  width: 32,
}))
