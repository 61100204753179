import styled from '@mui/material/styles/styled'

export const ProductColorSelectorContainer = styled('div', {
  name: 'ProductColorSlider',
  slot: 'Container',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
}))

export const StyledSelectedColor = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SelectedColor',
})(() => ({}))

export const StyledSelectedColorLabel = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SelectedColorLabel',
})(({ theme }) => ({
  fontSize: 14,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  lineHeight: 1.5,
  paddingBottom: theme.spacing(6),
}))

export const StyledColorThumbLink = styled('a', {
  name: 'ProductColorSlider',
  slot: 'ThumbLink',
})<{ selected: boolean }>(({ selected, theme }) => ({
  display: 'flex',
  width: 109,
  height: 80,
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  boxShadow: 'none',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    width: 120,
  },

  '::after': {
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: selected ? `1px solid ${theme.palette.background.light.quaternary}` : 'none',
    boxSizing: 'border-box',
  },
}))

export const SwiperWrapper = styled('div', {
  name: 'ProductColorSlider',
  slot: 'SliderWrapper',
  shouldForwardProp: prop => prop !== 'isTabletLandscape',
})<{ isTabletLandscape: boolean }>(({ theme, isTabletLandscape }) => ({
  width: '100%',
  '.slider-container': {
    width: isTabletLandscape ? '116%' : '100%',
    padding: isTabletLandscape ? `0 ${theme.spacing(1)}` : `0 ${theme.spacing(3)}`,
  },
}))

/*
 * Actually, our Material UI version doesn't support "number" as spacing for Grid
 * So this is a little hacky, we should use spacing instead.
 * */
export const StyledColorsContainer = styled('div', {
  name: 'ProductColorSlider',
  slot: 'ColorsContainer',
})(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 1,
  marginLeft: 1,
  '.swiper-button-disabled': {
    visibility: 'hidden',
  },
}))

export const ProductColorInfoText = styled('p', {
  name: 'ProductColorSlider',
  slot: 'ColorInfoText',
  shouldForwardProp: prop => prop !== 'isLensRelatedProduct',
})<{ isLensRelatedProduct?: boolean }>(({ theme, isLensRelatedProduct }) => ({
  margin: theme.spacing(isLensRelatedProduct ? 2 : 6, 0, 0),
  fontSize: 12,
  lineHeight: 1.33,
  color: theme.palette.text.dark.primary,
}))

export const ProductColorSelectorProp = styled('span', {
  name: 'ProductColorSelector',
  slot: 'Property Label',
})(() => ({
  fontWeight: 600,
}))
