import Drawer from '@mui/material/Drawer'
import styled from '@mui/material/styles/styled'
import { StyledTypography } from '@components/UI/Typography'

export const ProductSizeWrapper = styled('div', {
  name: 'ProductSize',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),

  backgroundColor: theme.palette.background.light.primary,
  color: theme.palette.text.dark.primary,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}))

export const StyledSizeLabel = styled('div', {
  name: 'ProductSize',
  slot: 'Label',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: 1.43,
  fontWeight: 600,
  flex: '1 0 auto',
  textTransform: 'uppercase',
}))

export const StyledSizeCtaOverlay = styled('div', {
  name: 'ProductSize',
  slot: 'CtaOverlay',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: 1.43,
  fontWeight: 600,
  marginRight: theme.spacing(3.25),
  cursor: 'pointer',
}))

export const StyledSizeCtaWrapper = styled('div', {
  name: 'ProductSize',
  slot: 'CtaWrapper',
})(() => ({
  display: 'flex',
}))

export const StyledRecommendedSizeLabel = styled('div', {
  name: 'ProductSize',
  slot: 'RecommendedSizeLabel',
})(({ theme }) => ({
  fontSize: theme.spacing(2.5),
  lineHeight: 1.6,
  fontWeight: 600,
  paddingTop: theme.spacing(0.5),
}))

export const StyledSizeDrawer = styled(Drawer)(() => ({}))

export const StyledSizeDrawerContainer = styled('div', {
  name: 'ProductSize',
  slot: 'DrawerContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.light.primary,
  width: '100vw',

  [theme.breakpoints.up('sm')]: {
    width: 375,
  },
}))

export const StyledSuggestedLabel = styled(StyledTypography, {
  name: 'StyledSuggestedLabel',
  slot: 'product-size',
  shouldForwardProp: (props) => props !== 'isRecommended',
})<{ isRecommended?: boolean }>(({ isRecommended, theme }) => ({
  textTransform: isRecommended ? 'uppercase' : 'unset',
  color: isRecommended ? theme.palette.text.dark.primary : theme.palette.text.light.secondary,
  fontWeight: isRecommended ? '600' : '400',
  border: isRecommended ? `1px solid ${theme.palette.custom.halfBaked}` : 'none',
  borderRadius: '15px',
  padding: isRecommended ? `${theme.spacing(1)} ${theme.spacing(2)}` : 0,

  svg: {
    width: '10px',
    height: '10px',
    marginRight: theme.spacing(1),
  },
}))

export const StyledSizeDrawerHeader = styled('div', {
  name: 'StyledSuggestedLabel',
  slot: 'DrawerHeader',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.background.dark.primary,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  width: '100vw',

  color: theme.palette.text.light.primary,
  fontSize: theme.spacing(3.5),
  fontWeight: 600,
  lineHeight: 1.43,

  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },

  svg: {
    cursor: 'pointer',
    height: 16,
    width: 16,
  },
}))

export const StyledSizeItemContainer = styled('div', {
  name: 'StyledSuggestedLabel',
  slot: 'ItemContainer',
})<{ selected?: boolean }>(({ theme, selected }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.43,
  fontSize: theme.spacing(3.5),
  fontWeight: selected ? 'bold' : 'normal',
  color: theme.palette.text.dark.primary,
  borderBottom: `1px solid ${theme.palette.background.light.tertiary}`,
  cursor: 'pointer',

  svg: {
    marginLeft: 'auto',
  },
}))

export const StyledSizeGuideLink = styled('div', {
  name: 'StyledSuggestedLabel',
  slot: 'SizeGuideLink',
})(({ theme }) => ({
  lineHeight: 1.43,
  fontWeight: 'bold',
  fontSize: theme.spacing(3.5),
  textDecoration: 'underline',
  color: theme.palette.text.dark.primary,
  cursor: 'pointer',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
}))

export const StyledFindMySize = styled('div', {
  name: 'StyledSuggestedLabel',
  slot: 'FindMySize',
})(({ theme }) => ({
  lineHeight: 1.43,
  fontWeight: 'bold',
  fontSize: theme.spacing(3.5),
  color: theme.palette.text.dark.primary,
  cursor: 'pointer',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const StyledIconFrameSize = styled('div', {
  name: 'StyledSuggestedLabel',
  slot: 'IconFrameSize',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'svg:last-child': {
    marginLeft: theme.spacing(5),
  },
}))
