import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const ContactLensIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox = '0.618694 3.33946 23.97 20.66',
  ...rest
}) => {
  return (
    <IconWrapper viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`} width={width} height={height} {...rest}>
      <g clipPath="url(#7zzjt8v2sa)">
        <path
          d="M24.587 13.722c0-5.787-3.103-10.277-5.771-10.277-.115 0-.229.009-.342.022-2.233.091-4.232 1.06-5.75 2.626a15.424 15.424 0 0 0-.939-.669c-3.457-2.248-7.261-2.747-8.85-1.158l-.002.003c-3.454 3.5-2.988 9.623 1.038 13.65 2.112 2.111 4.866 3.285 7.588 3.285.33 0 .66-.024.989-.059 1.601 1.74 3.781 2.81 6.224 2.85.015 0 .029.005.043.005l.013-.002c.03 0 .058.005.087.005v-.013c3.032-.103 5.672-4.852 5.672-10.268zM11.239 6.263c.28.183.555.381.825.586-1.419 1.81-2.279 4.205-2.279 6.877 0 1.353.229 2.632.633 3.806a14.374 14.374 0 0 1-3.59-2.625c-3.63-3.631-4.712-8.398-3.189-9.93l.004-.004c1.065-1.064 4.271-.873 7.596 1.29zm-6.56 10.948c-2.743-2.742-3.68-6.535-2.663-9.57.25 2.59 1.909 5.777 4.105 7.973 1.427 1.427 3.11 2.557 4.875 3.284.233.458.493.895.784 1.303-2.53.068-5.12-1.008-7.102-2.99zm6.106-3.485c0-3.983 2.071-7.297 5.06-8.636-1.693 1.995-2.801 5.482-2.801 8.632 0 3.676 1.252 6.822 2.849 8.633-3.019-1.343-5.108-4.653-5.108-8.63zm8.043 9.272-.045-.002c-1.921-.042-4.739-3.921-4.739-9.274 0-5.097 2.567-9.204 4.718-9.27.018 0 .035-.003.053-.003v-.003c1.922 0 4.771 3.895 4.771 9.277 0 5.214-2.554 9.26-4.758 9.275z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="7zzjt8v2sa">
          <path fill={htmlColor} transform="translate(.625)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const ContactLensFingerIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M10.892 16.866c1.854 0 3.624.576 5.117 1.665.54.394 1.036.852 1.523 1.414l27.573 27.589 1.415-1.414-23.567-23.577c1.605.514 3.226.773 4.804.773 4.627 0 8.872-2.158 11.184-6.163v-.001c1.007-1.745.487-4.15-1.464-6.77-1.777-2.386-4.58-4.754-7.892-6.666C26.273 1.804 22.822.56 19.866.214c-3.246-.381-5.586.373-6.594 2.118-.015.026-.022.054-.036.08l-.007-.004c-2.235 4.047-1.915 8.826.426 12.824a10.712 10.712 0 0 0-2.763-.367c-5.895 0-10.69 4.796-10.69 10.69v.415l21.856 21.856 1.414-1.414-11.153-11.15c1.991-2.436 2.756-5.292 1.932-7.503-.61-1.64-2.114-3.028-4.124-3.81-2.388-.93-5.145-.894-7.776.04.743-4.044 4.285-7.123 8.541-7.123zm9.686 2.453c-4.628-2.672-7.158-7.323-6.832-11.77.276.508.596 1.025.99 1.553 1.776 2.387 4.58 4.755 7.891 6.667 4.13 2.384 8.161 3.61 11.248 3.61.128 0 .247-.01.371-.014-3.657 2.536-8.969 2.667-13.668-.046zM18.273 2.117c.422 0 .877.027 1.361.084 2.691.315 5.87 1.469 8.951 3.248 3.082 1.779 5.67 3.955 7.288 6.128 1.423 1.91 1.91 3.579 1.334 4.575-1.173 2.03-7.039 1.66-13.58-2.115-3.083-1.78-5.67-3.956-7.289-6.13-1.423-1.91-1.91-3.578-1.334-4.574.458-.793 1.62-1.216 3.27-1.216zm-8.87 23.696c1.48.574 2.562 1.538 2.974 2.643.552 1.484-.04 3.556-1.476 5.387l-7.949-7.948c2.202-.798 4.491-.843 6.451-.082z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const ContactLensesAccessoriesIcon: React.FC<CustomSVGIconProps> = ({
  width = 28,
  height = 20,
  htmlColor,
  ...rest
}) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path d="M15.5024 3.50092H11.5024V4.50092H15.5024V3.50092Z" fill={htmlColor} />
      <path d="M11.484 4.56824L10.984 5.43424L14.448 7.43424L14.948 6.56824L11.484 4.56824Z" fill={htmlColor} />
      <path d="M14.4496 0.568359L10.9852 2.56796L11.4851 3.43406L14.9495 1.43446L14.4496 0.568359Z" fill={htmlColor} />
      <path
        d="M7.1294 12.0009H6.1294V14.0009H4.1294V15.0009H6.1294V17.0009H7.1294V15.0009H9.1294V14.0009H7.1294V12.0009Z"
        fill={htmlColor}
      />
      <path
        d="M9.1294 6.23042V3.00092C9.1294 2.44842 8.6819 2.00092 8.1294 2.00092H5.1294C4.5769 2.00092 4.1294 2.44842 4.1294 3.00092V6.23042C2.1009 6.86742 0.629395 8.76192 0.629395 11.0009V23.0009C0.629395 23.5534 1.07689 24.0009 1.62939 24.0009H11.6294C12.1819 24.0009 12.6294 23.5534 12.6294 23.0009V11.0009C12.6294 8.76242 11.1579 6.86792 9.1294 6.23042ZM5.1294 6.00092V3.00092H8.1294V6.00092H5.1294ZM11.6294 23.0009H1.62939V11.0009C1.62939 8.79543 3.4239 7.00092 5.6294 7.00092H7.6294C9.8349 7.00092 11.6294 8.79543 11.6294 11.0009V23.0009Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
