import { useBreakpoint } from '@hooks/useBreakpoint'
import { useMemo } from 'react'

export const useGetProductImageWidth = (variant?: string): number => {
  const { isMobile, isTablet, isDesktop } = useBreakpoint()

  return useMemo(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 400
      case isMobile:
        return 296
      case isTablet:
        return 600
      case isDesktop:
        return 600
      default:
        return 260
    }
  }, [isMobile, isTablet, isDesktop, variant])
}
