import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV1 } from '../../../../constants/ui'

const ProductAttributeBadgesContainer = styled('div')(({ theme }) => ({
  zIndex: Z_INDEX_LV1,
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  textAlign: 'center',
  fontSize: 10,
  lineHeight: '16px',
  color: theme.palette.text.dark.primary,
}))

const ProductPrimaryAttributeBadge = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 600,
  lineHeight: '16px',
  textTransform: 'uppercase',

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
    fontSize: 14,
    lineHeight: '20px',
  },
}))

const ProductSecondaryAttributeBadges = styled('p')(() => ({
  margin: 0,
}))

export { ProductAttributeBadgesContainer, ProductPrimaryAttributeBadge, ProductSecondaryAttributeBadges }
