import { ArrowUpIcon } from '@components/UI/Icons/arrows'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cartSelector, orderItemsSelector } from '../../../../features/order/selector'
import theme from '../../../../themes'
import { IOrderItem } from '../../../../types/order'
import type { Attachment, IProduct, ProductSoldOutStatus } from '../../../../types/product'
import { isCLAccessoriesProduct, isContactLensesProduct } from '../../../../utils/product'
import { isRxCart, parseRxOrderItems } from '../../../../utils/rx'
import ProductCtaContainer from '../ProductCtaContainer'
import * as S from './PDPStickyBar.style'
import { StyledProductImage } from './PDPStickyBar.style'
import ProductPriceNew from '../ProductPriceNew'
import { getProductType } from '@utils/productAttributes'
import useProductPrice from '@hooks/useProductPrice'

interface PDPStickyBarProps {
  loading: boolean
  partNumber: string
  product: IProduct
  images?: Attachment[]
  currentIndex: number
  soldOutState: ProductSoldOutStatus
  addToCartButtonFail: boolean
  stickyBarCtaRef: React.RefObject<HTMLDivElement>
  isRoxable?: boolean
  visible?: boolean
  productQuantity?: string[]
  customerSegments?: string[]
  onlyFewPieces: boolean
  lazy?: boolean
}

const PDPStickyBar: FC<PDPStickyBarProps> = ({
  loading,
  partNumber,
  product,
  images,
  currentIndex,
  soldOutState,
  addToCartButtonFail,
  stickyBarCtaRef,
  isRoxable,
  visible,
  productQuantity,
  onlyFewPieces,
  lazy,
}) => {
  const { t } = useTranslation()
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const modelName = product.productAttributes['MODEL_NAME']
  const brandName = product.productAttributes['BRAND']

  const [clicked, setClicked] = React.useState<boolean>(false)

  const isClAccessory = isCLAccessoriesProduct(product)
  const isCL = isContactLensesProduct(product)
  const { currentPrice: prices } = useProductPrice(product.x_price)

  const handleScrollToTop = () => {
    setClicked(true)
    document.getElementById('main-viewport')?.scrollIntoView({ behavior: 'smooth' })
  }

  const initialPrice = prices?.listPrice
  const currentPrice = prices?.offerPrice

  const productInCart: IOrderItem | undefined =
    (isRxCart(cart?.orderExtendAttribute) ? parseRxOrderItems(orderItems) : orderItems).find(
      i => i.partNumber === product.partNumber || i.partNumber === partNumber
    ) || undefined

  return (
    <S.StyledStickyCta data-element-id="X_X_Stickybar" visible={visible}>
      <S.StyledInfoProductContainer>
        <h5 aria-label={brandName || 'brandName'}>{brandName}</h5>
        <h6 aria-label={modelName || 'modelName'}>{modelName}</h6>
      </S.StyledInfoProductContainer>
      <S.StyledImageAndPriceContainer>
        {images && images.length > 0 && (
          <StyledProductImage
            attachments={[images[currentIndex]]}
            usage="PDP"
            width={340}
            alt={modelName}
            backgroundColor={theme.palette.background.light.primary}
            lazy={lazy}
          />
        )}
        <S.StickyBarPriceWrapper flexDirection={currentPrice ? 'column' : 'row'}>
          {!isCL && !isClAccessory && (
            <S.StyledFrameContainer>{t('ProductAvailableNotificationDrawer.Labels.Frame')}</S.StyledFrameContainer>
          )}

          <S.StickyBarPriceContainer>
            <ProductPriceNew
              isPDP={true}
              isCL={true}
              productType={getProductType(product)}
              isCompact={true}
              price={prices}
              productQuantity={productQuantity}
            />
          </S.StickyBarPriceContainer>
        </S.StickyBarPriceWrapper>
        {(initialPrice || currentPrice) && (
          <ProductCtaContainer
            productInCart={productInCart}
            type={soldOutState}
            currentProduct={product}
            error={addToCartButtonFail}
            ctaRef={stickyBarCtaRef}
            addToCartFillType={'fill'}
            roxable={isRoxable}
            productQuantity={productQuantity}
            onlyFewPieces={onlyFewPieces}
            isStickyBar
          />
        )}
      </S.StyledImageAndPriceContainer>
      <S.StyledRoundPill
        id="scrollToTop"
        key="scrollToTop"
        aria-label="ScrollToTop"
        data-element-id="X_X_Stickybar_ScrollUp"
        labelText=""
        icon={<ArrowUpIcon />}
        onClick={handleScrollToTop}
        clicked={clicked}
      />
    </S.StyledStickyCta>
  )
}

export default PDPStickyBar
