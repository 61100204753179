import React, { FC } from 'react'
//TYPES
import { IProduct, ProductSoldOutStatus } from '../../../types/product'
//REDUX

//CONSTS
//COMPONENTS
import CtaPdp from './cta'
import { CartInterceptListener } from '@components/DirectBilling'
// TYPES
import { IOrderItem } from '../../../types/order'
//HOOKS
import { useSite } from '@foundation/hooks/useSite'
//UTILS
import { useTranslation } from 'next-i18next'

//API
import { useAddOrderItem } from '../hooks/useAddOrderItem'
import { PdpCtasOnlyFewPieces } from './cta/CtaPdp.style'

export interface ProductCtaContainerProps {
  error?: boolean
  currentProduct: IProduct
  roxable?: boolean
  type: ProductSoldOutStatus
  payload?: any
  productQuantity?: string[]
  productInCart?: IOrderItem
  ctaRef: React.RefObject<HTMLDivElement>
  addToCartFillType?: 'outline' | 'fill'
  isStickyBar?: boolean
  onlyFewPieces: boolean
}

const ProductCtaContainer: FC<ProductCtaContainerProps> = ({
  error,
  currentProduct,
  type,
  ctaRef,
  roxable,
  addToCartFillType,
  productQuantity,
  isStickyBar,
  productInCart,
  onlyFewPieces,
}: ProductCtaContainerProps) => {
  const { addToCart } = useAddOrderItem(productInCart, currentProduct, currentProduct.partNumber, productQuantity)
  const { t } = useTranslation()
  const { mySite } = useSite()
  const isDirectBillingEnabled = mySite.xStoreCfg?.DIRECT_BILLING_ENABLED

  return (
    <>
      {onlyFewPieces && <PdpCtasOnlyFewPieces>{t('CtaPDP.Labels.OnlyFewPieces')}</PdpCtasOnlyFewPieces>}

      <CtaPdp
        ref={ctaRef}
        type={type}
        product={currentProduct}
        error={error}
        addToCart={addToCart}
        roxable={roxable}
        addToCartFillType={addToCartFillType}
        isStickyBar={isStickyBar}
      />

      {isDirectBillingEnabled && <CartInterceptListener />}
    </>
  )
  // )
}

export default ProductCtaContainer
