import { ArrowLeftIcon } from '@components/UI/Icons/arrows'
import { CloseIcon } from '@components/UI/Icons/close'
import { StyledTypography } from '@components/UI/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { debounce } from '@utils/helpers'
import { useTranslation } from 'next-i18next'
import React, { Fragment, createRef, useCallback, useEffect, useRef, useState } from 'react'
import * as S from '../ProductSize.style'
import { BridgeGuide } from './BridgeGuide'
import { FitGuide } from './FitGuide'
import { SizeGuide } from './SizeGuide'
import { Chip, Header, ScrollableContainer, StyledNonStickyHeader, StyledStickyHeader } from './styles'

interface SizeFitGuideProps {
  sizeGuideOpen: boolean
  onBackdropClick: () => void
}

const contents = [
  {
    id: 0,
    component: props => <SizeGuide {...props} />,
    key: 'size',
  },
  { id: 1, component: props => <FitGuide {...props} />, key: 'fit' },
  { id: 2, component: props => <BridgeGuide {...props} />, key: 'bridge' },
]

export const SizeFitGuide: React.FC<SizeFitGuideProps> = ({ sizeGuideOpen, onBackdropClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const drawerHeader = useRef<HTMLDivElement | null>(null)
  const [scrollableContainer, setScrollableContainer] = useState<HTMLDivElement | null>(null)
  const [header, setHeader] = useState<HTMLElement | null>(null)
  const [nonStickyHeader, setNonStickyHeader] = useState<HTMLElement | null>(null)

  const [isSticky, setIsSticky] = useState(false)
  const [activeChipIndex, setActiveChipIndex] = useState(-1)

  const refs = useRef([...Array(contents.length)].map(() => createRef<HTMLDivElement>()))

  const onChipClick = useCallback(
    (ind: number) => {
      if (ind === activeChipIndex) {
        setActiveChipIndex(-1)
        scrollableContainer?.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      const drawerHeaderHeight = drawerHeader.current?.clientHeight ?? 0
      const headerHeight = header?.clientHeight ?? 0
      const elemToScrollHeight = refs.current[ind]?.current?.offsetTop ?? 0

      const scrollTo = elemToScrollHeight - headerHeight - drawerHeaderHeight

      scrollableContainer?.scrollTo({ top: scrollTo, behavior: 'smooth' })
    },
    [activeChipIndex, scrollableContainer, header]
  )

  const calculate = useCallback(
    (scrollTop: number) => {
      const headerOffset = nonStickyHeader?.offsetTop ?? 0
      const drawerHeaderHeight = drawerHeader.current?.clientHeight ?? 0

      if (headerOffset - scrollTop - drawerHeaderHeight <= 0) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    },
    [nonStickyHeader]
  )

  const autoScroll = useCallback(
    (scrollTop: number) => {
      const drawerHeaderHeight = drawerHeader.current?.clientHeight ?? 0
      const headerHeight = header?.clientHeight ?? 0
      const offsets = refs.current.map(el => (el.current?.offsetTop ?? 0) - drawerHeaderHeight - headerHeight)

      const min = offsets.reduce((prev, curr) =>
        Math.abs(curr - scrollTop) < Math.abs(prev - scrollTop) ? curr : prev
      )
      const index = offsets.findIndex(el => {
        return min === el
      })

      if (index !== activeChipIndex) {
        setActiveChipIndex(index)
      }
    },
    [header, activeChipIndex]
  )

  useEffect(() => {
    if (!scrollableContainer) return

    const scrollListener = debounce(() => {
      calculate(scrollableContainer.scrollTop)
      autoScroll(scrollableContainer.scrollTop)
    }, 200)

    scrollableContainer.addEventListener('scroll', scrollListener)

    return () => {
      if (!scrollableContainer) return
      scrollableContainer.removeEventListener('scroll', scrollListener)
    }
  }, [scrollableContainer])

  const StickyHeader = () => {
    return (
      <>
        <StyledNonStickyHeader isVisible={!isSticky} ref={setNonStickyHeader}>
          {contents.map(el => (
            <Chip key={el.id} onClick={() => onChipClick(el.id)} selected={el.id === activeChipIndex}>
              {t(`SizeFitGuide.category.${el.key}`)}
            </Chip>
          ))}
        </StyledNonStickyHeader>

        {isSticky && (
          <StyledStickyHeader ref={setHeader}>
            {contents.map(el => (
              <Chip key={el.id} onClick={() => onChipClick(el.id)} selected={el.id === activeChipIndex}>
                {t(`SizeFitGuide.category.${el.key}`)}
              </Chip>
            ))}
          </StyledStickyHeader>
        )}
      </>
    )
  }

  return (
    <S.StyledSizeDrawer open={sizeGuideOpen} onBackdropClick={onBackdropClick} anchor="right">
      <S.StyledSizeDrawerContainer style={{ overflow: 'hidden' }}>
        <Header ref={drawerHeader}>
          <ArrowLeftIcon htmlColor={theme.palette.custom.white} onClick={onBackdropClick} />
          <span>{t('SizeFitGuide.title').toUpperCase()}</span>
          <CloseIcon onClick={onBackdropClick} />
        </Header>
        <ScrollableContainer ref={setScrollableContainer}>
          <StyledTypography marginBottom={4} color={theme.palette.text.dark}>
            {t('SizeFitGuide.description1')}
          </StyledTypography>
          {StickyHeader()}
          {contents.map((el, ind) => (
            <Fragment key={el.id}>{el.component({ ref: refs.current[ind] })}</Fragment>
          ))}
        </ScrollableContainer>
      </S.StyledSizeDrawerContainer>
    </S.StyledSizeDrawer>
  )
}
