import React from 'react'
import { StyledBreadcrumbLink, StyledBreadcrumbs, StyledTypography } from './BreadCrumbs.style'

export interface BreadcrumbLink {
  label?: string
  href?: string
}

type BreadcrumbsProps = {
  items: BreadcrumbLink[]
}

function BreadCrumbs(props: BreadcrumbsProps) {
  const { items } = props

  if (!items || items.length === 0) return null

  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {items.map((item, index) =>
        item.href ? (
          <StyledBreadcrumbLink href={item.href} key={index}>
            {item.label}
          </StyledBreadcrumbLink>
        ) : (
          <StyledTypography variant="body2" key={`product-${index}`}>
            {item.label}
          </StyledTypography>
        )
      )}
    </StyledBreadcrumbs>
  )
}

export default BreadCrumbs
