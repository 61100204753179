import React from 'react'
import { StyledStack, StyledToggleButton, StyledToggleGroup, StyledToggleItems } from './Toggle.style'
import { EyeCareIcon } from '@components/UI/Icons/eye'
import { ContactLensIcon } from '@components/UI/Icons/contactLenses'
import { GlassesCase, GlassesIcon } from '@components/UI/Icons/glasses'

export interface ITogglesDataProps {
  label: string
  value: string
}

export interface IToggleButtonsProps {
  defaultValue: string | null
  togglesData?: ITogglesDataProps[]
  variant?: 'primary' | 'secondary'
  onChangeValue?: (event: React.MouseEvent<HTMLElement, MouseEvent>, newValue: string | null) => void
}

export const ToggleButtons: React.FC<IToggleButtonsProps> = ({ defaultValue, togglesData, variant, onChangeValue }) => {
  /* const [currentValue, setCurrentValue] = useState<string | null>(defaultValue)
  const [width, setWidth] = useState<string>('')
  const [translate, setTranslate] = useState<number>(0)
  const toggleElements = useRef<any>([])*/
  /*const handleChange = (e: React.MouseEvent<HTMLElement>, index: number) => {
    const element = e.target as HTMLButtonElement
    const width = element.offsetWidth

    if (index === 0) {
      setTranslate(0)
    } else {
      let count = 0
      // setting the right shift for the slider toggle
      toggleElements?.current?.map((el, i) => {
        if (i < index) {
          count += el.offsetWidth
        }
      })
      setTranslate(count)
    }

    setCurrentValue(element.value)
    setWidth(width.toString())
  }*/

  const setIcon = (label: string) => {
    switch (label) {
      case 'frames':
        return <GlassesIcon />
      case 'contactLenses':
        return <ContactLensIcon />
      case 'contactLensesAccessories':
        return <EyeCareIcon />
      case 'accessories':
        return <GlassesCase />
      default:
        return null
    }
  }

  return (
    <StyledStack className="toggle-buttons" direction="row">
      <StyledToggleGroup value={defaultValue} onChange={onChangeValue} exclusive>
        {togglesData?.map((item, index) => {
          return (
            <StyledToggleButton key={index} value={item.value} variant={variant}>
              <StyledToggleItems variant={variant}>
                {setIcon(item.label) ?? <span>{item.label}</span>}
              </StyledToggleItems>
            </StyledToggleButton>
          )
        })}
      </StyledToggleGroup>
    </StyledStack>
  )
}
