import styled from '@mui/material/styles/styled'
import { Button } from '@components/UI/Button'
import * as S from '../ProductSize.style'

export const ScrollableContainer = styled('div', {
  name: 'SizeFitGuide',
  slot: 'ScrollableContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  height: 'calc(100% - 68px)',
  overflowY: 'auto',
  overflowX: 'hidden',

  '&>div:not(:last-child)': {
    marginBottom: theme.spacing(10),
  },
}))

export const StyledButton = styled(Button, {
  name: 'SizeFitGuide',
  slot: 'FindMySize',
})(() => ({}))

export const StyledNonStickyHeader = styled('header', {
  name: 'SizeFitGuide',
  slot: 'NonStickyHeader',
  shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible?: boolean }>(({ theme, isVisible }) => ({
  display: 'flex',
  margin: isVisible ? `0 0 ${theme.spacing(4)}` : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  height: isVisible ? 'auto' : 0,

  span: {
    marginRight: theme.spacing(4),
  },
}))

export const StyledStickyHeader = styled('header', {
  name: 'SizeFitGuide',
  slot: 'StickyHeader',
})(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: `-${theme.spacing(6)}`,
  backgroundColor: theme.palette.custom.white,
  boxShadow: '0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16), 0 0 2.9px 0 rgba(40, 41, 61, 0.04)',

  padding: theme.spacing(4),
  margin: `0 -${theme.spacing(4)}`,

  span: {
    marginRight: theme.spacing(4),
  },
}))

export const Chip = styled('span', {
  name: 'SizeFitGuide',
  slot: 'Chip',
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.custom.cyprus : theme.palette.custom.wildSand,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  border: `1px solid ${theme.palette.custom.wildSand}`,
  borderRadius: theme.spacing(10),
  fontFamily: theme.typography.fontFamily,
  fontSize: 10,
  fontWeight: 600,
  textTransform: 'uppercase',
  cursor: 'pointer',
  color: selected ? theme.palette.custom.white : theme.palette.text.dark.primary,
}))

export const ImageContainer = styled('div', {
  name: 'SizeFitGuide',
  slot: 'ImgContainer',
})(({ theme }) => ({
  margin: `${theme.spacing(0)} -${theme.spacing(4)}`,

  img: {
    width: '100%',
  },
}))

export const SizeTable = styled('div', {
  name: 'SizeFitGuide',
  slot: 'Sizetable',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `0.5px solid ${theme.palette.custom.halfBaked}`,
}))

export const SizeTableTr = styled('div', {
  name: 'SizeFitGuide',
  slot: 'SizetableTr',
})(({ theme }) => ({
  border: `0.5px solid ${theme.palette.custom.halfBaked}`,
  padding: theme.spacing(2),
  fontSize: 12,
  color: theme.palette.text.dark.primary,
}))

export const SizeTableTd = styled('div', {
  name: 'SizeFitGuide',
  slot: 'SizetableTd',
  shouldForwardProp: prop => prop !== 'coloredBg' && prop !== 'highLighted',
})<{ highLighted?: boolean }>(({ highLighted, theme }) => ({
  backgroundColor: highLighted ? theme.palette.custom.wildSand : 'transparent',
  width: 'calc(100% / 3)',
  textTransform: highLighted ? 'uppercase' : 'none',
  fontWeight: highLighted ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}))

export const CtaContainer = styled('div', {
  name: 'SizeFitGuide',
  slot: 'CtaContainer',
})(({ theme }) => ({
  margin: `${theme.spacing(0)} -${theme.spacing(4)} ${theme.spacing(10)}`,
  padding: `${theme.spacing(10)} ${theme.spacing(4)}`,
  backgroundColor: theme.palette.custom.wildSand,
}))

export const Header = styled(S.StyledSizeDrawerHeader, {
  name: 'SizeFitGuide',
  slot: 'Header',
})(({ theme }) => ({
  justifyContent: 'flex-start',

  span: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
}))
