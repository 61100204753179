import React from 'react'
import { SupplyData } from '../../../../types/product'
import SupplyBadge from './SupplyBadge'
import { SliderBadgesWrapper, SupplyBadgeWrapper } from './SupplyBage.style'

const SupplyBadges: React.FC<{
  supplyData?: SupplyData[]
  isMobile?: boolean
}> = ({ supplyData, isMobile }) => {
  return isMobile && supplyData && supplyData?.length > 1 ? (
    <SliderBadgesWrapper slidesPerView={1.5} spaceBetween={16}>
      {supplyData?.map((data, i) => {
        return <SupplyBadge key={i} data={data} />
      })}
    </SliderBadgesWrapper>
  ) : !!supplyData ? (
    <SupplyBadgeWrapper>
      {supplyData.map((data, i) => {
        return <SupplyBadge data={data} key={i} />
      })}
    </SupplyBadgeWrapper>
  ) : null
}

export default SupplyBadges
