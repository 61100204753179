import React from 'react'
import { StyledLinkList } from './LinkList.style'

type LinkListProps = {
  children: React.ReactNode
  className?: string
}

function LinkList(props: LinkListProps) {
  const { children, className } = props

  return <StyledLinkList className={className}>{children}</StyledLinkList>
}

export default LinkList
