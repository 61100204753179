import React from 'react'
import config from '@configs/config.base'
import useTheme from '@mui/material/styles/useTheme'
import { useTranslation } from 'next-i18next'
import { StyledTypography } from '@components/UI/Typography'
import { ImageContainer } from './styles'

const fitGuide = config.publicUrl + 'images/common/fitGuide.png'

export const FitGuide = React.forwardRef<HTMLDivElement>(function FitGuide(_, ref) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div ref={ref as unknown as React.LegacyRef<HTMLDivElement>}>
      <StyledTypography isUppercased marginBottom={4} fontSize={20} fontWeight={'600'} color={theme.palette.text.dark}>
        {t('SizeFitGuide.fit.title')}
      </StyledTypography>

      <StyledTypography
        marginBottom={4}
        color={theme.palette.text.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.fit.description1'),
        }}
      />
      <ImageContainer>
        <img src={fitGuide} />
      </ImageContainer>
      <StyledTypography
        marginBottom={4}
        color={theme.palette.text.dark}
        dangerouslySetInnerHTML={{
          __html: t('SizeFitGuide.fit.description2'),
        }}
      />
    </div>
  )
})
