import { Carousel } from '@components/common/components/Carousel'
import styled from '@mui/material/styles/styled'

export const SupplyBadgeWrapper = styled('div', {
  name: 'SupplyBadge',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const SupplyBadgeContainer = styled('div', {
  name: 'SupplyBadge',
  slot: 'Container',
})(({ theme }) => ({
  fontSize: 14,
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.light.secondary,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
}))

export const SliderBadgesWrapper = styled(Carousel, {
  name: 'SupplyBadge',
  slot: 'SliderWrapper',
})(() => ({
  width: '100%',
}))
