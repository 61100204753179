import { ProductMedia } from '@components/common/Media/ProductMedia'
import useBreakpoints from '@hooks/useBreakpoints'
import { CustomProgressbar, ProgressbarItem } from '@layouts/Footer/components/PreFooter/PreFooter.style'
import { useTheme } from '@mui/material/styles'
import { FC, PropsWithChildren, useState } from 'react'
import { ProductImageProps } from '../../../../components/ProductImage/ProductImage'
import { ArrowLeftIcon, ArrowRightIcon } from '../../../../components/UI/Icons/arrows'
import { Attachment } from '../../../../types/product'
import {
  ProductImageCarousel,
  SliderContainer,
  SliderNavigationButton,
  SliderProductImageWrapper,
} from './ProductImagesCarousel.style'

/**
 * @param { Attachment[] } images slider images
 * @param { ProductImageProps } sliderImageProps slider images props
 * @param { string } mainCarouselWidth width in pixel of slider images and slider
 * @param { function } onImageClick callback when clicking the image
 * @param { function } onPrevClick callback when clicking the prev slide button
 * @param { function } onNextClick callback when clicking the next slide button
 * @param { function } onSlideChange callback when slide changes
 * @param { SwiperProps} sliderProps you can pass props to the main slider using this prop
 * @param { number } currentSlide you can force the slide index to the slider
 * @param { boolean } shownavigation show navigation buttons
 * visit https://swiperjs.com/react for more infos
 */

export type ProductImagesCarouselProps = PropsWithChildren<{
  sliderImageProps: ProductImageProps
  images?: Attachment[]
  mainCarouselWidth?: number
  currentDisplayedSlide?: number
  pdpdataloading?: boolean
  shownavigation?: boolean
  backgroundColor?: string
  loop?: boolean
  slidesPerView?: number
  slideIndex?: number
  swiping?: boolean
  withProgressBar?: boolean
  onImageClick?: (index: number) => void
  onImageLoaded?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  onSlidesLoadedAndRendered?(): void // is triggered when all images are fully loaded and rendered
}>

const ProductImagesCarousel: FC<ProductImagesCarouselProps> = ({
  children,
  currentDisplayedSlide = 0,
  images,
  pdpdataloading,
  shownavigation,
  sliderImageProps,
  loop = true,
  slidesPerView = 1,
  slideIndex,
  swiping,
  withProgressBar,
  onImageClick,
  onSlideChange,
  onSlidesLoadedAndRendered,
  onImageLoaded,
  ...rest
}: ProductImagesCarouselProps) => {
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const [animation, setAnimation] = useState<'fade' | undefined>(undefined)
  return images && images?.length > 0 && !pdpdataloading ? (
    <SliderContainer {...rest}>
      <ProductImageCarousel
        loop={loop}
        slidesPerView={slidesPerView}
        animation={animation}
        speed={2500}
        slideIndex={slideIndex}
        swiping={swiping}
        beforeSlide={(_, endSlideIndex) => {
          onSlideChange && onSlideChange(endSlideIndex)
        }}
        renderCenterLeftControls={({ currentSlide }) =>
          images?.length > 1 &&
          shownavigation && (
            <SliderNavigationButton
              onClick={() => {
                const prevSlideIndex = currentSlide - 1
                const slideTo = prevSlideIndex < 0 ? images.length - 1 : prevSlideIndex
                onSlideChange && onSlideChange(slideTo)
              }}
            >
              <ArrowLeftIcon htmlColor={theme.palette.custom.boulder} />
            </SliderNavigationButton>
          )
        }
        renderCenterRightControls={({ currentSlide }) =>
          images?.length > 1 &&
          shownavigation && (
            <SliderNavigationButton
              onClick={() => {
                const nextSlideIndex = currentSlide + 1
                const slideTo = nextSlideIndex > images.length - 1 ? 0 : nextSlideIndex
                onSlideChange && onSlideChange(slideTo)
              }}
            >
              <ArrowRightIcon htmlColor={theme.palette.custom.boulder} />
            </SliderNavigationButton>
          )
        }
        renderBottomCenterControls={() =>
          isMobile &&
          withProgressBar && (
            <CustomProgressbar>
              {images.map((_, index) => (
                <ProgressbarItem
                  active={currentDisplayedSlide === index}
                  key={`dot-${index}`}
                  width={100 / images.length}
                />
              ))}
            </CustomProgressbar>
          )
        }
      >
        {images?.map((_, index) => (
          <SliderProductImageWrapper
            key={index}
            className={currentDisplayedSlide === index ? 'selected' : ''}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ProductMedia
              onClick={() => onImageClick?.(index)}
              usage={sliderImageProps?.usage}
              attachments={[images[index]]}
              backgroundColor={theme.palette.background.light.primary}
              srcSetMap={sliderImageProps?.srcsetmap}
              lazy={index !== currentDisplayedSlide}
              fetchpriority="low"
              preload={index === 0}
              prefetch={index > 0 && index < 3}
              width={sliderImageProps?.width}
              shouldLoad={true}
              controls={true}
              onMouseMove={sliderImageProps?.onMouseMove}
              onTouchMove={sliderImageProps?.onTouchMove}
              onLoad={onImageLoaded}
            />
          </SliderProductImageWrapper>
        ))}
      </ProductImageCarousel>
      {children}
    </SliderContainer>
  ) : null
}

export default ProductImagesCarousel
