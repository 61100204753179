import React from 'react'
import { StyledBox } from './Box.style'

export type BoxThemeProps = 'light' | 'dark' | 'nopadding'
export interface BoxProps {
  withBorder?: boolean
  variant?: BoxThemeProps
  children?: React.ReactNode
  className?: string
}

function Box(props: BoxProps) {
  const { withBorder = false, variant = 'light', className, children } = props
  return (
    <StyledBox className={className} withBorder={withBorder} variant={variant}>
      {children}
    </StyledBox>
  )
}

export default Box
