import React from 'react'

import { StyledContainer } from './Container.style'

export interface ContainerProps {
  children: React.ReactNode
  id?: string
}

function Container(props: ContainerProps) {
  const { children, ...rest } = props
  return (
    <StyledContainer maxWidth="lg" {...rest}>
      {children}
    </StyledContainer>
  )
}

export default Container
