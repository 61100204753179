import styled from '@mui/material/styles/styled'

export const PlpDescriptionAccordionWrapper = styled('div', {
  name: 'PlpDescriptionAccordion',
  slot: 'Wrapper',
})(({ theme }) => ({
  marginTop: theme.spacing(-48),
  paddingBottom: theme.spacing(64),
  padding: theme.spacing(10, 4, 10),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(10, 24, 10),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(12, 44, 12),
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(16, 44, 16),
  },
}))

export const PlpDescriptionFaqsWrapper = styled('div', {
  name: 'PlpDescriptionFaqs',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: theme.spacing(5, 0, 5),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 0, 5),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 20),
  },

  [theme.breakpoints.up(1280)]: {
    padding: theme.spacing(0),
  },
}))
