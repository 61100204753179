import { Link } from '@components/common/Link/Link'
import { styled } from '@mui/material/styles'

export const StyledLinkListItem = styled(Link, {
  shouldForwardProp: prop => prop !== 'isAccented',
})<{ isAccented: boolean }>(({ theme, isAccented }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  color: isAccented ? theme.palette.primary.main : theme.palette.neutral.main,

  '&:hover': {
    textDecoration: 'underline',
    color: isAccented ? theme.palette.primary.main : theme.palette.neutral.main,
  },
}))
