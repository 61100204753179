import { IPlacement, IPlacementItem, isCMCollection } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'

export const getBanner = (placement: IPlacement): { viewtype: IViewType; items: IPlacementItem[] } => {
  const collection = placement.items.find(isCMCollection)
  const viewType = placement.viewtype
  if (collection) {
    return {
      viewtype: viewType === 'default' ? collection?.viewtype : viewType,
      items: collection.teasableItems,
    }
  }

  return {
    items: placement.items,
    viewtype: viewType === 'default' ? 'plp-one-tile' : viewType,
  }
}

export const getGridRow = (verticalIndex: number) => `${verticalIndex} / span 1`
