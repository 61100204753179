// @ts-nocheck
//TODO: remove the above ts nocheck when clarified the typescript error
import { getAnnualSupplyBadge } from '@utils/productAttributes'
import { useEffect, useMemo, useState } from 'react'
import { IProduct, SupplyData } from '../../../types/product'

/**
 * Object that reflects the attributes order returned by BE
 * https://luxotticaretail.atlassian.net/wiki/spaces/EECOM/pages/2113668688/Annual+Supply+Promotion#XLS-processing-flow
 */
const supplyAttributeDataConfMap: Record<number, keyof SupplyData> = {
  0: 'quantity',
  1: 'discountAmount',
  2: 'originalBoxPrice',
  3: 'discountedBoxPrice',
  4: 'timePeriod',
}

const parseSupplyDataAttribute = (attrValue: string): string[] => {
  return attrValue.split('|')
}

export const useAnnualSupply = (product?: IProduct | null): SupplyData[] => {
  const [supplyData, setSupplyData] = useState<SupplyData[]>([])
  useEffect(() => {
    const attr = getAnnualSupplyBadge(product)
    const supplyTempData: SupplyData[] = []

    if (Array.isArray(attr)) {
      attr?.map(attrItem => {
        const temp: SupplyData = {}
        parseSupplyDataAttribute(attrItem as string)?.map((supplyDataItem, i) => {
          if (!!supplyDataItem && !!supplyAttributeDataConfMap[i]) {
            temp[supplyAttributeDataConfMap[i]] = supplyDataItem
          }
        })
        supplyTempData.push(temp)
      })
    }
    setSupplyData(supplyTempData)
  }, [product])
  return supplyData
}

export const useAnnualSupplyCart = (attributes?: string[]): SupplyData[] => {
  const [supplyData, setSupplyData] = useState<SupplyData[]>([])
  useEffect(() => {
    const supplyTempData: SupplyData[] = []

    if (Array.isArray(attributes)) {
      attributes?.map(attrItem => {
        const temp: SupplyData = {}
        parseSupplyDataAttribute(attrItem as string)?.map((supplyDataItem, i) => {
          if (!!supplyDataItem && !!supplyAttributeDataConfMap[i]) {
            temp[supplyAttributeDataConfMap[i]] = supplyDataItem
          }
        })
        supplyTempData.push(temp)
      })
    }
    setSupplyData(supplyTempData)
  }, [])
  return supplyData
}

export const useAnnualSupplyPLP = (product?: IProduct): SupplyData[] => {
  const [supplyData, setSupplyData] = useState<SupplyData[]>([])
  useEffect(() => {
    const attr = getAnnualSupplyBadge(product)
    const splitAttr = typeof attr === 'string' ? attr.split() : []
    const supplyTempData: SupplyData[] = []
    if (Array.isArray(splitAttr)) {
      splitAttr?.map(attrItem => {
        const temp: SupplyData = {}
        parseSupplyDataAttribute(attrItem as string)?.map((supplyDataItem, i) => {
          if (!!supplyDataItem && !!supplyAttributeDataConfMap[i]) {
            temp[supplyAttributeDataConfMap[i]] = supplyDataItem
          }
        })
        supplyTempData.push(temp)
      })
    }
    setSupplyData(supplyTempData)
  }, [])
  return supplyData
}

export const useAnnualSupplyPerBoxQnt = (supplyData: SupplyData[], totalBoxes: number) => {
  const selectedSupplyPromo = useMemo(() => {
    const sortedSupplyData = [...supplyData].sort((a, b) =>
      parseFloat(a?.quantity || '0') < parseFloat(b?.quantity || '0') ? -1 : 1
    )
    const [minTimePeriod, maxTimePeriod] = sortedSupplyData
    const minQnt = parseFloat(minTimePeriod?.quantity || '0')
    const maxQnt = parseFloat(maxTimePeriod?.quantity || '0')

    if (totalBoxes >= minQnt && totalBoxes < maxQnt) return minTimePeriod
    if (totalBoxes >= maxQnt) return maxTimePeriod
    return undefined
  }, [supplyData, totalBoxes])

  return selectedSupplyPromo
}
