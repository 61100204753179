import { Button } from '@components/UI/Button'
import { Pill } from '@components/UI/Pill'
import { ProductMedia } from '@components/common/Media/ProductMedia'
import styled from '@mui/material/styles/styled'
import { CSSProperties } from '@mui/styled-engine'
import { Z_INDEX_LV2 } from '../../../../constants/ui'

export const StyledStickyCta = styled('div', {
  name: 'PDPStyckyBar',
  slot: 'StickyCta',
  shouldForwardProp: prop => prop !== 'visible',
})<{
  visible?: boolean
}>(({ theme, visible }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100vw',
  height: theme.spacing(12),
  display: visible ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 4),
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.light.primary,
  color: theme.palette.text.dark.primary,
  transition: 'opacity 0.1s ease-in-out',

  '.pdp__cta-wrapper button': {
    height: theme.spacing(10),
  },

  '.media-wrapper': {
    flexShrink: 0,
    padding: 0,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 170,
      height: 88,
      display: 'block',
    },
  },

  [theme.breakpoints.up('sm')]: {
    height: 88,
    padding: theme.spacing(1, 8, 1, 4.25),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 16),
  },
}))

export const StyledInfoProductContainer = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),

    h5: {
      margin: 0,

      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43,
    },

    h6: {
      margin: 0,
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 1.33,
    },
  },
}))

export const StyledProductImage = styled(ProductMedia, {
  name: 'PDPStickyBar',
  slot: 'ProductMedia',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    backgroundColor: theme.palette.background.default,
  },
}))

export const StyledImageAndPriceContainer = styled('div', {
  name: 'PDPStickyBar',
  slot: 'ImageAndPriceContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(4.5),
  lineHeight: 0,

  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4),
    width: 'auto',
  },

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}))

export const StyledFrameContainer = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 600,
  lineHeight: 1.33,

  textTransform: 'uppercase',

  flexBasis: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(4),
    lineHeight: 1.5,

    textTransform: 'capitalize',
  },
}))

export const StickyBarPriceContainer = styled('div', {
  name: 'PDPStickyBar',
  slot: 'PriceContainer',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(2),
  },

  h5: {
    margin: 0,

    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: 1.33,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(4),
      lineHeight: 1.5,
    },
  },

  h6: {
    margin: 0,

    textDecoration: 'line-through',
    fontSize: theme.spacing(2.5),
    fontWeight: 'normal',
    lineHeight: 1.6,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(3.5),
      lineHeight: 1.43,
    },
  },
}))

export const StickyBarPriceWrapper = styled('div', {
  name: 'PDPStickyBar',
  slot: 'PriceWrapper',
  shouldForwardProp: p => p !== 'flexDirection',
})<{
  flexDirection: CSSProperties['flexDirection']
}>(({ flexDirection, theme }) => ({
  display: 'flex',
  flexDirection: flexDirection,
  columnGap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
  },
}))

export const StyledSelectLensesCTA = styled(Button)(({ theme }) => ({
  minWidth: 163,
  height: 40,
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

export const StyledRoundPill = styled(Pill, {
  shouldForwardProp: prop => prop !== 'clicked',
  name: 'PDPStickyBar',
  slot: 'RoundPill',
})<{ clicked: boolean }>(({ theme, clicked }) => ({
  border: 'none',
  padding: theme.spacing(1),
  backgroundColor: clicked ? theme.palette.background.dark.quaternary : theme.palette.background.light.secondary,

  '&:hover': {
    backgroundColor: theme.palette.background.dark.quaternary,
  },
}))
