import { Link } from '@components/common/Link/Link'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useInstantSearch, useRange, useRefinementList } from 'react-instantsearch'
import { MultipleQueriesQuery, SearchResponse } from '@algolia/client-search'
import { SORTING_NOT_CLUSTERED } from '@constants/common'
import { SEARCH } from '@constants/routes'
import {
  ATTRIBUTES_TRANSLATED,
  INDICES_MAP,
  clearAlgoliaEventParameters,
  sendPlpEvent,
  storeAlgoliaEventParameters,
} from '@features/plp/algoliaUtils'
import { IUiState } from '@features/ui/slice'
import { algoliaEventTrigger, initIndexName } from '@foundation/algolia/algoliaConfig'
import { getUserToken } from '@foundation/hooks/useAnalyticsData'
import { useDynamicCmsContentPlpAlgolia } from '@foundation/hooks/useDynamicCmsContent'
import useBreakpoints from '@hooks/useBreakpoints'
import { useProductParams } from '@hooks/useProductParams'
import { useDeviceType } from '@hooks/useDeviceType'
import { Box } from '@mui/system'
import { SearchClient } from 'algoliasearch'
import { FACETS_LIMIT } from '../../configs/catalog'
import BottomSEOBlock from './components/BottomSEOBlock'
import PlpHeaderAlgolia from './components/PlpHeader/PlpHeaderAlgolia'
import { ProductGridViewAlgolia } from './components/ProductGridView'
import TopSEOBlock from './components/TopSEOBlock'
import usePlpPlacements from './usePlpPlacements'
// TODO RESTORE import { useFrameGenius } from '../FrameGenius/FrameGeniusContext'
// TODO RESTORE import SizeAdvisorUtil from '@utils/FrameGenius/SizeAdvisorUtil'
import PlacementLayout from '@components/Cms/PlacementLayout'
import PlpDescription from '@components/PlpDescription/PlpDescription'
import { ICategory } from '@features/category/query'
import { sendSearchResultEvent } from '@foundation/analytics/tealium/lib'
import { ICommerceHclPage } from '@typesApp/cms'
import { IAlgoliaHit } from '@typesApp/product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { teaserPropsByView } from '@utils/placements'
import { mapMonetateProducts, trackProductsPLP } from '@foundation/monetate/lib'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { getProductPriceByCustomerSegments } from '@components/common/UI/ProductPrice/utils/utils'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'

const VirtualRefinementList: React.FC<{ attribute: string }> = ({ attribute }) => {
  useRefinementList({ attribute: attribute, operator: 'or' })
  return null
}

const VirtualRangeSlider: React.FC<{ customerSegment: string }> = ({ customerSegment }) => {
  useRange({ attribute: `sort.price.${customerSegment}` })
  return null
}

const VirtualRange: React.FC<{ attribute: string }> = ({ attribute }) => {
  useRange({ attribute })
  return null
}

const PLP_PLACEMENT_KEY = 'PLP_placement_'
const HINGE_DISTANCE_RANGE = `${ATTRIBUTES_TRANSLATED}HINGE_DISTANCE_RANGE`

interface ProductGridProps {
  cid: string
  categoryId?: string
  searchTerm?: string
  searchClient: SearchClient
  categoryFilter?: string
  categoryData?: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  plpCommerce?: ICommerceHclPage | undefined
}

const getResultsWithPrice = (results: IAlgoliaHit[], customerSegments: string[]): IAlgoliaHit[] => {
  return results.map(list => {
    const cluster = list.cluster?.map(cluster => ({
      ...cluster,
      price: getProductPriceByCustomerSegments(cluster.prices, customerSegments),
    }))

    return {
      ...list,
      cluster,
    }
  })
}

/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */
const ProductGridLayoutAlgolia: React.FC<ProductGridProps> = ({
  cid,
  searchTerm = '',
  searchClient,
  categoryFilter = '',
  categoryData,
  plpCommerce,
}) => {
  const { t } = useTranslation()
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const { results, status, indexUiState } = useInstantSearch()
  const [isCatalogLoading, setIsCatalogLoading] = useState<boolean>(
    plpState.firstLoad ? status === 'loading' || status === 'stalled' : false
  )
  const [isComponentRendered, setComponentRendered] = useState(false)
  const [isViewEventDone, setIsViewEventDone] = useState<boolean>(false)

  const { nbHits, __isArtificial, queryID, hits, index } = results
  const seoData = plpState.seoData
  const sortOption = useMemo(
    () => plpState.sortOrderOptions.find(x => x.value === indexUiState.sortBy),
    [indexUiState, plpState.sortOrderOptions]
  )
  const selectedSortOption = useMemo(() => (sortOption?.id ? String(sortOption?.id) : '0'), [sortOption])
  const customerSegments = useCustomerSegmentsUtil()
  const [productList, setProductList] = useState(getResultsWithPrice(plpState.ungroupedHits, customerSegments))

  const { isDesktop } = useBreakpoints()
  const deviceType = useDeviceType()
  const monetateLoaded = listenMonetateQ()
  const suggestedKeywords = []
  const productsMobileListingLayout = plpState.productsMobileListingLayout
  const { generateParameters } = useProductParams()
  const parameters = useMemo(() => generateParameters(customerSegments[0]), [customerSegments, generateParameters])

  const { placementsPlpCommerce, plpDescription } = usePlpPlacements({ plpCommerce })
  let plpPlacements = placementsPlpCommerce?.filter(placement => placement.name.includes(PLP_PLACEMENT_KEY))

  if (indexUiState.page) {
    plpPlacements = plpPlacements.filter(placement => placement?.name !== 'PLP_placement_1')
  }

  const productListingLayout: IUiState['productsMobileListingLayout'] =
    productsMobileListingLayout === 'full' && !isDesktop ? 'full' : 'compact'
  const categoryDescription = categoryData ? categoryData[categoryData.length - 1].description : ''
  const categoryLongDescription = categoryData ? categoryData[categoryData.length - 1].longDescription : ''
  const headerTitle = indexUiState.query ? indexUiState.query : categoryDescription

  const bestsellerClusterCheck = el => el === '0'
  const currentOffset = indexUiState.page ?? 0

  const querySearchTerm = indexUiState.query ?? ''
  // TODO const originalSearchTerm = plpState.searchParams?.get(ORIGINALSEARCHTERM) ?? ''
  useEffect(() => {
    setComponentRendered(true)
  }, [])

  useEffect(() => {
    if (!isCatalogLoading && productList?.length > 0 && !isViewEventDone && isComponentRendered) {
      if (!querySearchTerm && deviceType) {
        sendPlpEvent({
          common: {
            ...plpState.analyticsData,
            Page_DeviceType: deviceType,
          },
          qnt: nbHits,
          products: productList,
          pageSection: seoData?.identifier || '',
        })
        setIsViewEventDone(true)
      }
    }
  }, [isCatalogLoading, querySearchTerm, productList, isViewEventDone, deviceType, isComponentRendered])

  useEffect(() => {
    if (!isCatalogLoading && productList?.length > 0) {
      if (querySearchTerm && deviceType) {
        sendSearchResultEvent({
          common: {
            ...plpState.analyticsData,
            Page_DeviceType: deviceType,
          },
          qnt: nbHits,
          products: productList as any[],
          pageSection: seoData?.identifier || '',
          searchKeyword: querySearchTerm,
          searchKeyActual: '',
        })
      }
    }
  }, [isCatalogLoading, querySearchTerm, productList.length, deviceType])

  useEffect(() => {
    if (!isCatalogLoading && productList?.length > 0 && monetateLoaded) {
      trackProductsPLP(mapMonetateProducts(productList))
    }
  }, [isCatalogLoading, querySearchTerm, productList.length, monetateLoaded])

  const brandNameRough = seoData?.tokenExternalValue?.split('_').pop() || ''
  const brandName = decodeURI(brandNameRough)

  const isClustered =
    (!!indexUiState.sortBy || bestsellerClusterCheck(selectedSortOption)) &&
    !SORTING_NOT_CLUSTERED.includes(String(selectedSortOption))

  const showPlacements = plpPlacements.every(placement =>
    placement.items.every(item => {
      const { teaserTitle, teaserText } = teaserPropsByView(placement.viewtype || 'default')
      return !(item?.media?.length === 0 && item[teaserTitle] === '' && item[teaserText] === '')
    })
  )

  const langCountry = plpState.locale

  const dynamicContentBanners = useDynamicCmsContentPlpAlgolia(placementsPlpCommerce, plpState.appliedFacets)

  const fetchUngroupedProducts = hits => {
    const { sortBy } = parameters
    const ungroupedIndexName: string = initIndexName({
      locale: langCountry,
      sortOption: sortBy ? Number(sortBy) : INDICES_MAP.BEST_SELLERS,
      isGrouped: false,
      customerSegment: customerSegments[0],
    })

    const facetFilters = new URLSearchParams(results['params']).get('facetFilters')

    const queries: MultipleQueriesQuery[] = hits.map(({ x_groupkey }) => ({
      indexName: ungroupedIndexName,
      query: searchTerm,
      params: {
        filters: `${categoryFilter ? `${categoryFilter} AND ` : ''}x_groupkey:"${x_groupkey}"`,
        facetFilters,
      },
    }))

    if (queries.length) {
      searchClient
        .multipleQueries<IAlgoliaHit>(queries)
        .then(data => {
          const hitsWithClustersAndPrices = data.results.map(resultResponse => {
            const result = resultResponse as SearchResponse<IAlgoliaHit>
            const hitsWithPrices = result.hits.map<IAlgoliaHit>((hit: IAlgoliaHit) => ({
              ...hit,
              price: getProductPriceByCustomerSegments(hit.prices, customerSegments) ?? null,
            }))

            const firstProduct = result.hits[0]
            return {
              ...firstProduct,
              cluster: hitsWithPrices,
            }
          })

          setProductList(hitsWithClustersAndPrices)
          storeAlgoliaEventParameters({
            type: 'ungrouped',
            indexName: ungroupedIndexName,
            queryID: results.queryID,
          })
          return hitsWithClustersAndPrices
        })
        .then(hitsWithClustersAndPrices => {
          algoliaEventTrigger('sendEvents', [
            {
              eventType: 'view',
              userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
              eventName: 'algolia_ungrouped_seach_result',
              index: ungroupedIndexName,
              objectIDs: hitsWithClustersAndPrices.map(hit => hit.objectID),
              queryID: results.queryID,
            },
          ])
        })
        .catch(() => {
          setProductList(hits)
        })
    }
    setIsCatalogLoading(false)
  }
  useEffect(() => {
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'])
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'], 'grouped')
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'], 'ungrouped')
  }, [])

  useEffect(() => {
    if (isClustered) {
      fetchUngroupedProducts(hits)
    }
  }, [isClustered, plpState.shouldLoadUngrouped])

  useEffect(() => {
    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned yet.
    if (!__isArtificial && (plpState.firstLoad || !plpState.openDrawerFilters)) {
      if (queryID && index) {
        storeAlgoliaEventParameters({
          type: 'grouped',
          indexName: index,
          queryID: queryID,
        })
      }

      if (isClustered) {
        fetchUngroupedProducts(hits)
        plpDispatch({
          type: 'SET_MULTIPLE',
          payload: {
            shouldLoadUngrouped: false,
            firstLoad: false,
          },
        })
        if (hits.length > 0) {
          const splittedHits = hits.reduce((all, one, i) => {
            const ch = Math.floor(i / FACETS_LIMIT)
            all[ch] = [].concat(all[ch] || [], one)
            return all
          }, [])

          splittedHits.forEach(sh => {
            algoliaEventTrigger('sendEvents', [
              {
                eventType: 'view',
                userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
                eventName: 'algolia_grouped_seach_result',
                index,
                objectIDs: sh.map(hit => hit.objectID),
                queryID: queryID,
              },
            ])
          })
        } else if (results.getRefinements().length > 0) {
          algoliaEventTrigger('sendEvents', [
            {
              eventType: 'view',
              userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
              eventName: 'algolia_grouped_seach_result',
              index,
              queryID: queryID,
              filters: results.getRefinements().map(filter => filter.attributeName + ':' + filter.name),
            },
          ])
        }
      } else {
        if (hits.length > 0) {
          const hitsWithPrices = hits.map(hit => ({
            ...hit,
            price: getProductPriceByCustomerSegments(hit.prices, customerSegments),
          }))
          setIsCatalogLoading(false)
          setProductList(hitsWithPrices)
        }
      }

      if (results.getRefinements().length > 0) {
        sessionStorage.setItem(
          'PLPFiltersApplied',
          JSON.stringify(results.getRefinements().map(filter => filter.attributeName + ':' + filter.name))
        )
      }
    }
  }, [results, plpState.shouldLoadUngrouped])

  return (
    <>
      {Object.keys(indexUiState.refinementList || { fake_refinement_attribute: 'fake_refinement_attribute' }).map(
        refinementList => (
          <VirtualRefinementList key={refinementList} attribute={refinementList} />
        )
      )}
      <VirtualRangeSlider customerSegment={customerSegments[0]} />
      <VirtualRange attribute={HINGE_DISTANCE_RANGE} />

      <PlpHeaderAlgolia
        title={headerTitle}
        catalogLoading={isCatalogLoading}
        searchTerm={searchTerm || ''}
        category={categoryData}
        productData={results}
        isPlacementsBetween={showPlacements}
      />
      <TopSEOBlock
        isEnabled={plpState.isPlpSeoEnabled.top}
        currentOffset={currentOffset}
        brandName={brandName}
        title={categoryDescription}
        description={categoryLongDescription || ''}
      />
      <ProductGridViewAlgolia
        isClustered={isClustered}
        isCatalogLoading={isCatalogLoading}
        products={productList}
        productsCount={nbHits}
        dynamicContentBanners={dynamicContentBanners}
        productListingLayout={productListingLayout}
      >
        {!isCatalogLoading && nbHits === 0 && !!searchTerm && (
          <Box paddingX="16px" className="suggested-keywords">
            <h4>
              {t('ProductGrid.Labels.noMatches', {
                searchTerm: searchTerm.replace('*', ''),
              })}
            </h4>
            <p>
              {t('ProductGrid.Labels.searchAgain', {
                searchTerm: searchTerm.replace('*', ''),
              })}
            </p>
            {suggestedKeywords.length > 0 && (
              <>
                {t('ProductGrid.Labels.suggestion')}
                <br />
                {suggestedKeywords?.map((keyword: string, index: number) => (
                  <Link
                    key={keyword}
                    href={SEARCH + '?query=' + keyword}
                    onClick={() => {}}
                    className="suggestion-link"
                    id={`productGrid_a_22_${index}_${cid}`}
                    locale={plpState.locale.replace('_', '-').toLowerCase()}
                    isLocaleDomain={plpState.isLocaleDomain}
                    locationOrigin={plpState.locationOrigin}
                  >
                    {keyword} <br />
                  </Link>
                ))}
              </>
            )}
          </Box>
        )}
      </ProductGridViewAlgolia>
      {showPlacements && <PlacementLayout placements={plpPlacements || []} loading={false} />}
      {plpDescription.numFound > 0 && <PlpDescription placements={plpDescription.result} />}

      <BottomSEOBlock
        isEnabled={plpState.isPlpSeoEnabled.bottom}
        title={categoryDescription}
        description={categoryLongDescription || ''}
      />
    </>
  )
}

//TODO RESTORE ? export default trackWindowScroll(ProductGridLayoutAlgolia)
export default ProductGridLayoutAlgolia
