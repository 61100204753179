import Link from '@mui/material/Link'
import { StyledLink } from './TopReasons.style'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'
import { replaceLocaleInUrl } from '@utils/locale'

export const renderLink = (href: string, name?: string, linkType = 'styled') => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const linkProps = {
    href: replaceLocaleInUrl({ isLocaleDomain, locationOrigin, locale, href }),
    ...(name ? { dataName: name } : {}),
  }

  return linkType === 'styled' ? (
    <StyledLink {...linkProps}>Link</StyledLink>
  ) : (
    <Link variant="caption" {...linkProps}>
      Link
    </Link>
  )
}
