import React, { FC, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ProductTile, ProductTileLoading } from '../../../ProductTile'
import {
  ProductsWrapper,
  StyledGridProductLayout,
  StyledProductGridContainer,
  StyledProductWrap,
  StyledProductsCount,
} from './ProductGridView.style'

import { GridItem } from '@components/UI/Grid'
import { PAGINATION_CONFIGS } from '@configs/catalog'
import { lastSelectedProductSelector } from '@features/ui/selector'
import { IUiState } from '@features/ui/slice'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IProduct } from '@typesApp/product'
import { isCMCollection } from '@typesApp/teaser'
import { useTranslation } from 'next-i18next'
import { ProductTileProps } from '../../../ProductTile/ProductTile'
import { useSearchParams } from 'next/navigation'
import { CmsGridBannerTiles } from '@components/common/components/CmsGridBannerTiles'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

const { pageLimit } = PAGINATION_CONFIGS

interface ProductGridViewProps {
  dynamicContentBanners?: IPlacement[]
  plpPlacements?: IPlacement[]
  isCatalogLoading: boolean
  isClustered?: boolean
  products: IProduct[]
  productsCount: number
  productTileProps?: Partial<ProductTileProps>
  variant?: string
  productListingLayout?: IUiState['productsMobileListingLayout']

  children?: React.ReactNode
}

const filterPlpPlacements = (placements: IPlacement[]) => {
  const listViewTypeControl = ['plp-two-tiles', 'default']
  return (
    placements?.filter(placement => {
      if (placement.viewtype === 'default') {
        const collection = placement.items.find(isCMCollection)
        return listViewTypeControl.includes(collection?.viewtype!)
      } else return listViewTypeControl.includes(placement.viewtype!)
    }) || []
  )
}

/**
 * `@function ProductGridView`
 *
 * Component for a view-purpose only.
 * Accepts props for displaying products, pagination, 'load more' action.
 * Doesn't perform server calls on its own, uses callbacks to interact with layer responsible
 * for fetching and formatting data.
 */
const ProductGridView: FC<ProductGridViewProps> = ({
  dynamicContentBanners,
  children,
  isCatalogLoading,
  isClustered,
  products,
  productsCount,
  productTileProps,
  variant,
  productListingLayout,
}) => {
  const { t } = useTranslation()
  const query = useSearchParams()
  const lastSelectedProduct = useSelector(lastSelectedProductSelector)
  const selectedProduct = useRef<HTMLDivElement | null>(null)
  const bannerPlpPlacements = filterPlpPlacements(dynamicContentBanners!)
  const pageCountTotal = useMemo<number>(() => Math.ceil(productsCount / pageLimit), [productsCount])
  const customerSegments = useCustomerSegmentsUtil()

  const currentOffset = Number(query?.get('offset')) ?? 0
  const bannersEnabled = currentOffset / PAGINATION_CONFIGS.pageLimit + 1 === 1

  return (
    <ProductsWrapper data-element-id="X_X_Tiles">
      {children}

      <StyledProductGridContainer>
        {isCatalogLoading && (
          <>
            {new Array(products?.length === 0 ? 8 : products?.length).fill(1).map((_, index) => {
              return (
                <StyledGridProductLayout key={index}>
                  <StyledProductWrap>
                    <ProductTileLoading />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              )
            })}
          </>
        )}

        {!isCatalogLoading && productsCount > 0 ? (
          <>
            {products.map((product, index) => {
              if (!product) {
                return <></>
              }
              return (
                <StyledGridProductLayout
                  key={product.id}
                  ref={!selectedProduct.current && lastSelectedProduct === product.id ? selectedProduct : undefined}
                >
                  <StyledProductWrap id={`product_${product.id}`}>
                    <ProductTile
                      isClustered={isClustered}
                      product={product}
                      tileIndex={index}
                      {...productTileProps}
                      variant={variant ? variant : 'plp'}
                    />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              )
            })}

            {bannersEnabled &&
              bannerPlpPlacements?.map((item, index) => {
                const controlBanner = 3
                const numBanners = Math.floor(products.length / controlBanner)
                if (index >= numBanners) return null
                return (
                  <CmsGridBannerTiles
                    key={index}
                    data-element-id={`X_PLP_${item.name}_${item.items?.length ?? 0}-Banner`}
                    placement={item}
                    customerSegments={customerSegments}
                    productGridLayout={productListingLayout}
                  />
                )
              })}
          </>
        ) : (
          <>{productsCount === 0 && <GridItem xs={12}>{t('ProductGrid.Labels.noProductsFoundForFilter')}</GridItem>}</>
        )}
      </StyledProductGridContainer>
      {!isCatalogLoading && pageCountTotal > 1 && (
        <StyledProductsCount>{/* <PaginationLayout totalPages={pageCountTotal} /> */}</StyledProductsCount>
      )}
    </ProductsWrapper>
  )
}

export default ProductGridView
