import { IUiState } from '@features/ui/slice'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import clsx from 'clsx'
import { CmsBannerTile } from '../CmsBannerTile'
import { getBanner, getGridRow } from './helpers'
import styles from './styles/index.module.scss'

type TGridBannerTiles = {
  placement: IPlacement
  customerSegments: string[]
  productGridLayout?: IUiState['productsMobileListingLayout']
}

export const CmsGridBannerTiles: React.FC<TGridBannerTiles> = ({ placement, customerSegments, productGridLayout }) => {
  const { items, viewtype } = getBanner(placement)
  const item = items.find(isLXTeaser)

  if (!item) return null

  if (!['plp-one-tile', 'plp-two-tiles'].includes(viewtype ?? '')) {
    return null
  }

  const formattedPlacement: IPlacement = {
    ...placement,
    viewtype,
    items,
  }

  const gridPositioning = item.gridPositioning ?? 1
  const split = placement.name.split('_')
  const verticalIndex = productGridLayout === 'full' ? gridPositioning : Number(split.pop()) ?? 1

  return (
    <div
      className={clsx(
        styles['cms-grid-banner-tiles'],
        styles[`cms-grid-banner-tiles-${viewtype}`],
        styles[`cms-grid-banner-tiles-${viewtype}-${productGridLayout}`]
      )}
      style={{
        gridRow: getGridRow(verticalIndex),
      }}
    >
      <CmsBannerTile placement={formattedPlacement} customerSegments={customerSegments} />
    </div>
  )
}
