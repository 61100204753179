import { gridColumns } from '../../../constants/ui'
import styled from '@mui/material/styles/styled'

/**
 * Grid container component based on css grid
 * @param {1 | 0} gap param to set gap container
 */

const GridContainer = styled('div')<{
  gap?: 1 | 0
  columns?: number
}>(({ columns, gap, theme }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns || gridColumns['xs']}, 1fr)`,
  gridTemplateRows: '1fr',
  gridColumnGap: gap === 1 ? '1rem' : 0,
  gridRowGap: gap === 1 ? '1rem' : 0,
  gridColumn: `span ${columns || gridColumns['xs']}`,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(${columns || gridColumns['md']}, 1fr)`,
    gridColumn: `span ${columns || gridColumns['md']}`,
    gridColumnGap: gap === 1 ? '1.5rem' : 0,
    gridRowGap: gap === 1 ? '1.5rem' : 0,
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: `repeat(${columns || gridColumns['lg']}, 1fr)`,
    gridColumn: `span ${columns || gridColumns['lg']}`,
    gridColumnGap: gap === 1 ? '2rem' : 0,
    gridRowGap: gap === 1 ? '2rem' : 0,
  },
  width: '100%',
}))

export default GridContainer
