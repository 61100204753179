import { PAGINATION_CONFIGS } from '@configs/catalog'
import { algoliaClient } from '@foundation/algolia/algoliaConfig'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import getRouting from '@utils/Plp/AlgoliaRouting'
import { useMemo } from 'react'
import { ConfigureProps, InstantSearchSSRProvider, InstantSearchServerState } from 'react-instantsearch'
import { TAlgoliaInit, TAlgoliaInitChildren } from '../types'
import { AlgoliaInit } from './Init'

type TAlgoliaProductListInit = {
  plpInitParams: {
    groupedIndexName: string
    serverUrl: string
    locale: string
    algoliafilterCategory?: string
    configure: ConfigureProps
  }
} & TAlgoliaInitChildren
export const AlgoliaProductListInit: React.FC<TAlgoliaProductListInit> = ({ children, plpInitParams }) => {
  const { groupedIndexName, serverUrl, locale, configure } = plpInitParams
  const customerSegment = useCustomerSegmentsUtil()[0]
  const { pageLimit } = PAGINATION_CONFIGS

  const routing = getRouting(groupedIndexName, serverUrl, customerSegment, locale)

  const props: TAlgoliaInit = useMemo(
    () => ({
      routing,
      indexNameDefault: groupedIndexName,
      searchClientDefault: algoliaClient,
      configure: {
        facets: ['*'],
        hitsPerPage: pageLimit,
        clickAnalytics: true,
        ...configure,
      },
    }),
    [configure, groupedIndexName, pageLimit, routing]
  )

  return <AlgoliaInit {...props}>{children}</AlgoliaInit>
}

type TWithSSR = {
  plpSSRInitParams?: InstantSearchServerState
}

const withSSR =
  <P,>(Component: React.ComponentType<P>): React.FC<P & TWithSSR> =>
  props => {
    return (
      <InstantSearchSSRProvider {...props.plpSSRInitParams}>
        <Component {...props} />
      </InstantSearchSSRProvider>
    )
  }

export const AlgoliaProductListInitSSR = withSSR(AlgoliaProductListInit)
