const SET_FILTER_PRICE_RANGE = 'SET_FILTER_PRICE_RANGE'
const SET_SELECTED_FACETS = 'SET_SELECTED_FACETS'
const SET_CURRENT_PLP_FACETS = 'SET_CURRENT_PLP_FACETS'
const SET_HINGES_RANGE_FACET = 'SET_HINGES_RANGE_FACET'
const SET_SIZE_ADVISOR_STATUS = 'SET_SIZE_ADVISOR_STATUS'

export type IPlpFilterAlgoliaState = {
  selectedFacets: {
    [x: string]: string[]
  }
  currentPlpFacets: Record<string, Record<string, number>>
  suggestedPlpFacets: { key: string; value: string[] }[]
  hingesRangeFacet: string[]
  priceRange: {
    values: [number, number]
    isOpen: boolean
    shouldRender: boolean
  }
  isSizeAdvisorActive: boolean
}

type IActionSetFilterPriceRange = {
  type: typeof SET_FILTER_PRICE_RANGE
  payload: Partial<IPlpFilterAlgoliaState['priceRange']>
}

type IActionSetSelectedFacets = {
  type: typeof SET_SELECTED_FACETS
  payload: IPlpFilterAlgoliaState['selectedFacets']
}

type IActionSetCurrentPlpFacets = {
  type: typeof SET_CURRENT_PLP_FACETS
  payload: Pick<IPlpFilterAlgoliaState, 'currentPlpFacets' | 'suggestedPlpFacets'>
}

type IActionSetHingesRangeFacet = {
  type: typeof SET_HINGES_RANGE_FACET
  payload: IPlpFilterAlgoliaState['hingesRangeFacet']
}

type IActionSetSizeAdvisorStatus = {
  type: typeof SET_SIZE_ADVISOR_STATUS
  payload: IPlpFilterAlgoliaState['isSizeAdvisorActive']
}

type IActionType =
  | IActionSetFilterPriceRange
  | IActionSetSelectedFacets
  | IActionSetCurrentPlpFacets
  | IActionSetHingesRangeFacet
  | IActionSetSizeAdvisorStatus

export default function plpFilterAlgoliaReducer(
  state: IPlpFilterAlgoliaState,
  action: IActionType
): IPlpFilterAlgoliaState {
  switch (action.type) {
    case SET_FILTER_PRICE_RANGE:
      return {
        ...state,
        priceRange: {
          ...state.priceRange,
          ...action.payload,
        },
      }
    case SET_SELECTED_FACETS:
      return {
        ...state,
        selectedFacets: action.payload,
      }
    case SET_CURRENT_PLP_FACETS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_HINGES_RANGE_FACET:
      return {
        ...state,
        hingesRangeFacet: action.payload,
      }
    case SET_SIZE_ADVISOR_STATUS:
      return {
        ...state,
        isSizeAdvisorActive: action.payload,
      }
    default:
      return state
  }
}
