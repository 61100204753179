import { IProduct } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { plpBadgesSelector } from '../../../../redux/selectors/site'
import { getBadges } from '../../../../utils/productAttributes'
import {
  ProductAttributeBadgesContainer,
  ProductPrimaryAttributeBadge,
  ProductSecondaryAttributeBadges,
} from './ProductAttributeBadges.style'

interface ProductAttributeBadgesProps {
  productAttributes: IProduct['productAttributes']
}

/**
 * Placed on top of product's image.
 * Ref: https://app.zeplin.io/project/61f4249b56c939b46fd74a49/screen/62062a268ec2a2114a086cb4
 */
const ProductAttributeBadges: FC<ProductAttributeBadgesProps> = ({ productAttributes }) => {
  const { t } = useTranslation()
  const { isRXEnabled } = useStoreIdentity()
  const plpBadges = useSelector(plpBadgesSelector)

  const { primaryBadge, secondaryBadges } = getBadges({ productAttributes }, t, plpBadges, isRXEnabled)

  return primaryBadge || secondaryBadges ? (
    <ProductAttributeBadgesContainer>
      {primaryBadge && <ProductPrimaryAttributeBadge>{primaryBadge}</ProductPrimaryAttributeBadge>}

      {secondaryBadges && <ProductSecondaryAttributeBadges>{secondaryBadges}</ProductSecondaryAttributeBadges>}
    </ProductAttributeBadgesContainer>
  ) : null
}

export default ProductAttributeBadges
