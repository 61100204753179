import MuiRadioGroup from '@mui/material/RadioGroup'
import { formControlLabelClasses as MuiFormControlClasses, radioClasses as MuiRadioClasses } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const RadioGroup = styled(MuiRadioGroup)(({ row, theme }) => ({
  [`& .${MuiFormControlClasses.root}`]: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginBottom: !row ? 24 : 0,
    padding: 0,
  },
  [`& .${MuiRadioClasses.root}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: theme.spacing(1, 3, 0, 0),
    margin: 0,
  },
}))

export const RadioDescription = styled('div', {
  name: 'StyledRadio',
  slot: 'Description',
})(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  marginLeft: theme.spacing(7),
}))
