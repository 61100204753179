import { useEffect } from 'react'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface Item {
  [key: string]: string | unknown
}

const useDataLayerExtendedEvent = (items: Item[]) => {
  useEffect(() => {
    if (items && typeof window !== 'undefined' && window?.hasOwnProperty('dataLayer')) {
      window.dataLayer.push(['event', 'view_item_extended', items?.[0]])
    }
  }, [items])
}

export default useDataLayerExtendedEvent
