import React, { PropsWithChildren, createContext, useContext, useState } from 'react'

type ContextType = {
  zoomModalOpen: 1 | 0
  setZoomModalOpen: (value: 1 | 0) => void
}

const _throw = (m: string) => {
  throw new Error(m)
}

const ProductImageZoomContext = createContext<ContextType | undefined>(undefined)

const ProductImageZoomProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [zoomModalOpen, setZoomModalOpen] = useState<1 | 0>(0)

  return (
    <ProductImageZoomContext.Provider
      value={{
        setZoomModalOpen,
        zoomModalOpen,
      }}
    >
      {children}
    </ProductImageZoomContext.Provider>
  )
}

const useProductImageZoom = () => {
  const context = useContext(ProductImageZoomContext)
  return context || _throw('Init ProductImageZoomContext first.')
}

export default ProductImageZoomProvider

export { useProductImageZoom }
