import { useState } from 'react'
import { ContactLensesAccessoriesIcon, ContactLensIcon } from '@components/UI/Icons/contactLenses'
import { GlassesCase, GlassesIcon } from '@components/UI/Icons/glasses'
import { useMenu } from 'react-instantsearch'
import { MenuItem } from 'instantsearch.js/es/connectors/menu/connectMenu'
import { TransformItems } from 'instantsearch.js/es/types/widget'

export const FACET_PRODUCT_TYPE = 'attributes.LX_SEARCH_PAGE_PRODUCT_TYPE'

const transformItems: TransformItems<MenuItem> = items => {
  return items.sort((a, b) => a.label.localeCompare(b.label))
}

interface MenuItemProps {
  items: MenuItem[]
  selectedPT: string
  getPTIcon: (type: string) => JSX.Element
  handleOnClickPT: (pt: string) => void
}

const useProductTypePillFilters = (): MenuItemProps => {
  const { items, refine } = useMenu({
    attribute: FACET_PRODUCT_TYPE,
    transformItems,
  })

  const [selectedPT, setSelectedPT] = useState<string>('')

  const getPTIcon = (type: string): JSX.Element => {
    switch (type) {
      case 'contactLenses':
        return <ContactLensIcon width={28} height={18} viewBox="0 0 25 25" />
      case 'accessories':
        return <GlassesCase width={28} height={18} viewBox="0 0 25 25" />
      case 'contactLensesAccessories':
        return <ContactLensesAccessoriesIcon width={28} height={18} viewBox="-7 0 28 24" />
      default:
        return <GlassesIcon width={28} height={18} viewBox="0 0 32 32" />
    }
  }

  const handleOnClickPT = (pt: string) => {
    setSelectedPT(selectedPT === pt ? '' : pt)
    refine(pt)
  }

  return { items, selectedPT, getPTIcon, handleOnClickPT }
}

export default useProductTypePillFilters
