import React, { Fragment, useEffect } from 'react'
import { getFacetLabel } from '@utils/productAttributesAlgolia'
import { CloseCircleIcon } from '@components/UI/Icons/close'
import { Pill } from '@components/UI/Pill'
import { useRefinementList } from 'react-instantsearch'

interface IFacetFilterPill {
  facetValues: string[]
  facetName: string
  facet: string
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
  searchTerm?: string
}

const FacetFilterPill: React.FC<IFacetFilterPill> = ({ facetValues, facetName, facet, onFacetChange, searchTerm }) => {
  const { items, refine, searchForItems } = useRefinementList({
    attribute: facet,
    operator: 'or',
    sortBy: ['count'],
    limit: facetValues.length,
  })

  useEffect(() => {
    searchForItems(searchTerm || '')
  }, [searchForItems, searchTerm])

  return (
    <>
      {facetValues.map(facetValue => {
        if (facetValue === '0') return null
        const item = items.find(item => item.value === facetValue)
        let isRefined, label

        if (item) {
          isRefined = item.isRefined
          label = getFacetLabel(item.label)
        }
        if (facetName === 'DISCOUNT') {
          const priceArray = label?.split('.')
          if (priceArray && +priceArray[1] === 0) label = priceArray[0]
          label += '% OFF'
        }
        const isDisabled = !item
        const endIcon = <CloseCircleIcon />

        return (
          <Fragment key={facetValue}>
            <Pill
              id={facetValue}
              key={facetValue}
              deleteIcon={endIcon}
              disabled={isDisabled}
              labelText={label || getFacetLabel(facetValue)}
              onClick={() => {
                onFacetChange(facetValue, label, facet, refine)
              }}
              isActive={isRefined}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default FacetFilterPill
