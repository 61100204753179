import {
  Checkbox as MuiCheckbox,
  checkboxClasses as MuiCheckboxClasses,
  svgIconClasses as MuiSvgIconClasses,
  styled,
} from '@mui/material'

const StyledMuiCheckbox = styled(MuiCheckbox, {
  name: 'Checkbox',
})(({ theme }) => ({
  [`&.${MuiCheckboxClasses.root}`]: {
    borderRadius: 1,
    width: 16,
    height: 16,
    marginRight: theme.spacing(4),

    [`& .${MuiSvgIconClasses.root}`]: {
      fill: theme.palette.common.white,
    },
  },
  [`&.${MuiCheckboxClasses.colorPrimary}`]: {
    color: theme.palette.neutral.tones[60],
  },
  '&.MuiCheckbox-colorError': {
    color: theme.palette.error.main,
  },
  [`&.${MuiCheckboxClasses.checked}`]: {
    color: theme.palette.primary.tones[100],
    background: theme.palette.primary.main,
  },
  [`&.${MuiCheckboxClasses.disabled}`]: {
    color: theme.palette.background[4],
  },
  [`&.${MuiCheckboxClasses.indeterminate}`]: {
    color: theme.palette.neutral.tones[60],
    background: theme.palette.primary.tones[100],
  },
  [`&.${MuiCheckboxClasses.checked}.${MuiCheckboxClasses.disabled}`]: {
    color: theme.palette.primary.tones[100],
    background: theme.palette.background[4],
  },
}))
export default StyledMuiCheckbox
