import { PAGINATION_CONFIGS } from '@configs/catalog'
import { getAttributesFromUrl } from '@features/plp/algoliaUtils'
import { IPlpProductArgs } from '@features/plp/query'
import { usePlpState } from '@utils/Plp/PlpContext'
import { useSearchParams } from 'next/navigation'

export const useProductParams = () => {
  const searchParams: { [key: string]: any } = useSearchParams()
  const plpState = usePlpState()
  const generateParameters = (customerSegment: string) => {
    const parameters: IPlpProductArgs = {
      offset: PAGINATION_CONFIGS.pageDefaultOffset,
      limit: PAGINATION_CONFIGS.pageLimit,
    }

    const facet = searchParams?.get('facet')
    const offset = searchParams?.get('offset')
    const page = searchParams?.get('page')
    const sortBy = searchParams?.get('sortBy')
    const priceFrom = searchParams?.get('priceFrom')
    const priceTo = searchParams?.get('priceTo')
    const discount = searchParams?.get('discount')

    //control query params
    if (facet) {
      parameters.facet = facet
    }

    if (offset) {
      parameters.offset = offset
    }

    if (page) {
      parameters.page = page
    }

    if (sortBy) {
      parameters.sortBy = sortBy
      parameters.profileName = plpState.sortOrderOptions.find(item => sortBy?.localeCompare(item.value))?.profileName
    }

    if (priceFrom) {
      parameters.priceFrom = priceFrom
    }

    if (priceTo) {
      parameters.priceTo = priceTo
    }

    if (discount) {
      parameters.discount = discount
    }

    const attributes = getAttributesFromUrl(searchParams, customerSegment)

    return { ...parameters, ...attributes }
  }

  return { generateParameters }
}
