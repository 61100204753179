import { styled } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'

export const StyledFormControlLabel = styled(FormControlLabel, {
  slot: 'Root',
  name: 'StyledFormControlLabel',
})(({ theme }) => ({
  margin: theme.spacing(0),
  justifyContent: 'flex-end',
  gap: theme.spacing(4),

  '& .MuiRadio-root': {
    display: 'none',
  },
}))
