/**
 * Function to multiply a formatted number by a given multiplier and return the result.
 * If the multiplier is 0, it returns the original amount formatted to two decimal places.
 * @param {number} amount - The amount to multiply
 * @param {number} multiplier - The multiplier to apply
 * @returns {string} - The resulting value formatted to two decimal places.
 */
export const multiplyBundleAmount = (amount: number | undefined, multiplier: number): number => {
  if (amount === undefined) {
    return 0
  }

  if (multiplier === 0) {
    return parseFloat(amount.toFixed(2))
  }
  const result = amount * multiplier
  return parseFloat(result.toFixed(2))
}
