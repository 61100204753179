import styled from '@mui/material/styles/styled'
import { gridColumns } from '../../../constants/ui'

/**
 * Grid slot component.
 * Every slot is 1 column span by default.
 * Every breakpoint column span value will be the same for all the larger breakpoints
 * unless another value is set
 * @param {boolean} fullwidth param to set the slot as sub layout container
 * @param {number} xs number of columns to set the slot width from the 'xs' breakpoint (default 1)
 * @param {number} sm number of columns to set the slot width from the 'sm' breakpoint (default 1)
 * @param {number} md number of columns to set the slot width from the 'md' breakpoint (default 1)
 * @param {number} lg number of columns to set the slot width from the 'lg' breakpoint (default 1)
 * @param {number} xl number of columns to set the slot width from the 'xl' breakpoint (default 1)
 */

const GridItem = styled('div')<{
  fullWidth?: boolean
  xs?: number | string
  sm?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
}>(({ xs, sm, md, lg, xl, theme, fullWidth }) => {
  const breakpointsMapping: Record<any, number | string | undefined> = {
    xs,
    sm,
    md,
    lg,
    xl,
  }

  const breakpointsKeys = Object.keys(breakpointsMapping)
  let activeSpanValue: number | string | undefined = undefined
  let lastActiveSpanValue: number | string | undefined = undefined
  let style = {}

  breakpointsKeys.map((breakpoint, i) => {
    const currentBreakpoint = breakpoint
    const lastBreakpoint = breakpointsKeys.slice(-1)[0]
    const nextBreakpoint = breakpointsKeys[i + 1] || lastBreakpoint
    const currentBreakpointValue = breakpointsMapping[currentBreakpoint]
    const fullWidthSpan = gridColumns[breakpoint]

    if (!!currentBreakpointValue) {
      activeSpanValue = currentBreakpointValue
      lastActiveSpanValue = activeSpanValue
    } else {
      activeSpanValue = lastActiveSpanValue || 1
    }

    if (!!activeSpanValue && ((activeSpanValue as number) > 1 || activeSpanValue === 'fullWidth')) {
      style[theme.breakpoints.down('sm')] = {
        gridColumn: `span ${breakpointsMapping['sm'] !== 'fullWidth' ? breakpointsMapping['sm'] : fullWidthSpan}`,
      }
      style[theme.breakpoints.down('xs')] = {
        gridColumn: `span ${breakpointsMapping['xs'] !== 'fullWidth' ? breakpointsMapping['xs'] : fullWidthSpan}`,
      }
      if (currentBreakpoint !== lastBreakpoint) {
        style[theme.breakpoints.between(currentBreakpoint as any, nextBreakpoint as any)] = {
          gridColumn: `span ${activeSpanValue !== 'fullWidth' ? activeSpanValue : fullWidthSpan}`,
        }
      } else {
        style[theme.breakpoints.up(lastBreakpoint as any)] = {
          gridColumn: `span ${activeSpanValue !== 'fullWidth' ? activeSpanValue : fullWidthSpan}`,
        }
      }
    } else if (fullWidth) {
      style = {
        gridColumn: `span ${fullWidthSpan}`,
      }
    } else {
      style = {
        gridColumn: 'span 1',
      }
    }
  })

  return {
    width: '100%',
    ...style,
  }
})

export default GridItem
