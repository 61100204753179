import {
  ADD_CART_ROWS,
  ADD_PRODUCT_DETAILS,
  ADD_PRODUCTS,
  ADD_PURCHASE_ROWS,
  BILLING,
  CATEGORY,
  CUSTOM_PRODUCT,
  PRODUCT,
  PURCHASE,
  SEARCH,
  SET_PAGE_TYPE,
  TRACK_DATA,
} from './pageTypes'

import Log from '../../services/Log'
import { IOrderItem, OrderItemWithRoxProps } from '../../types/order'
import { IAlgoliaHit, IProduct } from '../../types/product'
import { TProduct } from '@utils/productNew'
export interface ProductForMonetate {
  productId?: string
  purchaseId?: string
  sku?: string
  quantity?: number
  unitPrice?: string
  currency?: string
}

interface IpageTypeMap {
  key: string
  value: string
  haveProductInformation: boolean
}
const pageTypes: IpageTypeMap[] = [
  { key: 'home', value: 'main', haveProductInformation: true },
  { key: 'pdp', value: PRODUCT, haveProductInformation: true },
  { key: 'plp', value: CATEGORY, haveProductInformation: true },
  { key: 'cart', value: 'cart', haveProductInformation: true },
  { key: 'checkout', value: 'checkout', haveProductInformation: false },
  { key: 'shipping', value: 'shipping', haveProductInformation: false },
  { key: 'payment', value: BILLING, haveProductInformation: false },
  { key: 'order-confirmation', value: PURCHASE, haveProductInformation: true },
  { key: 'RemixProductDisplay', value: CUSTOM_PRODUCT, haveProductInformation: false },
  { key: 'SearchDisplay', value: SEARCH, haveProductInformation: false },
  { key: 'SearchResults', value: SEARCH, haveProductInformation: false },
  { key: 'page404', value: '404', haveProductInformation: false },
]

function mapPageType(pageType): IpageTypeMap {
  const page = pageTypes.find(p => p.key === pageType)
  return page ? page : { key: '', value: 'other_page', haveProductInformation: false }
}

function sanitizeSku(sku) {
  if (sku === undefined) {
    return ''
  }
  return sku.replace(/ /g, '_')
}

function mapPurchaseRows(orderId, productsInCart: ProductForMonetate[], promoAdjust?: string): ProductForMonetate[] {
  const purchaseRows = productsInCart.map(product => {
    const mappedRow: ProductForMonetate = {
      ...product,
      purchaseId: orderId,
    }
    return mappedRow
  })

  if (promoAdjust && parseFloat(promoAdjust) < 0.0) {
    const objectDiscount: ProductForMonetate = {
      productId: 'DISCOUNT',
      purchaseId: orderId,
      quantity: 1,
      unitPrice: promoAdjust,
      currency: purchaseRows[0].currency,
    }
    purchaseRows.push(objectDiscount)
  }
  return purchaseRows
}

export function mapOrderItemsInCart(productsInCart: IOrderItem[] | OrderItemWithRoxProps[]): ProductForMonetate[] {
  const mappedProducts = productsInCart.map(item => {
    const mappedProduct: ProductForMonetate = {
      productId: item.productId,
      quantity: parseInt(item.quantity),
      unitPrice: item.unitPrice,
      currency: item.currency,
      sku: item.partNumber,
    }
    return mappedProduct
  })

  return mappedProducts
}

export function mapMonetateProducts(products: IProduct[] | IAlgoliaHit[]): ProductForMonetate[] {
  return products.map(product => {
    return {
      productId: product?.parentProductId || product.uniqueID,
      sku: sanitizeSku(product?.parentPartnumberId || product.partNumber),
    }
  })
}

export function mapMonetateProductsHome(products: TProduct[]): ProductForMonetate[] {
  return products.map(product => {
    return {
      productId: product?.productId,
      sku: sanitizeSku(product?.partnumberId),
    }
  })
}

function mergeProductsInCart(productsInCart: ProductForMonetate[]): ProductForMonetate[] {
  const cloneProductsInCart = Array.from(productsInCart, item => ({ ...item }))
  const mergedProducts: ProductForMonetate[] = []

  cloneProductsInCart.forEach(product => {
    const existingProduct = mergedProducts.find(p => p.sku === product.sku)
    if (existingProduct) {
      existingProduct.quantity! += product.quantity!
    } else {
      mergedProducts.push(product)
    }
  })

  return mergedProducts
}

export const getMonetate = () => {
  return window.monetateQ || []
}

export const addToBag = (currentProduct: IProduct) => {
  try {
    trackSingleProduct(
      // @ts-ignore
      currentProduct,
      currentProduct.partNumber
    )
  } catch (e) {
    Log.error('MONETATE ADD TO BAG TRACK ERROR ', `${e}`)
  }
}

export const trackSingleProduct = (currentProduct: IProduct, partNumber: string, pageType?: string) => {
  try {
    const M = getMonetate()
    const sanitizedPartNumber = sanitizeSku(partNumber)
    const Product: ProductForMonetate = {
      productId: currentProduct?.uniqueID || currentProduct?.id,
      sku: sanitizedPartNumber,
    }
    setMonetatePageType(pageType)
    M.push([ADD_PRODUCT_DETAILS, [Product]])
    sendMonetateEvent()
    Log.info('MONETATE SINGLE PRODUCT', JSON.stringify(currentProduct))
  } catch (e) {
    Log.error('MONETATE SINGLE PRODUCT TACK ERROR ', `${e}`)
  }
}

export const trackProductsPLP = (products: ProductForMonetate[]) => {
  trackProducts(products, 'plp')
}

export const trackProducts = (products: ProductForMonetate[], pageType: string) => {
  try {
    const M = getMonetate()
    setMonetatePageType(pageType)
    M.push([ADD_PRODUCTS, products])
    sendMonetateEvent()
    Log.info('MONETATE PRODUCTS', JSON.stringify(products))
  } catch (e) {
    Log.error('MONETATE PRODUCTS TRACK ERROR ', `${e}`)
  }
}

export const trackPurchase = (purchaseId, productsInCart: ProductForMonetate[], promoAdjust?: string) => {
  try {
    const M = getMonetate()
    const productsInCartMerged = mergeProductsInCart(productsInCart)
    const purchaseRows = mapPurchaseRows(purchaseId, productsInCartMerged, promoAdjust)
    setMonetatePageType('order-confirmation')
    M.push([ADD_PURCHASE_ROWS, purchaseRows])
    Log.info('MONETATE PURCHASE', JSON.stringify(purchaseRows))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE PURCHASE TRACK ERROR ', `${e}`)
  }
}

export const trackCart = (productsInCart: ProductForMonetate[], pageType?: string, M?) => {
  try {
    if (M == null) {
      M = getMonetate()
    }
    const productsInCartMerged = mergeProductsInCart(productsInCart)
    setMonetatePageType(pageType)
    M.push([ADD_CART_ROWS, productsInCartMerged])
    Log.info('MONETATE CART', JSON.stringify({ products: productsInCartMerged, pageType: pageType }))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE TRACK CART ERROR ', `${e}`)
  }
}

export function sendMonetateEvent() {
  window.monetateQ && window.monetateQ.push([TRACK_DATA])
}

export const setMonetatePageType = (pageType?: string) => {
  try {
    const M = getMonetate()
    const mappedPageType = mapPageType(pageType)
    M.push([SET_PAGE_TYPE, mappedPageType.value])
    !mappedPageType.haveProductInformation && sendMonetateEvent()
    Log.info('MONETATE Page Type', JSON.stringify({ pageType: pageType, monetatePageType: mappedPageType }))
  } catch (e) {
    Log.error('MONETATE Page Type ERROR ', `${e}`)
  }
}
