import { styled } from '@mui/material'

export const StyledBox = styled('div', {
  name: 'Box',
  slot: 'Root',
  shouldForwardProp: prop => prop !== 'withBorder',
})<{
  variant: string
  withBorder: boolean
}>`
  background-color: ${({ theme, variant }) =>
    variant === 'light' ? theme.palette.neutral.tones[100] : theme.palette.background[1]};
  border: ${({ theme, withBorder, variant }) => {
    const tone = variant === 'light' ? 50 : 60
    return withBorder ? `1px solid ${theme.palette.neutral.tones[tone]}` : 'none'
  }};
  border-radius: ${({ withBorder }) => (withBorder ? '8px' : '')};
  display: flex;
  flex-direction: column;
  padding: ${({ withBorder, variant, theme }) => (withBorder || variant === 'dark' ? theme.spacing(8) : '')};
  width: 100%;
`
