import React from 'react'
import styled from '@mui/material/styles/styled'

import { useTranslation } from 'next-i18next'

export const ImageNotAvailablePlaceholderContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.light.secondary,
  padding: `${theme.spacing(5)} 0`,
  margin: `${theme.spacing(4)} 0`,

  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(21),
    marginRight: theme.spacing(19.5),
    height: 336,
  },
}))

const ImageNotAvailableTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(10),
  color: theme.palette.text.light.secondary,
}))

const ProductImageNotAvailable = () => {
  const { t } = useTranslation()
  // const category = breadcrumbs && breadcrumbs[0]?.label

  return (
    <ImageNotAvailablePlaceholderContainer>
      <ImageNotAvailableTitle>{t('ImageNotAvailable.title')}</ImageNotAvailableTitle>
    </ImageNotAvailablePlaceholderContainer>
  )
}

export default ProductImageNotAvailable
