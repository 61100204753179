const usePlpPlacements = ({ plpCommerce }) => {
  const placementsPlpCommerce = plpCommerce?.commercePlacements || []
  const plpDescription = plpCommerce?.PLPDescription || {
    numFound: 0,
    result: [],
  }

  return {
    placementsPlpCommerce,
    plpDescription,
  }
}

export default usePlpPlacements
