import { Link } from '@components/common/Link/Link'
import { ORIGINALSEARCHTERM, SEARCHTERM, SORTING_NOT_CLUSTERED } from '@constants/common'
import { Box } from '@mui/system'
//Custom libraries
//Standard libraries
import React, { useEffect } from 'react'
//Redux
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { mapMonetateProducts, mapOrderItemsInCart, trackProducts } from '@foundation/monetate/lib'
import { useSelector } from 'react-redux'
import { BreadcrumbLayout } from '../breadcrumb'
import PlpHeader from './components/PlpHeader'
import { ProductGridView } from './components/ProductGridView'
import TopSEOBlock from './components/TopSEOBlock'
//Tealium
import { SEARCH } from '@constants/routes'
import { currentPageSeoDataSelector } from '@features/seo/selector'
//UI
import { orderItemsSelector } from '@features/order/selector'
import { getFrameAdvisorAnalyticsData, sendPlpEvent, sendSearchResultEvent } from '@foundation/analytics/tealium/lib'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { usePageType } from '@foundation/hooks/usePageType'
import { keywordSelector } from '@redux/selectors/search'
import { LazyComponentProps, trackWindowScroll } from 'react-lazy-load-image-component'
//Foundation libraries
import PlpDescription from '@components/PlpDescription/PlpDescription'
import { ICategory } from '@features/category/query'
import { useGetProductQuery } from '@features/plp/query'
import { IUiState } from '@features/ui/slice'
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'
import useBreakpoints from '@hooks/useBreakpoints'
import { useProductParams } from '@hooks/useProductParams'
import { teaserPropsByView } from '@utils/placements'
import { useTranslation } from 'next-i18next'
import { getProductBreadcrumbs, sortBreadcrumbsByGroupId } from '../PagesSeo/product/Product'
import BottomSEOBlock from './components/BottomSEOBlock'
import usePlpPlacements from './usePlpPlacements'

import PlacementLayout from '@components/Cms/PlacementLayout'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { usePlpState } from '@utils/Plp/PlpContext'
import { getParentCatalogGroupIds } from '@utils/product'
import { useSearchParams } from 'next/navigation'

const PLP_PLACEMENT_KEY = 'PLP_placement_'

interface ProductGridProps {
  cid: string
  categoryId?: string
  searchTerm?: string
  categoryData?: ICategory[] | null
  parentCatalogGroupID?: string[] | null
}

/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */
const ProductGridLayout: React.FC<ProductGridProps & LazyComponentProps> = props => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  // const query = router.query as Record<string, string>
  const suggestedKeywords = useSelector(keywordSelector)
  const orderItems = useSelector(orderItemsSelector)
  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)
  const seoData = useSelector(currentPageSeoDataSelector())
  const pageType = usePageType()
  const { ...analyticsDataForPlp } = useAnalyticsData('plp')
  // const frameGeniusData = useFrameGenius()
  const frameGeniusData = {} as any

  const isFrameGeniusToggleEnabled = false
  // SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  const cid = props.cid
  const searchTerm: string = props.searchTerm ? props.searchTerm : ''
  // const identifier =
  //   props?.externalContext?.identifier || seoData?.tokenExternalValue
  //get Plp Product List
  const customerSegment = useCustomerSegmentsUtil()[0]
  const { generateParameters } = useProductParams()
  const parameters = generateParameters(customerSegment)

  const query = useSearchParams()
  const selectedSortOption = query.get('orderBy') ?? '0'
  const currentOffset = Number(query.get('offset')) ?? 0
  const querySearchTerm = query.get(SEARCHTERM) ?? ''
  const originalSearchTerm = query.get(ORIGINALSEARCHTERM) ?? ''
  const plpState = usePlpState()

  const { data: productData, isLoading, isFetching } = useGetProductQuery(parameters)

  const productList = productData?.productList || []

  const productListTotal = productData?.productListTotal || -1
  const isCatalogLoading = isLoading || isFetching
  const facets = productData?.facets || []
  const selectedFacets = productData?.selectedFacets || {}

  const { categoryData } = props

  const category = categoryData ? categoryData[categoryData.length - 1] : ({} as ICategory)

  const breadcrumbsNotSorted = getProductBreadcrumbs(categoryData) || []
  const parentCatalogGroupID = getParentCatalogGroupIds(categoryData)
  const breadcrumbs = sortBreadcrumbsByGroupId(breadcrumbsNotSorted, parentCatalogGroupID)
  const { placementsPlpCommerce, plpDescription } = usePlpPlacements({ plpCommerce: undefined })
  const plpPlacements = placementsPlpCommerce?.filter(placement => placement.name.includes(PLP_PLACEMENT_KEY))
  const { langCode } = useStoreIdentity()
  const { dynamicContentBanners } = useDynamicCmsContent(placementsPlpCommerce, null)
  //Analytics
  const productsInCartForMonetate = mapOrderItemsInCart(orderItems)

  const productListingLayout: IUiState['productsMobileListingLayout'] =
    productsMobileListingLayout === 'full' && !isDesktop ? 'full' : 'compact'
  const searchTitle = productListTotal > 0 ? `${productListTotal} ${t('ProductGrid.Labels.searchResults')}` : ''
  const headerTitle = searchTerm ? searchTitle : category?.description

  const bestsellerClusterCheck = el => el === '0'

  useEffect(() => {
    if (!isCatalogLoading && productList?.length > 0) {
      analyticsDataForPlp.userToken = getUserToken()
      if (querySearchTerm) {
        sendSearchResultEvent({
          common: analyticsDataForPlp,
          qnt: productListTotal,
          products: productList,
          pageSection: seoData?.identifier || '',
          searchKeyword: querySearchTerm,
          searchKeyActual: originalSearchTerm,
        })
      } else {
        sendPlpEvent({
          common: {
            ...analyticsDataForPlp,
            ...((isFrameGeniusToggleEnabled && getFrameAdvisorAnalyticsData(frameGeniusData)) || {}),
          },
          qnt: productListTotal,
          products: productList,
          pageSection: seoData?.identifier || '',
        })
      }

      category && trackProducts(mapMonetateProducts(productList), pageType.pageType as string)
    }
  }, [
    analyticsDataForPlp,
    category,
    frameGeniusData,
    isCatalogLoading,
    isFrameGeniusToggleEnabled,
    originalSearchTerm,
    pageType.pageType,
    productList,
    productListTotal,
    productsInCartForMonetate,
    querySearchTerm,
    seoData?.identifier,
  ])

  const brandNameRough = seoData?.tokenExternalValue?.split('_').pop() || ''

  const brandName = decodeURI(brandNameRough)

  const isClustered =
    (!!parameters.sortBy || bestsellerClusterCheck(selectedSortOption)) &&
    !SORTING_NOT_CLUSTERED.includes(parameters.sortBy as string)

  const showPlacements = plpPlacements.every(placement =>
    placement.items.every(item => {
      const { teaserTitle, teaserText } = teaserPropsByView(placement.viewtype || 'default')
      return !(item.media?.length === 0 && item[teaserTitle] === '' && item[teaserText] === '')
    })
  )

  return (
    <>
      <BreadcrumbLayout
        breadcrumbsList={breadcrumbs}
        cid={cid}
        searchTermText={`${t('ProductGrid.Labels.searchResults')}`}
        langCode={langCode}
        isLocaleDomain={plpState.isLocaleDomain}
        locale={plpState.locale}
        locationOrigin={plpState.locationOrigin}
      />
      {showPlacements && <PlacementLayout placements={plpPlacements || []} loading={false} isPLP={true} />}
      <PlpHeader
        productData={productData!}
        title={headerTitle}
        isPlacementsBetween={showPlacements}
        catalogLoading={isCatalogLoading}
        searchTerm={searchTerm}
        category={category}
      />
      <TopSEOBlock
        isEnabled={plpState.isPlpSeoEnabled.top}
        currentOffset={currentOffset}
        brandName={brandName}
        title={category?.description}
        description={category?.longDescription || ''}
      />
      <ProductGridView
        isClustered={isClustered}
        isCatalogLoading={isCatalogLoading}
        products={productList}
        productsCount={productListTotal}
        dynamicContentBanners={dynamicContentBanners}
        productListingLayout={productListingLayout}
      >
        {!isCatalogLoading && productListTotal <= 0 && searchTerm !== '' && (
          <Box paddingX="16px" className="suggested-keywords">
            <h4>
              {t('ProductGrid.Labels.noMatches', {
                searchTerm: searchTerm.replace('*', ''),
              })}
            </h4>
            <p>
              {t('ProductGrid.Labels.searchAgain', {
                searchTerm: searchTerm.replace('*', ''),
              })}
            </p>
            {suggestedKeywords.length > 0 && (
              <>
                {t('ProductGrid.Labels.suggestion')}
                <br />
                {suggestedKeywords?.map((keyword: string, index: number) => (
                  <Link
                    key={keyword}
                    href={SEARCH + '?searchTerm=' + keyword}
                    onClick={() => {}}
                    className="suggestion-link"
                    id={`productGrid_a_22_${index}_${cid}`}
                    locale={plpState.locale.replace('_', '-').toLowerCase()}
                    isLocaleDomain={plpState.isLocaleDomain}
                    locationOrigin={plpState.locationOrigin}
                  >
                    {keyword} <br />
                  </Link>
                ))}
              </>
            )}
          </Box>
        )}
      </ProductGridView>
      {plpDescription.numFound > 0 && <PlpDescription placements={plpDescription.result} />}
      <BottomSEOBlock
        isEnabled={plpState.isPlpSeoEnabled.bottom}
        title={category?.description}
        description={category?.longDescription || ''}
      />
    </>
  )
}

export default trackWindowScroll(ProductGridLayout)
