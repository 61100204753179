import { initIndexName, initSearchClient } from '@foundation/algolia/algoliaConfig'
import { Configure, InstantSearch } from 'react-instantsearch'
import { TAlgoliaInit, TAlgoliaInitChildren } from '../types'

export const AlgoliaInit: React.FC<TAlgoliaInit & TAlgoliaInitChildren> = ({
  children,
  checkForEmptyQuery,
  initIndexNameParams,
  routing,
  searchClientDefault,
  indexNameDefault,
  configure,
}) => {
  const searchClient = searchClientDefault ?? initSearchClient(checkForEmptyQuery)
  const indexName = indexNameDefault ?? initIndexName(initIndexNameParams || {})

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} routing={routing}>
      <Configure {...configure} />
      {children(searchClient)}
    </InstantSearch>
  )
}
