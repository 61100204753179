import { useTranslation } from 'next-i18next'
import React, { FC, useRef } from 'react'
import { useInstantSearch, usePagination } from 'react-instantsearch'

import { ProductTileAlgolia, ProductTileLoading } from '@components/ProductTile'
import { ProductTileProps } from '@components/ProductTile/ProductTile'
import { GridItem } from '@components/UI/Grid'
import { IUiState } from '@features/ui/slice'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IAlgoliaHit } from '@typesApp/product'
import { usePlpState } from '@utils/Plp/PlpContext'
import PaginationLayout from '../PaginationLayout'
import {
  ProductsWrapper,
  StyledGridProductLayout,
  StyledProductGridContainer,
  StyledProductLabelWrap,
  StyledProductWrap,
  StyledProductsCount,
} from './ProductGridView.style'

import { CmsGridBannerTiles } from '@components/common/components/CmsGridBannerTiles'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { isCMCollection } from '@typesApp/teaser'
import ProductLabel from '@components/UI/ProductLabel'
import { badgeLabelsMap, updateAttributes } from '@components/UI/ProductLabel/helper'

interface ProductGridViewProps {
  dynamicContentBanners?: IPlacement[]
  plpPlacements?: IPlacement[]
  isCatalogLoading: boolean | undefined
  isClustered?: boolean
  products: IAlgoliaHit[]
  productsCount: number
  productTileProps?: Partial<ProductTileProps>
  variant?: string
  productListingLayout?: IUiState['productsMobileListingLayout']
  children?: React.ReactNode
}

const filterPlpPlacements = (placements: IPlacement[]) => {
  const listViewTypeControl = ['plp-two-tiles', 'default']
  return (
    placements?.filter(placement => {
      if (placement.viewtype === 'default') {
        const collection = placement.items.find(isCMCollection)
        return collection ? listViewTypeControl.includes(collection.viewtype) : false
      } else return placement.viewtype ? listViewTypeControl.includes(placement.viewtype) : false
    }) || []
  )
}

/**
 * `@function ProductGridViewAlgolia
 * `
 *
 * Component for a view-purpose only.
 * Accepts props for displaying products, pagination, 'load more' action.
 * Doesn't perform server calls on its own, uses callbacks to interact with layer responsible
 * for fetching and formatting data.
 */
const ProductGridViewAlgolia: FC<ProductGridViewProps> = ({
  dynamicContentBanners,
  children,
  isCatalogLoading,
  isClustered,
  products,
  productsCount,
  variant,
  productListingLayout,
}) => {
  const { indexUiState } = useInstantSearch()
  const plpState = usePlpState()
  const { t } = useTranslation()
  const lastSelectedProduct = plpState.lastSelectedProduct
  const selectedProduct = useRef<HTMLDivElement | null>(null)
  const paginationState = usePagination()
  const page = indexUiState.page
  // Show only on first page
  const bannerPlpPlacements = dynamicContentBanners ? filterPlpPlacements(dynamicContentBanners) : []
  const bannersEnabled = typeof page === 'undefined' || indexUiState.page === 0
  const customerSegments = useCustomerSegmentsUtil()

  return (
    <ProductsWrapper data-element-id="X_X_Tiles">
      {children}
      <StyledProductGridContainer>
        {isCatalogLoading && (
          <>
            {new Array(products?.length === 0 ? 8 : products?.length).fill(1).map((_, index) => {
              return (
                <StyledGridProductLayout key={index}>
                  <StyledProductWrap>
                    <ProductTileLoading />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              )
            })}
          </>
        )}

        {!isCatalogLoading && products.length > 0 ? (
          <>
            {products.map((product, index) => {
              const updatedAttributes = updateAttributes(product['attributes'])
              const matchedBadgeKey = Object.keys(badgeLabelsMap).find(badgeKey => badgeKey in updatedAttributes)
              return (
                <StyledGridProductLayout
                  key={product.productId}
                  ref={
                    !selectedProduct.current && lastSelectedProduct === product.productId ? selectedProduct : undefined
                  }
                >
                  <StyledProductWrap id={`product_${product.productId}`}>
                    <StyledProductLabelWrap>
                      {matchedBadgeKey && (
                        <ProductLabel badge={matchedBadgeKey} label={t(`ProductLabel.${matchedBadgeKey}`)} />
                      )}
                    </StyledProductLabelWrap>
                    <ProductTileAlgolia
                      isClustered={isClustered}
                      product={product}
                      tileIndex={index}
                      variant={variant ? variant : 'plp'}
                    />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              )
            })}

            {bannersEnabled &&
              bannerPlpPlacements?.map((item, index) => {
                const controlBanner = 3
                const numBanners = Math.floor(products.length / controlBanner)
                if (index >= numBanners) return null
                return (
                  <CmsGridBannerTiles
                    key={index}
                    data-element-id={`X_PLP_${item.name}_${item.items?.length ?? 0}-Banner`}
                    placement={item}
                    customerSegments={customerSegments}
                    productGridLayout={productListingLayout}
                  />
                )
              })}
          </>
        ) : (
          <>{!productsCount && <GridItem xs={12}>{t('ProductGrid.Labels.noProductsFoundForFilter')}</GridItem>}</>
        )}
      </StyledProductGridContainer>
      {!isCatalogLoading && paginationState.nbPages > 1 && (
        <StyledProductsCount>
          <PaginationLayout paginationState={paginationState} />
        </StyledProductsCount>
      )}
    </ProductsWrapper>
  )
}

export default ProductGridViewAlgolia
