import { ReactNode, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IProduct, ProductSoldOutStatus } from '../../../../types/product'
import {
  AddToCartButton,
  AvailabilityTitle,
  AvailabilityWarning,
  ComingBackSoonContainer,
  ComingSoonText,
  ComingSoonWarning,
  NeedPrescriptionText,
  PdpCtasWrapper,
  SoldOutContainer,
  StyledButtonsContainer,
  StyledCTAContainer,
  StyledComingBackSoonContainer,
  StyledPrimaryButton,
  StyledRelativeBtn,
  StyledRelativeLargeBtn,
} from './CtaPdp.style'

import { usePrescriptionLenses } from '@components/PrescriptionLenses/PrescriptionLensesContext'
import { Button } from '@components/UI/Button'
import { StyledLoader } from '@components/UI/Loader'
import { isAddingItemSelector } from '@features/order/selector'
import { isOpticalProduct } from '@utils/product'
import { getModelName } from '@utils/productAttributesAlgolia'
import { useTranslation } from 'next-i18next'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { setOpenDrawerProductNotificationAvailable } from '../../../../features/ui/action'
import { openDrawerProductNotificationSelector } from '../../../../features/ui/selector'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'

interface CtaProps {
  'data-element-id'?: string
  'data-name'?: string
  error?: boolean
  isStickyBar?: boolean
  isMobileSticky?: true
  product: IProduct
  roxable?: boolean
  type: ProductSoldOutStatus
  addToCartFillType?: 'outline' | 'fill'
  addToCart?(): void
}

const CtaPdp = forwardRef<HTMLDivElement, CtaProps>(
  (
    {
      'data-element-id': dataElementId = 'X_X_Prod_AddCart',
      'data-name': dataName = 'addToBasket',
      error,
      isStickyBar,
      isMobileSticky,
      product,
      roxable,
      type,
      addToCart,
      addToCartFillType,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const { isRXEnabled } = useStoreIdentity()
    const dispatch = useDispatch()
    const productAvailableNotificationOpen = useSelector(openDrawerProductNotificationSelector)
    const modelName = getModelName(product)
    const { isLoadingConfigurator, setPrescriptionLensesFormOpen, setPrescriptionLenses } = usePrescriptionLenses()
    const isAddingItem = useSelector(isAddingItemSelector)

    const toggleproductNotificationAvailableDrawer = () =>
      dispatch(setOpenDrawerProductNotificationAvailable(!productAvailableNotificationOpen))

    const openPrescriptionLenses = () => {
      if (!product) return
      setPrescriptionLensesFormOpen(true)
      setPrescriptionLenses(product)
    }

    const GetPdpCtas = (): ReactNode => {
      switch (true) {
        case roxable && isRXEnabled && isOpticalProduct(product):
          return (
            <StyledRelativeBtn
              id="rxconfig-button"
              data-element-id="X_Pdp_Prod_AddLens"
              data-description={`${modelName}_${product.partNumber}`}
              className="prescription"
              disabled={isAddingItem}
              onClick={openPrescriptionLenses}
            >
              <StyledLoader size={20} loading={isLoadingConfigurator} />
              {t('ProductDetails.Suggested.Actions.Prescription')}
            </StyledRelativeBtn>
          )
        case roxable && !isStickyBar && isRXEnabled && !isOpticalProduct(product):
          return (
            <>
              <StyledRelativeBtn
                id="rxconfig-button"
                data-element-id="X_Pdp_Prod_AddLens"
                data-description={`${modelName}_${product.partNumber}`}
                className="prescription"
                disabled={isAddingItem}
                onClick={openPrescriptionLenses}
              >
                <StyledLoader size={20} loading={isLoadingConfigurator} />
                {t('ProductDetails.Suggested.Actions.Prescription')}
              </StyledRelativeBtn>
              <NeedPrescriptionText>{t('CtaPDP.Labels.NeedPrescription')}</NeedPrescriptionText>
              <AddToCartButton
                fillType={addToCartFillType}
                data-element-id={dataElementId}
                data-description={`${modelName}_${product.partNumber}`}
                disabled={error || isAddingItem}
                onClick={addToCart}
                isStickyBar={false}
              >
                <StyledLoader size={20} loading={isAddingItem} />
                {t(error ? 'productDetail.AddToCartError' : 'ProductDetails.CTA.AddToBag')}
              </AddToCartButton>
            </>
          )
        case isStickyBar && isRXEnabled && !isOpticalProduct(product):
          return (
            <>
              {roxable ? (
                <StyledRelativeBtn
                  id="rxconfig-button-sticky"
                  data-element-id="X_X_Stickybar_AddLens"
                  data-description={`${modelName}_${product.partNumber}`}
                  className="prescription"
                  onClick={openPrescriptionLenses}
                  disabled={isAddingItem}
                  fullWidth
                >
                  <StyledLoader size={20} loading={isLoadingConfigurator} />
                  {t('ProductDetails.Suggested.Actions.Prescription')}
                </StyledRelativeBtn>
              ) : (
                <StyledRelativeBtn
                  fillType={addToCartFillType}
                  data-element-id="X_X_Stickybar_AddCart"
                  data-description={`${modelName}_${product.partNumber}`}
                  disabled={error || isAddingItem}
                  onClick={addToCart}
                  fullWidth
                >
                  <StyledLoader size={20} loading={isAddingItem} />
                  {t(error ? 'productDetail.AddToCartError' : 'ProductDetails.CTA.AddToBag')}
                </StyledRelativeBtn>
              )}
            </>
          )

        default:
          return (
            <StyledPrimaryButton
              fillType="fill"
              data-element-id={isStickyBar ? 'X_X_Stickybar_AddCart' : dataElementId}
              data-name={dataName}
              data-description={`${modelName}_${product.partNumber}`}
              disabled={error || isAddingItem}
              onClick={addToCart}
              isAddingItem={isAddingItem}
              fullWidth
            >
              <StyledLoader size={20} loading={isAddingItem} />
              {t(error ? 'productDetail.AddToCartError' : 'ProductDetails.CTA.AddToBag')}
            </StyledPrimaryButton>
          )
      }
    }

    if (type === PRODUCT_SOLDOUT_STATUS.NONE || type === PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY)
      return (
        <PdpCtasWrapper className="pdp__cta-wrapper" isStickyBar={isStickyBar} ref={ref}>
          <StyledButtonsContainer>{GetPdpCtas()}</StyledButtonsContainer>
        </PdpCtasWrapper>
      )

    if (type === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON)
      return !isStickyBar ? (
        <ComingBackSoonContainer ref={ref}>
          <StyledComingBackSoonContainer>
            <AvailabilityWarning>
              <AvailabilityTitle>{t('ComingBackSoon.Sorry')}</AvailabilityTitle>
              <ComingSoonText
                dangerouslySetInnerHTML={{
                  __html: t('ComingBackSoon.ComingBackSoonText'),
                }}
              />
            </AvailabilityWarning>
            {/*{t('productDetail.HighDemand')}*/}
          </StyledComingBackSoonContainer>

          <Button variant="primary" onClick={toggleproductNotificationAvailableDrawer}>
            {t('ComingBackSoon.EmailMeCTA')}
          </Button>
          {/*{!isMobileSticky && (*/}
          {/*  <Button variant="secondary" onClick={viewAlternatives}>*/}
          {/*    {t('productDetail.Alternatives')}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </ComingBackSoonContainer>
      ) : (
        <StyledCTAContainer>
          <StyledRelativeLargeBtn variant="primary" onClick={toggleproductNotificationAvailableDrawer}>
            {t('ComingBackSoon.EmailMeCTA')}
          </StyledRelativeLargeBtn>
        </StyledCTAContainer>
      )

    if (type === PRODUCT_SOLDOUT_STATUS.COMING_SOON)
      return isStickyBar ? (
        <SoldOutContainer ref={ref} isStickyBar={true}>
          <AddToCartButton onClick={toggleproductNotificationAvailableDrawer} isStickyBar={true}>
            {t('ComingSoon.BeTheFirst').toUpperCase()}
          </AddToCartButton>
        </SoldOutContainer>
      ) : (
        <SoldOutContainer ref={ref} isStickyBar={false}>
          {!isMobileSticky ? (
            <ComingSoonWarning isStickyBar={false}>{t('ComingSoon.ComingSoon').toUpperCase()}</ComingSoonWarning>
          ) : null}
          <AddToCartButton onClick={toggleproductNotificationAvailableDrawer} isStickyBar={false}>
            {t('ComingSoon.BeTheFirst').toUpperCase()}
          </AddToCartButton>
        </SoldOutContainer>
      )

    return null
  }
)

export default CtaPdp
