import { CheckboxProps as MuiCheckboxProps } from '@mui/material'
import { forwardRef } from 'react'
import { IconCheckboxTick, IconCheckboxUnchecked } from '../Icons/VD/General'
import StyledMuiCheckbox from './Checkbox.style'

export interface CheckboxProps {
  name?: MuiCheckboxProps['name']
  checked?: MuiCheckboxProps['checked']
  disabled?: MuiCheckboxProps['disabled']
  inputRef?: MuiCheckboxProps['inputRef']
  indeterminate?: MuiCheckboxProps['indeterminate']
  onChange?: MuiCheckboxProps['onChange']
  required?: MuiCheckboxProps['required']
  value?: MuiCheckboxProps['value']
  inputProps?: MuiCheckboxProps['inputProps']
  error?: boolean
  id?: MuiCheckboxProps['id']
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { error, ...rest } = props
  const checkBoxProps = {
    inputRef: ref,
    ...rest,
    ...(error ? { color: 'error' as MuiCheckboxProps['color'] } : {}),
  }
  return (
    <StyledMuiCheckbox
      {...checkBoxProps}
      icon={<IconCheckboxUnchecked />}
      checkedIcon={<IconCheckboxTick />}
      disableRipple
    />
  )
})

export default Checkbox
