import React, { forwardRef } from 'react'
import { useTranslation } from 'next-i18next'
import { SquaredFilterPill, SquaredFilterPillWrapper, StyledPLPOffersFiltersWrapper } from '../PlpHeader.style'

import { IFacet } from '@features/plp/query'

const PlpOffersFilters = forwardRef<
  HTMLDivElement,
  {
    facets: IFacet[]
    onOffersFilterApply: (string) => void
    selectedFacets: any
    removeAll: any
  }
>(({ facets, onOffersFilterApply, selectedFacets, removeAll }, ref) => {
  const { t } = useTranslation()

  const selectedDiscountFacets = Object.values(selectedFacets).filter((value: any) => {
    return value.facetName === 'LX_DISCOUNTED_PRICE_BADGES'
  })

  return (
    <StyledPLPOffersFiltersWrapper ref={ref}>
      <SquaredFilterPillWrapper>
        {facets.length > 0 && (
          <SquaredFilterPill
            id="all"
            key="all"
            selected={selectedDiscountFacets.length === 0}
            labelText={t('ProductGrid.Actions.all')}
            onClick={() => {
              removeAll(selectedDiscountFacets)
            }}
          />
        )}
      </SquaredFilterPillWrapper>
      {[...facets[0]?.entry]
        .sort((a, b) => Number(a.label.match(/\d+/)) - Number(b.label.match(/\d+/)))
        .map((entry, index) => (
          <SquaredFilterPillWrapper key={index}>
            <SquaredFilterPill
              id={entry.value}
              key={entry.value}
              selected={selectedDiscountFacets.some((sel: any) => {
                return entry.label === sel.label
              })}
              labelText={`${entry.label.replace(' OFF', '')} ${t('ProductGrid.Labels.percentageOff')}`}
              onClick={() => {
                onOffersFilterApply(entry.value)
              }}
            />
          </SquaredFilterPillWrapper>
        ))}
    </StyledPLPOffersFiltersWrapper>
  )
})

export default PlpOffersFilters
