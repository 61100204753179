import styled from '@mui/material/styles/styled'

export const VirtualMirrorCTAContainerDesktop = styled('div', {
  name: 'VirtualMirrorCTA',
  slot: 'ContainerDesktop',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}))

export const VirtualMirrorCTAContainerMobile = styled('div', {
  name: 'VirtualMirrorCTA',
  slot: 'ContainerMobile',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))
