import React, { FC } from 'react'
import { StyledTitleContainer } from '../PlpHeader.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const PlpHeaderTitle: FC<{
  text?: string
  isSticky?: boolean
  catalogLoading?: boolean
}> = ({ text = '', catalogLoading }) => (
  <StyledTitleContainer variant="h5">
    {catalogLoading ? (
      <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
        <Skeleton containerClassName="dc_plp_header_skeleton" />
      </SkeletonTheme>
    ) : (
      text || (
        <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
          <Skeleton containerClassName="dc_plp_header_skeleton" />
        </SkeletonTheme>
      )
    )}
  </StyledTitleContainer>
)

export default PlpHeaderTitle
