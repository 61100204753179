import { findFirstPlacementByType, findPlacementByName } from '@components/common/helpers/cms'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import {
  BUNDLE_OFFER_ACTION_TYPE,
  BUNDLE_OFFER_PLACEMENT_NAME,
  BUNDLE_OFFER_PRODUCT_TYPE,
} from '../constants/BundleOfferPlacementNames'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import { IBundleProduct, ICMLXBundle } from '@typesApp/cmsPlacement/ICMLXBundle'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { useTranslation } from 'next-i18next'

type UseBundlePlacementsProps = IPlacement<IPlacementItem>[]

type UseBundlePlacementsReturn = {
  hasBundleOffer: boolean
  productBundleImages: TMedia[] | undefined
  bundleQuantity: number
  bundleTitle: string | undefined
  bundleAddToCartText: string
}

const useBundlePlacements = (props: UseBundlePlacementsProps): UseBundlePlacementsReturn => {
  const { t } = useTranslation()
  const bundlePlacement = findPlacementByName(props, BUNDLE_OFFER_PLACEMENT_NAME)
  const bundlePlacementLXTeaser = findFirstPlacementByType<ILXTeaser>(bundlePlacement, 'LXTeaser')
  const bundlePlacementAction = findFirstPlacementByType<ICMLXBundle>(bundlePlacement, BUNDLE_OFFER_ACTION_TYPE)
  const bundlePlacementProduct = findFirstPlacementByType<IBundleProduct>(bundlePlacement, BUNDLE_OFFER_PRODUCT_TYPE)

  const productBundleImages = bundlePlacement?.items?.[0]?.media

  const bundleQuantity = bundlePlacementProduct?.quantity ?? 0

  const bundleTitle = bundlePlacementAction?.teaserTitle ?? bundlePlacementAction?.title ?? undefined

  const bundleAddToCartText =
    bundlePlacementLXTeaser?.teaserLXCallToActionSettings?.[0]?.callToActionText ?? t('ProductDetails.CTA.AddToCart')

  const hasBundleOffer = bundlePlacement !== undefined && bundleQuantity > 0

  return { productBundleImages, bundleQuantity, bundleTitle, bundleAddToCartText, hasBundleOffer }
}

export default useBundlePlacements
