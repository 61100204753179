import { bundleOfferPricesSelector } from '@redux/selectors/site'
import { IProduct } from '@typesApp/product'
import { useSelector } from 'react-redux'
import useProductBundleData from './useProductBundleData'

type UseBundlerOfferPricesProps = IProduct

type UseBundlerOfferPricesReturn = {
  originalPrice: number | undefined
  offerPrice: number | undefined
}

const useBundlerOfferPrices = (props: UseBundlerOfferPricesProps): UseBundlerOfferPricesReturn => {
  const { partNumber, price } = useProductBundleData(props)
  const offerPrices = useSelector(bundleOfferPricesSelector)

  const offerPrice = partNumber
    ? typeof offerPrices[partNumber] === 'string'
      ? Number.parseFloat(offerPrices[partNumber])
      : Number(offerPrices[partNumber]) || undefined
    : undefined

  const originalPrice =
    price?.listPrice !== undefined
      ? typeof price.listPrice === 'string'
        ? Number.parseFloat(price.listPrice) // TODO: offerPrice ahead of listPrice but only if offerprice is less than listPrice
        : price.listPrice
      : undefined

  return { originalPrice, offerPrice }
}

export default useBundlerOfferPrices
