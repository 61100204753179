import { useTheme } from '@mui/material/styles'
import { FC, useState } from 'react'
// TYPES
import { Attachment } from '../../../types/product'
import { ProductFullPageGalleryImagesSlider, ProductFullPageGalleryThumbsSlider } from '../ProductDetails.style'
import {
  ProductFullPageGalleryCloseIcon,
  ProductFullPageGalleryContainer,
} from './ProductImagesSlider/ProductImagesCarousel.style'

//UI
import { CloseIcon } from '@components/UI/Icons/close'

/**
 * @param { Attachment[] } images slider images
 * @param { 1 | 0 } isvisible toggle the component visibility
 * @param { boolean } pdpDataloading pdp data loading status
 * @param { function } onCloseClick callback when clicking on close button
 * @param { number } currentSlide you can force the slide index to the slider
 */

export interface ProductFullPageGalleryProps {
  images?: Attachment[]
  isvisible?: 1 | 0
  onCloseClick?: () => void
  currentSlide?: number
  pdpDataloading?: boolean
}

const ProductFullPageGallery: FC<ProductFullPageGalleryProps> = ({
  images,
  onCloseClick,
  isvisible,
}: ProductFullPageGalleryProps) => {
  const theme = useTheme()
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  return !!isvisible ? (
    <ProductFullPageGalleryContainer isvisible={isvisible}>
      <ProductFullPageGalleryCloseIcon onClick={() => onCloseClick && onCloseClick()}>
        <CloseIcon htmlColor={theme.palette.primary.main} />
      </ProductFullPageGalleryCloseIcon>
      {images && images?.length > 0 && (
        <>
          <ProductFullPageGalleryImagesSlider
            currentDisplayedSlide={currentSlide}
            slideIndex={currentSlide}
            onSlideChange={slideNumber => setCurrentSlide(slideNumber || 0)}
            images={images}
            sliderImageProps={{
              srcsetmap: {
                300: '320w',
                400: '640w',
                600: '960w',
                700: '1280w',
                900: '1920w',
              },
              width: 600,
              usage: 'PDP',
              loading: 'lazy',
            }}
            loop={false}
          />
          <ProductFullPageGalleryThumbsSlider
            currentDisplayedSlide={currentSlide}
            onImageClick={index => {
              setCurrentSlide(index)
            }}
            images={images}
            sliderImageProps={{
              srcsetmap: {
                200: '320w',
                300: '640w',
              },
              width: 80,
              usage: 'PDP',
              loading: 'lazy',
            }}
            loop={false}
            slidesPerView={images.length}
            withProgressBar
          />
        </>
      )}
    </ProductFullPageGalleryContainer>
  ) : null
}

export default ProductFullPageGallery
