import styled from '@mui/material/styles/styled'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const WrapperBrandIcon = styled('div', {
  name: 'BrandIcon',
  slot: 'Wrapper',
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'isTabletLandscape',
})<{
  width?: number
  height?: number
  isTabletLandscape?: boolean
}>(({ theme, width, height, isTabletLandscape }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: width ? width : 'auto',
  minWidth: width ? width : 'auto',
  height: height ? height : 'auto',
  minHeight: height ? height : 'auto',

  [theme.breakpoints.up('md')]: {
    width: isTabletLandscape ? '100%' : width ? width : 'auto',
    height: height ? height : 'auto',
    minHeight: height ? height : 'auto',
  },
}))

export const BrandImg = styled(LazyLoadImage, {
  name: 'BrandIcon',
  slot: 'Image',
  shouldForwardProp: (prop: string) => prop !== 'isInverted' && prop !== 'isPradaLineaRossa',
})<{
  isInverted: boolean
  isPradaLineaRossa?: boolean
}>(({ theme, isInverted, isPradaLineaRossa }) => ({
  width: '100%',
  maxWidth: '100%',
  opacity: 1,
  transition: 'opacity 0.25s',
  filter: isInverted && !isPradaLineaRossa ? 'brightness(0) invert(1)' : 'none',

  [theme.breakpoints.up('md')]: {
    opacity: isInverted ? 1 : 0.54,
  },

  '&:hover': {
    opacity: 1,
  },
}))

export const StyledTopPageTitle = styled<any>('h2', {
  name: 'TopSEOBlock',
  slot: 'Title',
  shouldForwardProp: (prop: string) => prop !== 'isLogo',
})(({ isLogo }) => ({
  textTransform: 'uppercase',
  fontSize: isLogo ? 9 : 20,
  textAlign: 'center',
}))
