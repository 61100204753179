import { useTranslation } from 'next-i18next'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import {
  SoldOutDescription,
  SoldOutImagePlaceholderContainer,
  SoldOutTitle,
  StyledSoldOutCTA,
} from './ProductSoldoutBanner.style'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'

type Props = {
  breadcrumbs?: any[]
  parentCategory?: any[]
  basePath?: any[]
}

const ProductSoldoutBanner = ({ parentCategory }: Props) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIdentity()
  const { locale, isLocaleDomain, domainLocales } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const redirectCTA = parentCategory?.[0]?.seo?.href && parentCategory[0]?.seo?.href

  return (
    <SoldOutImagePlaceholderContainer>
      <SoldOutTitle>{t('SoldOut.Sorry')}</SoldOutTitle>
      <SoldOutDescription>{t('SoldOut.SoldOutMessage')}</SoldOutDescription>
      <StyledSoldOutCTA
        href={redirectCTA ? redirectCTA : basePath}
        locale={locale as string}
        isLocaleDomain={isLocaleDomain}
        locationOrigin={locationOrigin}
      >
        {t('SoldOut.CTA.ViewSimilarProducts')}
      </StyledSoldOutCTA>
    </SoldOutImagePlaceholderContainer>
  )
}

export default ProductSoldoutBanner
