import React from 'react'
import { StyledPill } from './Pill.style'

export interface PillProps {
  key: string
  id: string
  labelText: string
  disabled?: boolean
  onClick?: () => void
  onDelete?: () => void
  isActive?: boolean
  deleteIcon?: JSX.Element
  icon?: React.ReactElement
}

export const Pill = (props: PillProps) => {
  const { labelText, disabled, onClick, onDelete, isActive, deleteIcon, icon } = props

  return (
    <StyledPill
      key={labelText}
      label={labelText}
      onClick={onClick ? onClick : undefined}
      onDelete={onDelete ? onDelete : undefined}
      isActive={isActive}
      disabled={disabled}
      deleteIcon={deleteIcon}
      icon={icon}
    />
  )
}
