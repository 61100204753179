import { styled } from '@mui/material'
import Container from '@components/UI/Container'

export const StyledBodyContainer = styled(Container, {
  name: 'NotFound',
  slot: 'Body',
})(({ theme }) => ({
  '.ops-page-grid--default': {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    margin: theme.spacing(16, 0),

    article: {
      gap: theme.spacing(12),
    },

    p: {
      ['&:nth-of-type(1)']: {
        marginBottom: theme.spacing(8),
      },
    },
  },
}))
