import React, { useState } from 'react'
import { TooltipProps as MuiTooltipProps, Typography } from '@mui/material'
import {
  BootstrapTooltip,
  StyledContent,
  TooltipContent,
  TooltipContentBlock,
  TooltipContentRight,
  TooltipCross,
} from './Tooltip.style'

export interface TooltipProps {
  title?: MuiTooltipProps['title']
  text: string
  componentBody?: MuiTooltipProps['children']
  children: MuiTooltipProps['children']
  background?: 'background11' | 'neutral' | 'background3' | 'primary'
  className?: MuiTooltipProps['className']
  arrow?: MuiTooltipProps['arrow']
  open?: MuiTooltipProps['open']
  placement?: MuiTooltipProps['placement']
  onClose?: MuiTooltipProps['onClose']
  onOpen?: MuiTooltipProps['onOpen']
}

const Tooltip = (props: TooltipProps) => {
  const { title, text, componentBody, background, arrow = true, children, ...rest } = props
  const tooltipProps = { text, background, arrow, ...rest }
  const [showToolTip, setShowToolTip] = useState<boolean>(false)

  const BodyContent = (): JSX.Element => {
    if (componentBody) {
      return (
        <>
          <TooltipContentRight>
            <TooltipCross onClick={() => setShowToolTip(false)} />
          </TooltipContentRight>
          <TooltipContent>{componentBody}</TooltipContent>
        </>
      )
    }

    return (
      <>
        {title && (
          <TooltipContentBlock>
            <Typography variant="body2">{title}</Typography>
          </TooltipContentBlock>
        )}
        {text && (
          <TooltipContentBlock>
            <Typography variant="caption">{text}</Typography>
          </TooltipContentBlock>
        )}
      </>
    )
  }

  return (
    <BootstrapTooltip
      title={<BodyContent />}
      open={showToolTip}
      onClose={() => setShowToolTip(false)}
      onOpen={() => setShowToolTip(true)}
      {...tooltipProps}
    >
      <span onClick={() => setShowToolTip(true)}>
        <StyledContent>{children}</StyledContent>
      </span>
    </BootstrapTooltip>
  )
}
export default Tooltip
