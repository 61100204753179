import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

import { SiteInfo } from '../../../redux/reducers'

export type SubscribeToAvailableNotificationProps = {
  mySite: SiteInfo
  emailAddress: string
  catentryId: string
}
/**
 * @deprecated Use Redux Toolkit Query instead
 */
const ProductAvailableNotification = {
  subscribeToAvailableNotification(args: SubscribeToAvailableNotificationProps): AxiosPromise<any> {
    const {
      mySite: { transactionContext, storeID },
      ...data
    } = args

    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `${transactionContext}/store/${storeID}/itemAvailabilityNotify`,
      data,
    }
    return axios(requestOptions)
  },
}

export default ProductAvailableNotification
