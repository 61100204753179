import { capitalize } from '@utils/helpers'
import React from 'react'
import { SizeSwitcherContainer, SizeSwitcherOptionSelected, SizeSwitcherOption } from './SizeSwitcher.style'
import { Link } from '@components/common/Link/Link'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

type Props = {
  options: { size: string; label: string; href: string }[]
  currentSize: string
}
const SizeSwitcher = ({ options, currentSize }: Props) => {
  const { locale, isLocaleDomain, domainLocales } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  return (
    <SizeSwitcherContainer>
      {options?.map(o =>
        currentSize < o.size ? (
          <React.Fragment key={o.size}>
            <SizeSwitcherOptionSelected>
              <Typography variant="body2">{`${currentSize} ${capitalize(o.label)}`}</Typography>
            </SizeSwitcherOptionSelected>
            <SizeSwitcherOption>
              <Typography variant="body2">
                <Link
                  href={o.href}
                  locale={locale as string}
                  isLocaleDomain={isLocaleDomain}
                  locationOrigin={locationOrigin}
                >{`${o.size} ${capitalize(o.label)}`}</Link>
              </Typography>
            </SizeSwitcherOption>
          </React.Fragment>
        ) : (
          <React.Fragment key={o.size}>
            <SizeSwitcherOption>
              <Typography variant="body2">
                <Link
                  href={o.href}
                  locale={locale as string}
                  isLocaleDomain={isLocaleDomain}
                  locationOrigin={locationOrigin}
                >{`${o.size} ${capitalize(o.label)}`}</Link>
              </Typography>
            </SizeSwitcherOption>
            <SizeSwitcherOptionSelected>
              <Typography variant="body2">{`${currentSize} ${capitalize(o.label)}`}</Typography>
            </SizeSwitcherOptionSelected>
          </React.Fragment>
        )
      )}
    </SizeSwitcherContainer>
  )
}

export default SizeSwitcher
