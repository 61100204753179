import { ICategory } from '@features/category/query'
import ProductDetails from '@pages_views/ProductDetails'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sendPdpEvent, sendProductUpdateEvent } from '../../../foundation/analytics/tealium/lib'
//Redux
import { currentPageSeoDataSelector } from '@features/seo/selector'
import { userDetailsSelector } from '@features/user/selector'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'
import useProductData from '@hooks/useProductData/useProductData'
import { IProduct } from '@typesApp/product'
import { getParentCatalogGroupId } from '@utils/product'
import usePdpPlacements from './usePdpPlacements'
import ProductImageZoomProvider from '@pages_views/ProductDetails/components/ProductImageZoom/ProductImageZoomContext'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { useDeviceType } from '@hooks/useDeviceType'

export type Breadcrumbs = {
  label: string
  seo: {
    href?: string
  }
  value: string
  categoryIdentifier: string
}

export const getProductBreadcrumbs = (categoryData?: ICategory[] | null): Breadcrumbs[] | undefined => {
  return categoryData?.map(el => ({
    label: el.description,
    seo: el.seo,
    value: el.uniqueID,
    categoryIdentifier: el.identifier,
  }))
}

export const sortBreadcrumbsByGroupId = (
  breadcrumbsNotSorted: Breadcrumbs[],
  parentCatalogGroupID?: string[] | null
): Breadcrumbs[] => {
  return (
    parentCatalogGroupID?.reduce<Breadcrumbs[]>((acc, groupId) => {
      const i = breadcrumbsNotSorted?.find(br => br.value === groupId)
      if (i) {
        acc.push(i)
      }
      return acc
    }, []) || []
  )
}

/**
 * Product page component
 * @param props
 */
const Product: React.FC = (props: any) => {
  const analyticsDataForPdp = useAnalyticsData('pdp')
  const userDetails = useSelector(userDetailsSelector)
  const seoData = useSelector(currentPageSeoDataSelector())
  const { categoryData, pdpProduct: pdpData, pdpCommerce, page } = props
  const [partNumber, setPartNumber] = useState<string>(page.externalContext.identifier)
  const parentCatalogGroupID = getParentCatalogGroupId(categoryData) || []
  const customerSegments = useCustomerSegmentsUtil()

  const breadcrumbsNotSorted = getProductBreadcrumbs(categoryData) ?? []
  const breadcrumbs = sortBreadcrumbsByGroupId(breadcrumbsNotSorted, parentCatalogGroupID)

  if (pdpData && 'MODEL_NAME' in pdpData.productAttributes) {
    breadcrumbs.push({
      label: pdpData.productAttributes['MODEL_NAME'],
      seo: {
        href: undefined,
      },
      value: '',
      categoryIdentifier: '',
    })
  }

  //get placements cms pdp
  const { placementsPdpCommerce } = usePdpPlacements({ pdpCommerce })

  // dynamic content
  const { dynamicContentBanners } = useDynamicCmsContent(placementsPdpCommerce, pdpData)
  const { suggestedProducts, currentProductItem: currentProduct, isRoxable } = useProductData(pdpData, partNumber)

  const deviceType = useDeviceType()

  useEffect(() => {
    if (pdpData && seoData && deviceType) {
      const filters = sessionStorage.getItem('PLPFiltersApplied')
      analyticsDataForPdp.userToken = getUserToken()
      const pageSectionUrls = seoData?.identifier.split('/')

      sendPdpEvent({
        common: {
          ...analyticsDataForPdp,
          Page_DeviceType: deviceType,
        },
        userDetails: userDetails || undefined,
        products: [pdpData],
        pageSection1: pageSectionUrls[1] || '',
        pageSection2: pageSectionUrls[2] || '',
        vmIsSupported: '2',
        grouped_queryID: sessionStorage.getItem('grouped_queryID') || '',
        ungrouped_queryID: sessionStorage.getItem('ungrouped_queryID') || '',
        grouped_indexName: sessionStorage.getItem('grouped_indexName') || '',
        ungrouped_indexName: sessionStorage.getItem('ungrouped_indexName') || '',
        position: sessionStorage.getItem('grouped_position') || '',
        ungrouped_position: sessionStorage.getItem('ungrouped_position') || '',
        objectID: sessionStorage.getItem('ungrouped_objectID') || '',
        filters: filters && JSON.parse(filters),
        customerSegments,
      })
    }
  }, [pdpData, seoData, userDetails, deviceType])

  const onClusterProductClick = (p: IProduct) => {
    //TODO figure out if I shozuld send 'p' or 'p.items[0]'
    sendProductUpdateEvent(p.items?.[0])
    const partNumberToSet = p.items?.[0]?.partNumber

    if (partNumberToSet) {
      setPartNumber(partNumberToSet)
    }
  }

  if (!pdpData || !currentProduct) {
    return null
  }

  return (
    <ProductImageZoomProvider>
      <ProductDetails
        breadcrumbs={breadcrumbs}
        onClusterProductClick={onClusterProductClick}
        partNumber={partNumber}
        pdpData={pdpData}
        placements={dynamicContentBanners}
        categoryData={categoryData}
        loading={false}
        isVmProduct={false}
        currentProduct={currentProduct}
        isRoxable={isRoxable}
        suggestedProducts={suggestedProducts}
        pageIdentifier={page.externalContext?.identifier}
      />
    </ProductImageZoomProvider>
  )
}

export default Product
