import styled from '@mui/material/styles/styled'

export const SwiperDiv = styled('div')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.dark.primary,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  height: 62,
  svg: {
    height: `${theme.spacing(6)}px`,
    width: `${theme.spacing(6)}px`,
    marginRight: `${theme.spacing(4)}px`,
  },
  [theme.breakpoints.up('sm')]: {
    maxHeight: 62,
  },
}))

export const TooltipWrapper = styled('div')(() => ({
  display: 'flex',
}))

export const OmniChannelCarouselWrapper = styled('div')(({ theme }) => ({
  '.swiper-button-prev, .swiper-button-next': {
    color: theme.palette.text.dark.primary,
    width: 0,
  },
  '.swiper-button-prev::after, .swiper-button-next::after': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}))
