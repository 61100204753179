import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { IProduct } from '@typesApp/product'
import {
  getCurrentCluster,
  getCurrentProductItemFromProductData,
  getSuggestedProducts,
  isFormattedProductCLAccessories,
  isFormattedProductContactLenses,
} from '@utils/product'
import { getCanonicalCategory, getIsRoxable, getProductType } from '@utils/productAttributes'
import { useEffect, useState } from 'react'
import Log from '../../services/Log'

const getInternalSuggestedProducts = (currentProductItem: IProduct | null | undefined) => {
  if (currentProductItem?.merchandisingAssociations === null) {
    return []
  } else {
    const merchandisingItems = getSuggestedProducts(currentProductItem?.merchandisingAssociations)
    return merchandisingItems
  }
}

/**
 * useProductData custom react hook
 * @param pdpData IProduct
 * @returns {Record}
 */
const useProductData = (pdpData: IProduct | null | undefined, productPartNumber: string) => {
  const { basePath } = useStoreIdentity()
  const [currentProductItem, setCurrentProductItem] = useState<IProduct | null | undefined>(
    getCurrentProductItemFromProductData(pdpData?.partNumber || '', pdpData as any)
  )
  const [pathName, setPathName] = useState<string | null>(null)
  const [canonicalCategoryIdentifier, setCanonicalCategoryIdentifier] = useState<string | null | undefined>(null)
  const [partNumber, setPartNumber] = useState<string | null | undefined>(pdpData?.partNumber)
  const [suggestedProducts, setSuggestedProducts] = useState<IProduct['merchandisingAssociations']>(
    getInternalSuggestedProducts(currentProductItem) ?? []
  )
  const [currentCluster, setCurrentCluster] = useState<IProduct | null>(null)

  useEffect(() => {
    setCurrentProductItem(() => {
      return pdpData?.partNumber && productPartNumber
        ? getCurrentProductItemFromProductData(productPartNumber, pdpData)
        : null
    })
    setPartNumber(() => {
      return productPartNumber
    })
  }, [pdpData, productPartNumber])

  useEffect(() => {
    const canonicalCategoryIdentifier = currentProductItem ? getCanonicalCategory(currentProductItem) : null
    setCanonicalCategoryIdentifier(canonicalCategoryIdentifier)

    const productType = currentProductItem ? getProductType(currentProductItem) : ''
    if (isFormattedProductContactLenses(productType) || isFormattedProductCLAccessories(productType)) {
      setSuggestedProducts(() => {
        if (currentProductItem?.merchandisingAssociations === null) {
          return []
        } else {
          const merchandisingItems = getSuggestedProducts(currentProductItem?.merchandisingAssociations)
          return merchandisingItems
        }
      })
    }
  }, [basePath, currentProductItem])

  useEffect(() => {
    setCurrentCluster(() => {
      return getCurrentCluster(partNumber || '', pdpData?.cluster)?.find(cluster => !!cluster) || null
    })
  }, [pdpData, partNumber])

  useEffect(() => {
    try {
      if (!currentCluster) {
        return
      }
      setSuggestedProducts(() => {
        if (currentCluster?.merchandisingAssociations === null) {
          return []
        } else {
          const merchandisingItems = getSuggestedProducts(currentCluster?.merchandisingAssociations)
          return merchandisingItems
        }
      })
    } catch (e: any) {
      Log.error('Could not retry product details data', e.message)
    }
  }, [currentCluster])

  const isRoxable = currentProductItem ? getIsRoxable(currentProductItem) : false

  return {
    currentProductItem,
    pathName,
    canonicalCategoryIdentifier,
    partNumber,
    suggestedProducts,
    isRoxable,
    currentCluster,
  }
}

export default useProductData
