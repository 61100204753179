import styled from '@mui/material/styles/styled'

export const SizeSwitcherContainer = styled('div', {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherContainer',
})(({ theme }) => ({
  width: 'fit-content',
  minWidth: theme.spacing(80),
  backgroundColor: theme.palette.neutral.tones[90],
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.spacing(20),
  height: theme.spacing(17.5),
  marginBottom: theme.spacing(10),
}))

export const SizeSwitcherOption = styled('div', {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherOption',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(40),
  textTransform: 'lowercase',
  cursor: 'pointer',
  border: 'none',
  borderRadius: theme.spacing(4),
  textDecoration: 'none',
  '&:after': {
    height: 0,
  },
  a: {
    color: theme.palette.neutral.main,
  },
}))

export const SizeSwitcherOptionSelected = styled('div', {
  name: 'ContactLensesRightColumn',
  slot: 'SizeSwitcherOptionSelected',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(40),

  textTransform: 'lowercase',
  color: theme.palette.neutral.main,
  border: `1px solid ${theme.palette.neutral.tones[60]}`,
  borderRadius: theme.spacing(20),
  backgroundColor: theme.palette.common.white,
  textDecoration: 'none',
}))
