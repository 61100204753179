import { Button } from '@components/UI/Button'
import { buttonClasses as MuiButtonClasses, styled } from '@mui/material'

export const AddToCartButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'AddToCart',
  slot: 'Button',
})<{ isStickyBar: boolean }>(({ theme, isStickyBar }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'center',
  height: 48,
  width: '100%',
  whiteSpace: 'nowrap',
  fontSize: 14,
  padding: theme.spacing(isStickyBar ? 2 : 0),

  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}))

export const PdpCtasWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'PdpCtas',
  slot: 'Wrapper',
})<{ isStickyBar?: boolean }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  overflow: 'auto',
  gap: theme.spacing(8),
}))

export const PdpCtasOnlyFewPieces = styled('span', {
  name: 'ProductCtaContainer',
  slot: 'OnlyFewPieces',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
}))

export const SoldOutContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'SoldOut',
  slot: 'Container',
})<{ isStickyBar: boolean }>(({ theme, isStickyBar }) => ({
  maxWidth: '100vw',
  backgroundColor: isStickyBar ? theme.palette.background.light.primary : theme.palette.background.light.secondary,
  padding: isStickyBar ? `${theme.spacing(2)} ${theme.spacing(4)}` : theme.spacing(0),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  gap: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    padding: isStickyBar ? theme.spacing(1) : 0,
    gap: theme.spacing(4),
    justifyItems: 'center',
    marginLeft: 0,
    marginRight: 0,
    button: {
      width: '100%',
      padding: isStickyBar ? `${theme.spacing(0)} ${theme.spacing(6)}` : theme.spacing(0),
    },
  },
}))

export const ComingBackSoonContainer = styled('div', {
  name: 'ComingBackSoon',
  slot: 'Container',
})(({ theme }) => ({
  maxWidth: '100vw',
  backgroundColor: theme.palette.background.light.secondary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  position: 'relative',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    justifyItems: 'center',
    button: {
      width: '100%',
    },
  },
}))

export const AvailabilityTitle = styled('div', {
  name: 'AvailabilityTitle',
  slot: 'Divider',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  justifyContent: 'center',
  lineHeight: 1.4,
  marginBottom: theme.spacing(4),
  color: theme.palette.color.grey,
}))

export const AvailabilityWarning = styled('div', {
  name: 'AvailabilityWarning',
  slot: 'Divider',
})(({ theme }) => ({
  fontSize: 14,
  textAlign: 'center',
  lineHeight: 1.43,
  color: theme.palette.color.grey,
  gap: theme.spacing(4),
  maxWidth: 320,
}))

export const ComingSoonText = styled('p', {
  name: 'AvailabilityWarning',
  slot: 'Text',
})(() => ({
  margin: 0,
}))

export const ComingSoonWarning = styled('div', {
  shouldForwardProp: prop => prop !== 'isStickyBar',
  name: 'CtaPdp',
  slot: 'TextContainer',
})<{ isStickyBar: boolean }>(({ theme, isStickyBar }) => ({
  fontSize: isStickyBar ? 14 : 20,
  textAlign: 'center',
  fontWeight: 600,
  lineHeight: isStickyBar ? 1 : 1.4,
  marginTop: isStickyBar ? theme.spacing(1) : theme.spacing(0),
  marginBottom: isStickyBar ? theme.spacing(1) : theme.spacing(3),
  color: theme.palette.color.grey,

  [theme.breakpoints.up('sm')]: {
    fontSize: isStickyBar ? 16 : 20,
  },
}))

export const StyledButtonsContainer = styled('div', {
  name: 'StyledButtons',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'center',
  width: '100%',
}))

export const StyledRelativeBtn = styled(Button, {
  name: 'RelativeBtn',
  shouldForwardProp: prop => prop !== 'isLoadingCheckout',
})<{ isLoadingCheckout?: boolean }>(({ theme, isLoadingCheckout }) => ({
  gap: isLoadingCheckout ? theme.spacing(4) : 0,
}))

export const StyledPrimaryButton = styled(Button, {
  name: 'CtaPdpPrimaryButton',
  shouldForwardProp: prop => prop !== 'isAddingItem',
})<{ isAddingItem: boolean }>(({ theme, isAddingItem }) => ({
  gap: isAddingItem ? theme.spacing(4) : 0,
  width: '100%',

  //  Contained Primary Variant
  [`&.${MuiButtonClasses.containedPrimary}`]: {
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[50],
    },
  },

  //  Outlined Variant
  [`&.${MuiButtonClasses.outlined}`]: {
    border: `1px solid ${theme.palette.neutral.tones[60]}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[95],
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.tones[90],
    },
    '&:disabled': {
      border: `1px solid ${theme.palette.neutral.tones[90]}`,
    },
  },

  //  Text Variant
  [`&.${MuiButtonClasses.text}`]: {
    '&:hover': {
      backgroundColor: theme.palette.primary.tones[95],
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.tones[90],
    },
    '&:disabled': {
      backgroundColor: theme.palette.background[3],
      color: theme.palette.neutral.tones[60],
    },
  },
}))

export const StyledCTAContainer = styled('div', {
  name: 'StyledButtons',
  slot: 'Container',
})(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
}))

export const StyledRelativeLargeBtn = styled(Button, {
  name: 'StyledButtons',
  slot: 'RelativeLargerBtn',
})(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  height: 48,
  padding: theme.spacing(2),
  whiteSpace: 'nowrap',
}))

export const StyledComingBackSoonContainer = styled('div', {
  name: 'StyledComingBackSoon',
  slot: 'Container',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  gap: theme.spacing(4),

  '& > :first-of-type': {
    marginRight: theme.spacing(1),
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& > :first-of-type': {
      marginRight: 0,
    },
  },
}))

export const NeedPrescriptionText = styled('div', {
  name: 'NeedPrescription',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.43,
  textAlign: 'center',
  color: theme.palette.color.grey,
}))

export const CTAContainer = styled('div')({
  overflow: 'auto',
})

export const LoaderWrapper = styled('div', {
  name: 'Loader',
  slot: 'Wrapper',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})
