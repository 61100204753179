import { StyledFormControlLabel } from '@components/UI/Checkbox/StyledFormControlLabel'
import { StyledRadio } from '@components/UI/Checkbox/StyledRadio'
import { StyledRadioGroup } from '@components/UI/Checkbox/StyledRadioGroup'
import { PlpCompactWidthListingIcon, PlpFullWidthListingIcon } from '@components/UI/Icons'
import { INDICES_MAP } from '@features/plp/algoliaUtils'
import useBreakpoints from '@hooks/useBreakpoints'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import useTheme from '@mui/material/styles/useTheme'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { SortOrderOption } from '@utils/Plp/PlpReducer'
import { isEqual } from '@utils/helpers'
import { useTranslation } from 'next-i18next'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {
  ListingButtonsWrapper,
  PLPFilterSkeletonWrapper,
  SortByContainer,
  SortByContent,
  SortByWrapper,
  StyledCompactListingButton,
  StyledFullWidthListingButton,
} from '../PlpHeader.style'
import { IconChevronDown, IconChevronUp, IconFilter } from '@components/UI/Icons/VD/General'
import { StyledFilterCTA, StyledSortByCTA } from '@components/UI/Pill/Pill.style'

const PLPHeaderFilterAlgolia = forwardRef<
  HTMLDivElement,
  {
    appliedFiltersNumber: number
    catalogLoading?: boolean
    productTotal?: number
    refine: (value: string) => void
    currentRefinement: string
  }
>(({ appliedFiltersNumber, catalogLoading, refine, currentRefinement }, ref) => {
  const { setIndexUiState } = useInstantSearch()
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const { t } = useTranslation()
  const theme = useTheme()
  const { isDesktop } = useBreakpoints()

  const selectedSortOptionDefault =
    plpState.sortOrderOptions.find(option => option.value === currentRefinement)?.id ?? 0
  const [selectedSortOption, setSelectedSortOption] = useState(selectedSortOptionDefault)

  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false)
  const sortByDivRef = useRef<HTMLDivElement>(null)
  const isVisibleNewestSorting = false

  useEffect(() => {
    if (selectedSortOption === selectedSortOptionDefault) return
    setSelectedSortOption(selectedSortOptionDefault)
  }, [selectedSortOptionDefault, selectedSortOption])

  const onSortingSubmit = (sortOption: SortOrderOption) => {
    setIndexUiState(prevState => ({
      ...prevState,
      configure: {
        ...prevState.configure,
        attributesToRetrieve: [
          sortOption.id === INDICES_MAP.PRICE_ASC || sortOption.id === INDICES_MAP.PRICE_DESC ? '*' : 'x_groupkey',
        ],
      },
    }))

    if (isEqual(sortOption.id, selectedSortOption)) {
      return
    }

    setSelectedSortOption(sortOption.id)
    refine(sortOption.value)
    setFilterMenuOpen(false)
  }

  const handleClick = () => {
    setFilterMenuOpen(!filterMenuOpen)
  }

  const handleTouchEnd = e => {
    e.preventDefault()
    setFilterMenuOpen(!filterMenuOpen)
  }

  const renderIcon = () => (filterMenuOpen ? <IconChevronUp /> : <IconChevronDown />)

  const renderContentForMobile = () => {
    return (
      <SortByWrapper ref={ref}>
        <StyledSortByCTA
          data-name="sortBy"
          data-element-id="X_X_MainNav_Filter"
          label={t('ProductFilter.Labels.sortBy')}
          icon={renderIcon()}
          onTouchEnd={handleTouchEnd}
          onClick={handleClick}
        />

        <div ref={sortByDivRef}>
          {filterMenuOpen ? (
            <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
              <SortByContent isVisible={true} filtersApplied={appliedFiltersNumber > 0}>
                <SortByContainer>
                  <StyledRadioGroup defaultValue={selectedSortOption}>
                    {plpState.sortOrderOptions.map(sortOption => {
                      const sortName = sortOption.label.toLowerCase()
                      const sortProfileName = sortOption.profileName
                      const sortValue = sortOption.id ?? '0'
                      if (!isVisibleNewestSorting && sortName === 'newest') return []
                      return (
                        <StyledFormControlLabel
                          data-name={sortName}
                          key={`${sortProfileName}__${sortValue || 0}`}
                          value={sortValue}
                          control={<StyledRadio />}
                          label={t(`ProductGrid.SortOptions.${sortName}`)}
                          onChange={() => onSortingSubmit(sortOption)}
                        />
                      )
                    })}
                  </StyledRadioGroup>
                </SortByContainer>
              </SortByContent>
            </ClickAwayListener>
          ) : null}
        </div>

        {/* Mobile listing views (compact and full) */}
        <ListingButtonsWrapper>
          <StyledCompactListingButton
            aria-label="CompactListingIcon"
            onClick={() => {
              plpDispatch({
                type: 'SET_PRODUCTS_MOBILE_LISTING_LAYOUT',
                payload: 'compact',
              })
            }}
          >
            <PlpCompactWidthListingIcon
              htmlColor={
                plpState.productsMobileListingLayout === 'compact'
                  ? `${theme.palette.custom.cyprus}`
                  : `${theme.palette.custom.boulder}`
              }
            />
          </StyledCompactListingButton>
          <StyledFullWidthListingButton
            aria-label="FullListingIcon"
            onClick={() => {
              plpDispatch({
                type: 'SET_PRODUCTS_MOBILE_LISTING_LAYOUT',
                payload: 'full',
              })
            }}
          >
            <PlpFullWidthListingIcon
              htmlColor={
                plpState.productsMobileListingLayout === 'full'
                  ? `${theme.palette.custom.cyprus}`
                  : `${theme.palette.custom.boulder}`
              }
            />
          </StyledFullWidthListingButton>
        </ListingButtonsWrapper>

        <StyledFilterCTA
          id="filterCTA"
          key="filterCTA"
          data-name="filterBy"
          data-element-id="X_X_MainNav_Filter"
          label={t('ProductFilter.Labels.filterBy')}
          icon={<IconFilter />}
          onClick={() => {
            plpDispatch({
              type: 'TOGGLE_DRAWER_FILTERS',
              payload: true,
            })
          }}
        />
      </SortByWrapper>
    )
  }

  const renderContentForDesktop = () => {
    return (
      <SortByWrapper filtersApplied={appliedFiltersNumber > 0} ref={ref}>
        {catalogLoading ? (
          <PLPFilterSkeletonWrapper>
            <SkeletonTheme baseColor="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
              <Skeleton
                style={{
                  width: 102,
                  height: 34,
                  borderRadius: 40,
                  marginRight: 16,
                }}
              />
              <Skeleton style={{ width: 99, height: 34, borderRadius: 40 }} />
            </SkeletonTheme>
          </PLPFilterSkeletonWrapper>
        ) : (
          <>
            <StyledSortByCTA
              data-name="sortBy"
              data-element-id="X_X_MainNav_Filter"
              label={t('ProductFilter.Labels.sortBy')}
              icon={renderIcon()}
              onClick={handleClick}
            />
            <div ref={sortByDivRef}>
              {filterMenuOpen ? (
                <ClickAwayListener onClickAway={() => setFilterMenuOpen(false)}>
                  <SortByContent isVisible={true} filtersApplied={appliedFiltersNumber > 0}>
                    <SortByContainer>
                      <StyledRadioGroup defaultValue={selectedSortOption}>
                        {plpState.sortOrderOptions.map(sortOption => {
                          const sortName = sortOption.label.toLowerCase()
                          const sortProfileName = sortOption.profileName
                          const sortValue = sortOption.id ?? '0'
                          if (!isVisibleNewestSorting && sortName === 'newest') return []
                          return (
                            <StyledFormControlLabel
                              data-name={sortName}
                              key={`${sortProfileName}__${sortValue || 0}`}
                              value={sortValue}
                              control={<StyledRadio />}
                              label={t(`ProductGrid.SortOptions.${sortName}`)}
                              onChange={() => onSortingSubmit(sortOption)}
                            />
                          )
                        })}
                      </StyledRadioGroup>
                    </SortByContainer>
                  </SortByContent>
                </ClickAwayListener>
              ) : null}
            </div>

            <StyledFilterCTA
              id="filterCTA"
              key="filterCTA"
              data-name="filterBy"
              data-element-id="X_X_MainNav_Filter"
              label={t('ProductFilter.Labels.filterBy')}
              icon={<IconFilter />}
              onClick={() => {
                plpDispatch({
                  type: 'TOGGLE_DRAWER_FILTERS',
                  payload: true,
                })
              }}
            />
          </>
        )}
      </SortByWrapper>
    )
  }

  return !isDesktop ? renderContentForMobile() : renderContentForDesktop()
})

export default PLPHeaderFilterAlgolia
