import { ProductColorSelectorItem } from './ProductColorItem.style'

import { ProductMedia } from '@components/common/Media/ProductMedia'
import React from 'react'
import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import { IProduct } from '../../../types/product'
import { getModelName, getSoldOut } from '../../../utils/productAttributes'

interface IProductColorItem {
  product: IProduct // cluster item
}

const ProductColorItem: React.FC<IProductColorItem> = ({
  product, // cluster item
}) => {
  const soldOutStatus = getSoldOut(product.items?.[0] || product)
  const isSoldOut = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT
  const name = getModelName(product)

  return (
    <ProductColorSelectorItem isSoldOut={isSoldOut}>
      <ProductMedia
        width={240}
        attachments={isSoldOut ? [] : product.attachments}
        usage="PLP"
        sequence="1.0"
        backgroundColor="#FFFFFF"
        alt={name}
        fetchpriority="high"
      />
    </ProductColorSelectorItem>
  )
}

export default ProductColorItem
