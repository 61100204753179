import { LinkProps } from 'next/link'
import { StyledLinkListItem } from './LinkListItem.style'
import React from 'react'

type LinkListItemProps = {
  href: string
  isAccented?: boolean
  dataName?: string
  dataElementId?: string
  dataDescription?: string
  children: React.ReactNode
  locale: LinkProps['locale']
  isLocaleDomain: boolean
  locationOrigin: string
}

const LinkListItem: React.FC<LinkListItemProps> = ({
  href = '/',
  isAccented = false,
  dataName,
  dataElementId,
  dataDescription,
  children,
  locale,
  isLocaleDomain,
  locationOrigin,
}) => {
  return (
    <StyledLinkListItem
      prefetch={false}
      href={href}
      isAccented={isAccented}
      data-name={dataName}
      data-element-id={dataElementId}
      data-description={dataDescription}
      locale={locale as string}
      isLocaleDomain={isLocaleDomain}
      locationOrigin={locationOrigin}
    >
      {children}
    </StyledLinkListItem>
  )
}

export default LinkListItem
