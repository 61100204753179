import { ButtonAsLink } from '@components/UI/Button'
import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV1, Z_INDEX_LV2 } from '../../../constants/ui'
import Link from '@components/UI/Link'

export const StyledColorVariantsButtons = styled('div', {
  name: 'ColorVariants',
  slot: 'Buttons',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}))

export const StyledProductTileContactLensesAnnualSupplySaveAmount = styled('div', {
  name: 'ProductTile',
  slot: 'ContactLensesAnnualSupplySaveAmount',
})(() => ({
  fontWeight: 'bold',
  textTransform: 'uppercase',
}))

export const StyledProductTileContactLensesAnnualSupplyWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ContactLensesAnnualSupplyWrapper',
  shouldForwardProp: prop => prop !== 'isFull',
})<{ isFull?: boolean }>(({ theme, isFull }) => ({
  fontSize: isFull ? 16 : 10,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),

  [theme.breakpoints.up('xs')]: {
    flexDirection: isFull ? 'row' : 'column',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const StyledProductSubWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ProductSubWrapper',
  shouldForwardProp: prop => prop !== 'fullLayout',
})<{ fullLayout?: boolean }>(({ theme }) => ({
  position: 'relative',
  aspectRatio: '1',
  cursor: 'pointer',

  '&::before': {
    content: '""',
    paddingTop: '100%',

    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  [theme.breakpoints.up('sm')]: {
    height: 'auto',
  },
}))

export const StyledProductSubWrapperGV = styled('div', {
  name: 'ProductTile',
  slot: 'ProductSubWrapperGV',
  shouldForwardProp: prop => prop !== 'fullLayout',
})<{ fullLayout?: boolean }>(({}) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

export const StyledProductAnchor = styled(Link, {
  name: 'ProductAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: prop => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile = true, theme }) => ({
  width: '100%',
  paddingTop: isClRelatedTile ? theme.spacing(8) : 0,
  fontSize: 0,

  '&::after': {
    height: '0',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(6) : 'inherit',
  },
}))

export const StyledProductDescriptionAnchor = styled(Link, {
  name: 'ProductAnchor',
  slot: 'AnchorElement',
  shouldForwardProp: prop => prop !== 'isClRelatedTile',
})<{ isClRelatedTile?: boolean }>(({ isClRelatedTile = true, theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(isClRelatedTile ? 8 : 0),
  fontSize: 0,
  color: theme.palette.neutral.main,
  textDecoration: 'none',
  alignItems: 'center',

  '&::after': {
    height: '0',
  },

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: isClRelatedTile ? theme.spacing(6) : 'inherit',
  },

  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))

export const StyledSquare = styled('div', {
  name: 'StyledSquare',
  slot: 'Divider',
})(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const StyledSquareGV = styled('div', {
  name: 'StyledSquare',
  slot: 'DividerGV',
  shouldForwardProp: prop => prop !== 'isSliderView',
})<{ isSliderView?: boolean }>(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '.swiper': {
    paddingBottom: 0,
  },
  '.product-tile-image': {
    flex: 0,
  },
  '.product-tile__inner': {
    paddingTop: 0,
  },
}))

export const StyledProductTileWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'TileWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minHeight: '220px',
  height: '100%',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    '&:hover': {
      '& > div': {
        visibility: 'visible',
      },
    },
  },

  '&.product-tile-ymal': {
    position: 'relative',
    aspectRatio: '1/1',

    '@supports not (aspect-ratio: 1 / 1)': {
      minHeight: 356,
    },
  },
}))

export const StyledProductTileHeader = styled('div', {
  name: 'ProductTile',
  slot: 'Header',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV1,
  width: '100%',
  top: '1rem',
  left: '1rem',
  right: '1rem',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'none',

  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(3),
    alignSelf: 'end',
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(8),
  },
}))

export const StyledProductTilePopularLabel = styled('div', {
  name: 'ProductTile',
  slot: 'PopularLabel',
})(({ theme }) => ({
  fontSize: 10,
  textTransform: 'uppercase',
  fontWeight: 'bold',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: theme.spacing(0),
  },
}))

export const PrimaryBadgeSpacePlaceholder = styled('div', {
  name: 'ProductTile',
  slot: 'Divider',
})(({ theme }) => ({
  padding: theme.spacing(1.8),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2.8),
  },
}))

export const StyledProductTileAttributes = styled('div', {
  name: 'ProductTile',
  slot: 'Attributes',
})(({ theme }) => ({
  fontSize: 10,
  lineHeight: 1.6,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'auto',

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
  },
}))

export const StyledProductTileBadgeIcons = styled('div', {
  name: 'ProductTile',
  slot: 'BadgeIcons',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > svg:not(:last-child)': {
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.625rem',
    },
    marginRight: 0,
  },

  svg: {
    color: theme.palette.custom.black,
  },
}))

export const StyledProductTileImageContainer = styled('div', {
  name: 'ProductTile',
  slot: 'ImageContainer',
  shouldForwardProp: prop => prop !== 'isDisplayed' && prop !== 'isClRelatedTile' && prop !== 'isFull',
})<{
  isDisplayed?: boolean
  isClRelatedTile?: boolean
  isFull?: boolean
}>(({ isClRelatedTile = true, theme, isFull }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
    alignSelf: isFull ? 'end' : 'center',
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: isClRelatedTile ? theme.spacing(6) : 0,
  },
}))

export const StyledProductTileImageContainerGV = styled('div', {
  name: 'ProductTile',
  slot: 'ImageContainer',
  shouldForwardProp: prop => prop !== 'isDisplayed' && prop !== 'isClRelatedTile' && prop !== 'isFull',
})<{
  isDisplayed?: boolean
  isClRelatedTile?: boolean
  isFull?: boolean
}>(({ isClRelatedTile = true, theme, isFull }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    paddingTop: 0,
    alignSelf: isFull ? 'end' : 'center',
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: isClRelatedTile ? theme.spacing(6) : 0,
  },
}))

export const StyledColorVariantsBtn = styled(ButtonAsLink, {
  name: 'ColorVariants',
  slot: 'AnchorButton',
})({
  fontSize: 0,

  '&:disabled': {
    opacity: 0.5,
  },
})

export const StyledColorVariantName = styled('div', {
  name: 'ColorVariant',
  slot: 'TextField',
})(({ theme }) => ({
  flex: 1,
  fontSize: '1rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
}))

export const StyledProductTileFooterWrapper = styled('div', {
  name: 'ColorVariants',
  slot: 'FooterWrapper',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  height: '100%',
}))

export const StyledProductTileFooter = styled('div', {
  name: 'ProductTile',
  slot: 'Footer',
})(({ theme }) => ({
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'row',
  paddingTop: theme.spacing(3),

  '& > div': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& > div:nth-of-type(2)': {
    flex: 0,

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
}))

export const StyledProductTileDescription = styled('div', {
  name: 'ProductTile',
  slot: 'Description',
  shouldForwardProp: prop => prop !== 'isPDP',
})<{ isPDP: boolean | null }>(({ isPDP }) => ({
  alignItems: isPDP ? 'center' : 'flex-start',
  overflow: 'auto',
  textAlign: 'center',
}))

export const StyledProductName = styled('span', {
  name: 'ProductTile',
  slot: 'ProductName',
  shouldForwardProp: prop => prop !== 'type',
})<{ type?: string }>(({ type }) => ({
  fontSize: type === 'contact-lenses' || type === 'contact-lenses-accessories' ? 14 : 10,
  fontWeight: type === 'contact-lenses' || type === 'contact-lenses-accessories' ? 600 : 'normal',
  lineHeight: 1.43,
  whiteSpace: 'normal',
  overflow: 'auto',
  textAlign: 'center',
}))

export const StyledProductBrandName = styled('span', {
  name: 'ProductTile',
  slot: 'ProductBrandName',
})(({ theme }) => ({
  lineHeight: 1,
  fontWeight: 600,
  marginTop: theme.spacing(0.5),
  overflow: 'hidden',
  textAlign: 'left',
  textTransform: 'capitalize',

  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
    lineHeight: 2,
  },
}))

export const StyledProductVariants = styled('div', {
  name: 'ProductTile',
  slot: 'ProductVariants',
  shouldForwardProp: prop => prop !== 'isDisplayed',
})<{ isDisplayed?: boolean }>(({ isDisplayed = true, theme }) => ({
  fontSize: 10,
  height: 'fit-content',
  display: isDisplayed ? 'flex' : 'none',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(5),
    alignSelf: 'center',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
    marginBottom: theme.spacing(16.5), // DC-202, DC-1667
  },
}))

export const StyledNoProductVariants = styled('div', {
  name: 'ProductNoTile',
  slot: 'ProductNoVariants',
})({
  height: 21,
})

export const StyledLoadingWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'LoadingWrapper',
})(({ theme }) => ({
  zIndex: Z_INDEX_LV2,
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  aspectRatio: '1 / 1',
  backgroundColor: theme.palette.background.light.secondary,
}))

export const StyledSkeletonWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'SkeletonWrapper',
})({
  flex: 0,
  // minHeight: 100,
  width: '100%',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const StyledImageWrapper = styled('div', {
  name: 'ProductTile',
  slot: 'ImageWrapper',
  shouldForwardProp: prop => prop !== 'shouldZoomOnHover',
})<{ shouldZoomOnHover?: boolean }>(({ theme, shouldZoomOnHover }) => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-block',
  fontSize: 0,
  width: '100%',
  // paddingBottom: theme.spacing(3),

  [theme.breakpoints.up('md')]: {
    // paddingBottom: theme.spacing(6),

    '.lazy-load-image-loaded': {
      height: '100%',
      width: '100%',
      transition: 'transform .5s',
    },

    '&:hover .lazy-load-image-loaded, &:focus .lazy-load-image-loaded': {
      transform: shouldZoomOnHover ? 'scale(1.1)' : undefined,
    },
  },
}))

export const StyledColorSelectorContainer = styled('div', {
  name: 'ColorSelector',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'shouldZoomOnHover',
})<{ shouldZoomOnHover?: boolean }>(({}) => ({
  width: '100%',
  height: '100%',
  maxHeight: '27px',
  display: 'flex',
  justifyContent: 'center',
  gap: '4px',
  marginTop: '12px',
}))

export const StyledColorSelector = styled('div', {
  name: 'ColorSelector',
  slot: 'Selector',
  shouldForwardProp: prop => prop !== 'isActiveCluster',
})<{ isActiveCluster: boolean }>(({ theme, isActiveCluster }) => ({
  maxHeight: '23px',
  maxWidth: '40px',
  opacity: isActiveCluster ? 1 : 0.4,
  border: `1px solid ${isActiveCluster ? theme.palette.custom.gv.blue : theme.palette.custom.gv.grey2}`,
  borderRadius: '3px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 1,
    borderColor: theme.palette.custom.gv.black2,
  },
}))
