import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { ICMAlgolia } from '@typesApp/cmsPlacement/CMAlgolia'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMProductList } from '@typesApp/cmsPlacement/CMProductList'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { TAlgoliaInit, TAlgoliaInitChildren } from '../types'
import { AlgoliaInit } from './Init'

type TAlgoliaCmsProductInit = {
  placement: IPlacement | ICMCollection | ICMProductList | ICMAlgolia
} & TAlgoliaInitChildren
export const AlgoliaCmsProductInit: React.FC<TAlgoliaCmsProductInit> = ({ children, placement }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const item = 'items' in placement ? placement.items?.[0] : []
  const groupedRule = item?.['groupedRule'] || ''
  const ungroupedRule = item?.['ungroupedRule'] || ''

  const isEmptyString = (rule: string) => {
    return rule === ''
  }
  const isGrouped = () => {
    if (isEmptyString(groupedRule)) {
      return false
    }
    return true
  }

  const rules = isEmptyString(groupedRule) ? [ungroupedRule] : [groupedRule]
  const initIndexNameParams = {
    locale: langCountry,
    isGrouped: isGrouped(),
  }
  const displayProductsCount = 'displayProductsCount' in item ? +item?.displayProductsCount : 3

  const props: TAlgoliaInit = {
    checkForEmptyQuery: false,
    initIndexNameParams,

    configure: {
      facets: ['*'],
      clickAnalytics: true,
      hitsPerPage: displayProductsCount,
      ruleContexts: rules,
    },
  }

  return <AlgoliaInit {...props}>{children}</AlgoliaInit>
}
