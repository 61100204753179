import React from 'react'
import IconWrapper from '../IconWrapper'
import type { CustomSVGIconProps } from '../icon.type'

export const EyeCareIcon: React.FC<CustomSVGIconProps> = ({ width = 25, height = 25, ...rest }) => {
  return (
    <IconWrapper viewBox={`0 0 ${width} ${height}`} width={width} height={height} fill="none" {...rest}>
      <path
        d="M13.13 6.23V3a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v3.23A5.001 5.001 0 0 0 4.63 11v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V11a5.002 5.002 0 0 0-3.5-4.77zm-4-.23V3h3v3h-3zm6.5 17h-10V11c0-2.206 1.794-4 4-4h2c2.205 0 4 1.794 4 4v12z"
        fill="#000"
      />
    </IconWrapper>
  )
}

export const EyeIcon: React.FC<CustomSVGIconProps> = ({ width = 48, height = 48, htmlColor, viewBox, ...rest }) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M24 14c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
        fill={htmlColor}
      />
      <path d="M24 26a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill={htmlColor} />
      <path
        d="M47.781 23.083C43.202 14.101 34.272 8 24 8 13.728 8 4.798 14.101.219 23.083a2.031 2.031 0 0 0 0 1.835C4.798 33.899 13.728 40 24 40c10.272 0 19.202-6.101 23.781-15.083a2.03 2.03 0 0 0 0-1.834zM24 38c-9.17 0-17.6-5.361-21.999-14.009C6.4 15.361 14.83 10 24 10c9.17 0 17.6 5.361 21.999 14.009C41.6 32.639 33.17 38 24 38z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const EyewearRangeIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  viewBox,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M2 8h16v2h2V8h8v2h2V4h-2v2h-8V4h-2v2H2V4H0v6h2V8zM47.58 20.523c-.916-3.487-3.239-5.759-6.394-6.235A33.544 33.544 0 0 0 37 14.007V14H11v.007c-1.453.007-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-27.646-.156c-1.353 5.066-5.446 9.789-9.733 11.228-1.44.483-3.52.775-5.253-.574-2.426-1.895-3.565-6.282-2.593-9.99.413-1.574 1.577-4.285 4.72-4.76A31.574 31.574 0 0 1 11 15.998V16h.416c3.732.034 6.777.81 8.002 2.165.56.619.721 1.328.516 2.202zm2.061-1.013a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.12a5.583 5.583 0 0 0-.96.823 4.161 4.161 0 0 0-1.093 2.531A5.97 5.97 0 0 0 24 19c-.69 0-1.365.127-2.004.354zm21.056 11.667c-1.733 1.35-3.813 1.058-5.253.574-4.287-1.439-8.38-6.161-9.727-11.199-.212-.903-.05-1.612.51-2.231 1.227-1.356 4.278-2.132 8.008-2.165H37v-.001c1.247.007 2.561.091 3.907.269 3.161.478 4.325 3.189 4.738 4.763.972 3.707-.167 8.095-2.593 9.99z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}
