import { PRODUCT_TYPES_MAP } from '@constants/product'
import { IProduct } from '@typesApp/product'
import { cloneDeep } from '@utils/helpers'
import { getBadges, getModelCode, getModelName, getProductType } from '@utils/productAttributes'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import { ProductImageProps } from '../ProductImage/ProductImage'
import {
  PrimaryBadgeSpacePlaceholder,
  StyledImageWrapper,
  StyledNoProductVariants,
  StyledProductAnchor,
  StyledProductDescriptionAnchor,
  StyledProductSubWrapper,
  StyledProductTileAttributes,
  StyledProductTileContactLensesAnnualSupplySaveAmount,
  StyledProductTileContactLensesAnnualSupplyWrapper,
  StyledProductTileDescription,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
  StyledProductTileHeader,
  StyledProductTileImageContainer,
  StyledProductTilePopularLabel,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledSquare,
} from './components/ProductTile.style'

import { IconButton } from '@components/UI/IconButton'
import { setLastSelectedProduct } from '@features/ui/action'
import { productsMobileListingLayoutSelector } from '@features/ui/selector'
import { usePageType } from '@foundation/hooks/usePageType'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { useAnnualSupplyPLP } from '@pages_views/ProductDetails/hooks/useAnnualSupply'
import { plpBadgesSelector } from '@redux/selectors/site'
import CurrencyService from '@services/CurrencyService'
import ProductUtils from '@utils/ProductUtils'
import { isAccessories, isCLAccessories, isContactLenses } from '@utils/product'
import { seoUrlConcat } from '@utils/url'
import { useSelector } from 'react-redux'
import ProductTileGallery from './components/ProductTileGallery'

import { ProductMedia } from '@components/common/Media/ProductMedia'
import config from '@configs/index'
import { NOT_FOUND } from '@constants/routes'
import { useGetProductImageWidth } from '@hooks/useGetProductImageWidth'
import ProductPriceNew from '@pages_views/ProductDetails/components/ProductPriceNew'
import { useWishlist } from '@pages_views/ProductDetails/hooks/useWishlist'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import {
  getProductPriceByCustomerSegments,
  parseProductTypeForPrice,
} from '@components/common/UI/ProductPrice/utils/utils'
import { Typography } from '@mui/material'

const getItems = (product: IProduct) => {
  return product.sKUs != null ? product.sKUs : product.items
}

export interface ProductTileProps<T extends IProduct = any> {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: T
  onClick?: () => void
  tileIndex: number
  variant?: string
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
  isPDP?: boolean
  isCompact?: boolean
}

const siteName = config.name

const ProductTile = <T extends IProduct>({
  footerElementsDisplay = { colors: true, inWishlistAdded: false },
  product,
  onClick,
  tileIndex,
  variant,
  isClustered = true,
  isPDP = false,
}: ProductTileProps<T>) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pageType } = usePageType()
  const { isRXEnabled } = useStoreIdentity()
  const { isDesktop } = useBreakpoints()

  const customerSegments = useCustomerSegmentsUtil()
  const prices = getProductPriceByCustomerSegments(product?.x_price, customerSegments)
  const currency = CurrencyService.getSymbolByName(prices?.currency || '')
  const productType = (product !== null && PRODUCT_TYPES_MAP[getProductType(product)?.toLowerCase()]) || 'frames'
  const badgeSelector = useSelector(plpBadgesSelector)

  const [clusters, setClusters] = useState<IProduct[]>() // items when products are not clustered
  const [currentProduct, setCurrentProduct] = useState<IProduct>()

  const [primaryBadge, setPrimaryBadge] = useState<string>('')
  const [secondaryBadges, setSecondaryBadges] = useState<string>('')
  const [productPriceProps, setProductPriceProps] = useState({
    x_price: {},
  })
  const [carouselMode, setCarouselMode] = useState<boolean>(false)
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const { determineWishListAction } = useWishlist(currentProduct)

  const onProductTileMouseEnter = () => {
    if (clusterLength > 0 && (carouselMode || mobileListingLayoutBasedOnPages === 'full' || isDesktop)) {
      setCarouselMode(true)
    }
  }

  const onProductTileMouseLeave = () => {
    if (carouselMode) {
      setCarouselMode(false)
    }
  }

  const productsMobileListingLayout = useSelector(productsMobileListingLayoutSelector)
  const mobileListingLayoutBasedOnPages = pageType === 'search' ? 'compact' : productsMobileListingLayout

  const clusterViewLength = clusters?.length ?? 1
  const selectedViewCluster = useMemo<IProduct>(
    () => (clusters ? clusters[selectedClusterIndex] : product),
    [clusters?.length, selectedClusterIndex, product?.id]
  )

  const isGroupingPLP: boolean = product && !!product.cluster && !product.items
  const clusterLength = clusters?.length ?? 0

  const isMoCoSliderRenderedForAccessories = isAccessories(productType) && clusterLength > 1
  const isMoCoSliderRendered =
    (clusters && !isAccessories(productType) && !isContactLenses(productType) && !isCLAccessories(productType)) ||
    isMoCoSliderRenderedForAccessories

  const isFullListeningLayout = productsMobileListingLayout === 'full'

  const isMoCoSliderDisplayed = isMoCoSliderRendered && (carouselMode || mobileListingLayoutBasedOnPages === 'full')

  const productItems = getItems(product)
  const name = getModelName(product)
  const annualSupplyData = useAnnualSupplyPLP(product).find(data => data.timePeriod === '12')

  const modelCode = getModelCode(product)
  const { seo } = getItems(selectedViewCluster)?.[0] || (productItems && productItems[0]) || product

  const annualSupplyBadges =
    parseInt(annualSupplyData?.originalBoxPrice || '') * parseInt(annualSupplyData?.timePeriod || '') -
    parseInt(annualSupplyData?.discountedBoxPrice || '') * parseInt(annualSupplyData?.timePeriod || '')

  const tileDataElementId = useMemo(() => {
    switch (pageType) {
      case 'search':
        return `X_X_SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }, [pageType])

  const productImageWidth = useGetProductImageWidth(variant)

  const onImageClick = () => {
    dispatch(setLastSelectedProduct(product.id || ''))
  }

  const commonProductImageProps = {
    alt: t('ProductTile.Labels.AltPrefix', { name }),
    sequence: '1.0',
    usage: 'PLP' as const,
    width: productImageWidth,
    onClick: onImageClick,
  }

  /**
   * One cluster is one product's MOCO (model color).
   *
   * 1. Determine the need of clusters sorting. Is there are any discounted clusters (MOCOs)
   *    or any polarized clusters?
   *    (yes) Should be sorted (move to step 2).
   *    (no) Should be displayed in the order fetched from BE (skip step 2, move to step 3).
   * 2. Sort clusters by following parameters:
   *    2.1. Has discount (A goes earlier than B, if A has discount and B hasn't, despite further params)
   *    2.2. Lower price (ascending sort by 'offer' price, despite further params)
   *    2.3. Is polarized (A goes earlier than B, if A is polarized and B isn't)
   * 3. Determine the content of price label. Does product have any clusters with discount?
   *    (yes) Show price of selected cluster.
   *    (no) Show 'from #lowest_price_of_all_clusters' label, do not react on selected cluster change.
   */
  useEffect(() => {
    const clonedProducts = cloneDeep<IProduct[]>(
      (product.cluster ?? (isClustered ? product.items : product?.items?.slice(0, 1))) || []
    )

    const newClusters = clonedProducts.map(cl => {
      if (cl.seo) return cl
      // Get seo on one hierarchy level higher
      return {
        ...cl,
        seo: product.seo,
      }
    })

    setClusters(newClusters)

    const newClustersLength = newClusters?.length ?? 0
    if (!newClustersLength) {
      setProductPriceProps({
        x_price: product?.x_price || {},
      })
    } else {
      setProductPriceProps({
        x_price: newClusters[0].x_price,
      })
    }
  }, [product.cluster, product.items, isClustered])

  useEffect(() => {
    let currentSku: IProduct | undefined
    if (clusterLength) {
      const items = getItems(selectedViewCluster)

      if (items) {
        currentSku = items[0]
      } else {
        currentSku = product
      }
    } else {
      currentSku = product
    }

    if (!currentSku) {
      return
    }

    setCurrentProduct(currentSku)

    const { primaryBadge, secondaryBadges } = getBadges(currentSku, t, badgeSelector, isRXEnabled)

    setPrimaryBadge(primaryBadge)
    setSecondaryBadges(secondaryBadges)
  }, [product, selectedViewCluster])

  const getMocoLinkTo = (product: IProduct | undefined) => {
    let href = ''
    if (productType === 'contact-lenses' || productType === 'contact-lenses-accessories') {
      href = product?.seo?.href || NOT_FOUND
    } else {
      const items = product && getItems(product)
      href = seo?.href || items?.[0]?.seo?.href || NOT_FOUND
    }
    // ernsure that seourl starts with a slash
    href = href?.startsWith('/') ? href : '/' + href
    // const finalHref = seoUrlConcat(langCode, href)
    const finalHref = seoUrlConcat('', href)
    return finalHref
  }

  const commonGalleryProductImageProps: ProductImageProps = {
    sequence: productType === 'accessories' ? '1.0' : '2.0',
    usage: 'PLP',
  }

  const getAttachments = (cluster: IProduct) => {
    const retvalue = cluster.attachments
    return retvalue
  }

  const handleWishlistAction = () => {
    determineWishListAction()
  }

  return (
    product && (
      <StyledProductTileWrapper
        onMouseEnter={onProductTileMouseEnter}
        onMouseLeave={onProductTileMouseLeave}
        aria-label={primaryBadge || `${name}_${modelCode}`}
        data-element-id={tileDataElementId}
        data-description={`${siteName}_${name}_${modelCode}`}
        className={`${variant === 'suggested' ? 'ProductTile-ymal' : 'ProductTile'}`}
      >
        <StyledProductSubWrapper fullLayout={carouselMode || mobileListingLayoutBasedOnPages === 'full'}>
          <StyledSquare onClick={onClick}>
            <>
              <StyledProductTileHeader className="product-tile__header">
                {productType === 'contact-lenses' && annualSupplyBadges ? (
                  <StyledProductTileContactLensesAnnualSupplyWrapper isFull={isFullListeningLayout}>
                    <StyledProductTileContactLensesAnnualSupplySaveAmount>
                      {t('ProductTile.Labels.SaveAmountLine1', {
                        currency: currency,
                        amount: annualSupplyBadges,
                      })}
                    </StyledProductTileContactLensesAnnualSupplySaveAmount>
                    {t('ProductTile.Labels.SaveAmountLine2')}
                  </StyledProductTileContactLensesAnnualSupplyWrapper>
                ) : (
                  <StyledProductTilePopularLabel>
                    {primaryBadge ? primaryBadge : <PrimaryBadgeSpacePlaceholder />}
                  </StyledProductTilePopularLabel>
                )}
                <StyledProductTileAttributes>{secondaryBadges}</StyledProductTileAttributes>
              </StyledProductTileHeader>
              {isMoCoSliderRendered && clusters && isMoCoSliderDisplayed && (
                <ProductTileGallery
                  isDisplayed={isMoCoSliderDisplayed}
                  mocoLink={getMocoLinkTo(selectedViewCluster)}
                  tileDataElementId={tileDataElementId}
                  priorityBadgeText={primaryBadge}
                  commonImageProps={commonGalleryProductImageProps}
                  currentProductIndex={selectedClusterIndex}
                  onIndexChange={setSelectedClusterIndex}
                  cluster={isClustered ? clusters : [product]}
                />
              )}

              <>
                <StyledProductTileImageContainer
                  isFull={isFullListeningLayout}
                  className={`product-tile-image${variant === 'cms-products-module' ? ' product-tile__hp' : ''}`}
                  isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
                  isClRelatedTile={
                    isCLAccessories(productType) || isContactLenses(productType) || isAccessories(productType)
                  }
                >
                  <StyledProductAnchor
                    aria-label={primaryBadge || `${name}_${modelCode}`}
                    href={getMocoLinkTo(selectedViewCluster)}
                    className="product-tile__inner"
                    data-element-id={tileDataElementId}
                    data-description={`${siteName}_${name}_${modelCode}`}
                    isClRelatedTile={
                      isCLAccessories(productType) || isContactLenses(productType) || isAccessories(productType)
                    }
                  >
                    <StyledImageWrapper
                      shouldZoomOnHover={
                        isCLAccessories(productType) || isContactLenses(productType) || isAccessories(productType)
                      }
                    >
                      <ProductMedia
                        attachments={
                          isGroupingPLP && selectedViewCluster
                            ? getAttachments(selectedViewCluster)
                            : !isGroupingPLP && product
                              ? getAttachments(product)
                              : undefined
                        }
                        {...commonProductImageProps}
                      />
                    </StyledImageWrapper>
                  </StyledProductAnchor>
                </StyledProductTileImageContainer>

                {footerElementsDisplay?.colors && (
                  <StyledProductVariants
                    className="product-tile__colors"
                    isDisplayed={!(isMoCoSliderRendered && isMoCoSliderDisplayed)}
                  >
                    {clusterViewLength > 1 ? (
                      `${clusterViewLength} ${t('ProductTile.Labels.colors').toUpperCase()}`
                    ) : ProductUtils.getProductColorLabel(currentProduct!) ? (
                      ProductUtils.getProductColorLabel(currentProduct!)
                    ) : (
                      <StyledNoProductVariants></StyledNoProductVariants>
                    )}
                  </StyledProductVariants>
                )}
              </>
            </>
          </StyledSquare>
        </StyledProductSubWrapper>
        <StyledProductTileFooterWrapper className={`${variant === 'cms-products-module' ? 'hp-variant' : ''}`}>
          <StyledProductTileFooter className="product-tile__bottom">
            <StyledProductTileDescription isPDP={isPDP}>
              <StyledProductDescriptionAnchor
                aria-label={primaryBadge || `${name}_${modelCode}`}
                href={getMocoLinkTo(selectedViewCluster)}
                className="product-tile__inner"
                data-element-id={tileDataElementId}
                data-description={`${siteName}_${name}_${modelCode}`}
                isClRelatedTile={
                  isCLAccessories(productType) || isContactLenses(productType) || isAccessories(productType)
                }
              >
                <Typography variant="button">{name ? name : '\u00A0'}</Typography>

                {productPriceProps && (
                  <ProductPriceNew
                    isPDP={isPDP}
                    productType={parseProductTypeForPrice(productType)}
                    isCompact={mobileListingLayoutBasedOnPages === 'full' ? false : true}
                    price={getProductPriceByCustomerSegments(
                      currentProduct?.x_price || productPriceProps.x_price || null,
                      customerSegments
                    )}
                  />
                )}
              </StyledProductDescriptionAnchor>
            </StyledProductTileDescription>
            <div>
              {productType !== 'contact-lenses' && (
                <IconButton
                  aria-label="Wishlist"
                  data-element-id={'X_X_WishList_Remove'}
                  onClick={handleWishlistAction}
                ></IconButton>
              )}
            </div>
          </StyledProductTileFooter>
        </StyledProductTileFooterWrapper>
      </StyledProductTileWrapper>
    )
  )
}

export { ProductTile }
