import React from 'react'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import styles from './styles/index.module.scss'
import { CmsMedia } from '../CmsMedia'
import { ImageCrops } from '../CmsMedia/types'
import { TextModule } from '../TextModule'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { CmsFullBannerAnchor } from '../CmsFullBannerAnchor'
import { getToLink } from '@components/common/helpers/cms'

const imageCrops: ImageCrops = {
  xxl: {
    crop: 'landscape_ratio863x416',
    width: 1726,
    height: 832,
  },
  xl: {
    crop: 'landscape_ratio755x361',
    width: 1510,
    height: 722,
  },
  lg: {
    crop: 'landscape_ratio317x149',
    width: 1141,
    height: 536,
  },
  md: {
    crop: 'portrait_ratio343x359',
    width: 617,
    height: 646,
  },
  sm: {
    crop: 'portrait_ratio343x345',
    width: 617,
    height: 621,
  },
}

type TCmsBannerTile = {
  placement: IPlacement
  customerSegments: string[]
}

export const CmsBannerTile: React.FC<TCmsBannerTile> = ({ placement, customerSegments }) => {
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || 'default'
  const item = placement?.items?.find(isLXTeaser)

  const { media } = item || {}

  const toLink = getToLink(item)

  if (!item) return null
  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <div className={styles['banner-tile-wrapper']} {...metaData}>
      <CmsFullBannerAnchor toLink={toLink} bannerIndex={1} teaserIndex={1}>
        <CmsMedia media={media} imageCrops={imageCrops} hasOverlay={false} />
      </CmsFullBannerAnchor>
      <div className={styles['content-tiles']}>
        <TextModule placement={placement} teaserIndex={1} overrideTeaserOverlayTextAlign="left" showCtas={false} />
      </div>
      <ShoppableTeaser
        hotZonesSettings={item?.hotZonesSettings}
        hotZones={item?.hotZones}
        customerSegments={customerSegments}
      />
    </div>
  )
}
