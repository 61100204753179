import { useEffect, useState } from 'react'

import { sum } from '@utils/helpers'
import { OrderItemContactLensData } from '../../types/cart'
import { IOrderItem } from '../../types/order'
import { getOrderItemContactLensesData } from '../../utils/order'
/**
 * Contact lens product info data hook
 * displays contact lens order item info,quantity
 * @param { IOrderItem } orderItem current cart order item
 */

export const useContactLensCartItemData = ({ orderItem }: { orderItem?: IOrderItem }) => {
  const [orderItemClData, setOrderItemClData] = useState<OrderItemContactLensData | null>({
    left: {
      data: null,
      quantity: null,
      orderItemId: null,
    },
    right: {
      data: null,
      quantity: null,
      orderItemId: null,
    },
  })

  const [totalBoxes, setTotalBoxes] = useState<number | null>(null)

  useEffect(() => {
    const orderItemClData: OrderItemContactLensData | null = !!orderItem
      ? getOrderItemContactLensesData(orderItem, ['x_productId', 'x_partNumber', 'x_dominance'])
      : null

    const rightEyeQuantity = orderItemClData?.right.quantity
    const leftEyeQuantity = orderItemClData?.left.quantity

    const totalBoxes = sum([parseInt(leftEyeQuantity || '0'), parseInt(rightEyeQuantity || '0')])

    setOrderItemClData(orderItemClData)
    setTotalBoxes(totalBoxes)
  }, [orderItem])

  return {
    orderItemClData,
    setOrderItemClData,
    totalBoxes,
  }
}
