import { Divider, Typography, DividerClasses, dividerClasses } from '@mui/material'
import {
  TextField as MuiTextField,
  inputBaseClasses as MuiInputBaseClasses,
  outlinedInputClasses as MuiOutlinedInputClasses,
  InputAdornment as MuiInputAdornment,
  inputAdornmentClasses as MuiInputAdornmentClasses,
  inputLabelClasses as MuiInputLabelClasses,
  typographyClasses as MuiTypographyClasses,
} from '@mui/material'
import styled from '@mui/material/styles/styled'

export const ContactLensesDataWrapper = styled('div', {
  name: 'ContactLensesData',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(10),
  rowGap: theme.spacing(4),
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(12),
    rowGap: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}))

export const ContactLensesSelectPrescriptionTitle = styled(Typography, {
  name: 'ContactLensesData',
  slot: 'SelectPrescriptionTitle',
})(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    color: theme.palette.neutral.tones[80],
  },
}))

export const ContactLensesInput = styled('input', {
  name: 'ContactLensesData',
  slot: 'Input',
})<{
  disabled: boolean
}>(({ theme, disabled }) => ({
  height: '33px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '12px',
  backgroundColor: theme.palette.background.light.primary,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.custom.boulder}`,
  opacity: disabled ? '0.5' : '1',
}))
export const ContactLensesInputWrapper = styled('div', {
  name: 'ContactLensesData',
  slot: 'InputWrapper',
})(() => ({}))

export const ContactLensesDataColumn = styled('div', {
  name: 'ContactLensesData',
  slot: 'Column',
})(({ theme }) => ({
  gridAutoRows: '1fr',
  display: 'grid',
  alignItems: 'center',
  rowGap: theme.spacing(6),
  fieldset: {
    top: '0px',
  },
}))

export const ContactLensesDataLabel = styled(Typography, {
  name: 'ContactLensesData',
  slot: 'Label',
})(({ theme }) => ({}))

export const ContactLensesListHeadingContainer = styled('div', {
  name: 'ContactLensesData',
  slot: 'ListHeadingContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.dark.secondary,
  display: 'flex',
  justifyContent: 'space-evenly',
  position: 'relative',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const ContactLensesListHeadingItem = styled('span', {
  name: 'ContactLensesData',
  slot: 'ListHeading',
})(({ theme }) => ({
  fontSize: '0.75rem',
  width: '100%',
  listStyle: 'none',
  textAlign: 'center',
  lineHeight: '33px',
  margin: '0',
  padding: '0',
  backgroundColor: theme.palette.background[2],
}))

export const ContactLensesSeparator = styled('div', {
  name: 'ContactLensesData',
  slot: 'Separator',
})(({ theme }) => ({
  gridColumn: 'span 3',
  height: '1px',
}))

export const ContactLensesErrorContainer = styled('div', {
  name: 'ContactLensesData',
  slot: 'ErrorContainer',
})(({ theme }) => ({
  color: theme.palette.color.error,
  fontSize: '14px',
}))

export const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  [`& .${MuiInputBaseClasses.root}`]: {
    ...theme.typography.body1,
    height: 'auto',
    padding: theme.spacing(4.75, 8),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 8),
    },
  },

  [`& .${MuiInputBaseClasses.input}`]: {
    color: theme.palette.neutral.main,
    height: 'auto',
    padding: theme.spacing(0),
  },

  [`& .${MuiOutlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.neutral.tones[80],
    height: 40,
    top: 0,

    '&:focus': {
      borderColor: theme.palette.neutral.tones[60],
    },

    '> legend': {
      display: 'none',
    },
  },

  //  Targets prefix and suffix's
  [`& .${MuiOutlinedInputClasses.inputAdornedStart}`]: {
    paddingLeft: theme.spacing(0),
  },

  [`.${MuiInputLabelClasses.root}`]: {
    lineHeight: 1,
    fontWeight: 'normal',
    [`&.${MuiInputLabelClasses.shrink}`]: {
      ...theme.typography.caption,
      color: theme.palette.text.primary,
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    '&[data-shrink="true"]': {
      transform: 'translate(12px, -19px) scale(1)',
      backgroundColor: 'transparent',
      left: 6,
    },
  },

  // this disable the autofill background color
  'input:-webkit-autofill': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
  'input:-webkit-autofill:hover': {
    transition: 'background-color 9999s ease-in-out 0s',
  },

  'input:-webkit-autofill:focus': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
  'input:-webkit-autofill:active': {
    transition: 'background-color 9999s ease-in-out 0s',
  },
}))
